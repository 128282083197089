import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Security from '../../commons/security/Security.js'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/dist/min/dropzone.min.css'
import '../../assets/css/vec-dropzone.css'
import {FormattedMessage, injectIntl} from 'react-intl';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import backendStrings from '../../lang/backendStrings.js';
import swal from 'sweetalert'
import PresupuestoDetalladoItemGrid from "../presupuestoDetallado/PresupuestoDetalladoItemGrid.js";
import PresupuestoDetalladoTareaGrid from "../presupuestoDetallado/PresupuestoDetalladoTareaGrid.js";

class TicketTareasItemsCierre extends Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.ajaxHandler = new AjaxHandler();
		let mdo = this.props.ticket.manoDeObra ? this.props.ticket.manoDeObra : '';
		let repuestos = this.props.ticket.repuestos ? this.props.ticket.manoDeObra : 0;
		let impuestos =  this.props.ticket.impuestos ?  this.props.ticket.impuestos : 0;
		let otros = this.props.ticket.otros ? this.props.ticket.otros : 0;
		moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
				id: 0,
				tareas: [],
				items: [],
				manoDeObra: mdo,
				impuestos: impuestos,
				repuestos: repuestos,
				otros: otros
			},
			maxLimitMonto: false,
			maxLimitObra: false,
			montosRepuesto: false,
			montosManoDeObra: false,
			listaSelectServicio: [],
			listaSelectTareas: [],
			listaSelectItems: [],
			servicioTareaSeleccionado: null,
			servicioItemSeleccionado: null,
			servicioSeleccionado: null,
			tareasSeleccionadas: [],
			errors: [],
			loading: false,
			tareasDisabled: true,
			tareasIsLoading: false,
			itemsDisabled: true,
			itemsIsLoading: false,
			totalRepuestos :0,
			totalManoDeObra: 0,
			isFormComplete: false,
			showTareas: [],
			enabledItems: false,
			showCierreItems: [],
			showCierreTareas: [],
			items: [],
			tareas: [],
			total: 0
		};

		this.handleServicioChange = this.handleServicioChange.bind(this);
		this.handleTareasChange = this.handleTareasChange.bind(this);
		this.handleItemsChange = this.handleItemsChange.bind(this);
		this.handleDeleteTarea = this.handleDeleteTarea.bind(this);
		this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleCheckControlTotalItems = this.handleCheckControlTotalItems.bind(this);
		this.handleModalCheck = this.handleModalCheck.bind(this);
		this.calculateTotal = this.calculateTotal.bind(this);
		this.deleteServicioItem = this.deleteServicioItem.bind(this);
		this.addItem = this.addItem.bind(this);
		this.setLoadingAndUpdate = this.setLoadingAndUpdate.bind(this);
		this.updateServicioItem = this.updateServicioItem.bind(this);
		this.handleFormChangeImpuestos = this.handleFormChangeImpuestos.bind(this);
		this.handleFormChangeOtros = this.handleFormChangeOtros.bind(this);
		this.deleteServicioTarea = this.deleteServicioTarea.bind(this);
		this.updateTarea = this.updateTarea.bind(this);
		this.handleCheckControlTotalTareas = this.handleCheckControlTotalTareas.bind(this);

	}

	componentDidMount() {
		if(Security.hasPermission('TICKETS_VER_TAREAS')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
		}
	}

	initForm() {
		this.setState({ loading: true });
		let component = this;

		let enabledItems = ConfigBusiness.get('tickets.presupuesto.items.habilitado') === 'true';
		let showCierreItems = [];
		if(enabledItems){
			showCierreItems = ConfigBusiness.get('tickets.presupuesto.items.showCierre').split(',');
		}

		let enabledTareas = ConfigBusiness.get('tickets.presupuesto.tareas.habilitado') === 'true';
		let showCierreTareas = [];
		if(enabledTareas){
			showCierreTareas = ConfigBusiness.get('tickets.presupuesto.tareas.showCierre').split(',');
		}

		this.setState({ 
			enabledItems: enabledItems,
			enabledTareas: enabledTareas,
			showCierreItems: showCierreItems,
			showCierreTareas: showCierreTareas
		});

		Promise.all([
			this.ajaxHandler.getJson('/servicios/tipo-ticket/' + this.props.ticket.ticketTipo + '/select'),
			this.ajaxHandler.getJson('/tickets/tareas/' + this.props.ticket.id ),
			ConfigBusiness.get('tickets.tareasCierre.montosRepuesto'),
			this.ajaxHandler.getJson('/ticket-presupuestos/ticket/' + this.props.ticket.id + '/grid')
		]).then((data) => {
			let servicios = data[0];
			let montosRepuesto = data[2] == 'true';
			let montosManoDeObra = data[3] == 'true';
			let formDataCopy = this.state.formData
			let items = [];
			let tareas = [];
			if(data[3]){
				let index = data[3].findIndex((element) => element.activo === true);
				if(index >= 0){
					let presupuestoRepuestos = data[3][index]['presupuestoRepuestos'];
					if(presupuestoRepuestos){
						items = presupuestoRepuestos.map((element, index) => {
							let precio = element.precio ? element.precio : 0;
							let cantidad = element.cantidad ? element.cantidad : 1;
							let total = precio * cantidad;
							let servicio = {
								id: element.servicio_id,
								nombre: element.servicio_nombre
							}
							return {
								...element,
								id: element.id_item,
								index,
								costo: precio,
								cantidad: cantidad,
								servicio: servicio,
								total: total
							};
						});
					}
					let presupuestoManoObras = data[3][index]['presupuestoManoObras'];
					if(presupuestoManoObras){
						tareas = presupuestoManoObras.map((element, index) => {
							let precio = element.precio ? element.precio : 0;
							let cantidad = element.cantidad ? element.cantidad : 1;
							let total = precio * cantidad;
							let servicio = {
								id: element.servicio_id,
								nombre: element.servicio_nombre
							}
							return {
								...element,
								index,
								id: element.id_tarea,
								precio: precio,
								servicio: servicio,
								cantidad: cantidad,
								total: total
							};
						});
					}
				}
			}
			this.calculateTotal(items, tareas, this.state.formData.impuestos, this.state.formData.otros)
			this.setState({
				formData: formDataCopy,
				servicios: servicios,
				montosRepuesto: montosRepuesto,
				montosManoDeObra: montosManoDeObra,
				items: items,
				tareas: tareas
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			component.setState({ loading: false });
		});
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		this.setState({
			redirectTo: null,
			props: this.props,
			formData: {
				id: 0,
				tareas: []
			},
			maxLimitMonto: false,
			maxLimitObra: false,
			maxLimitImpuestos: false,
			maxLimitOtros: false,
			montosRepuesto: false,
			montosManoDeObra: false,
			listaSelectServicio: [],
			listaSelectTareas: [],
			servicioTareaSeleccionado: null,
			tareasSeleccionadas: [],
			itemsSeleccionadas: [],
			errors: [],
			loading: false,
			tareasDisabled: true,
			tareasIsLoading: false
		}, ()=>{
			this.formValidation.validate();
			this.props.callbackSave();
		});
	}

	handleServicioChange(object, name) {
		if(object) {
			if(name === "servicioTarea"){
				this.setState({
					servicioTareaSeleccionado: object,
					tareasIsLoading: true
				}, () => {
					this.ajaxHandler.getJson('/tareas/tareas-de-servicio/' + object.value)
					.then((data) => {
						let listaSelectTareas = data;
						this.setState({
							listaSelectTareas: listaSelectTareas,
							tareasDisabled: false,
							tareasIsLoading: false
						});
					}).catch(function(error) {
						AjaxHandler.handleError(error);
					});
				});
			}if (name === "servicioItem") {
				this.setState({
					servicioItemSeleccionado: object,
					itemsIsLoading: true
				}, () => {
					this.ajaxHandler.getJson('/items/items-de-servicio/' + object.value)
					.then((data) => {
						let listaSelectItems = data;
						this.setState({
							listaSelectItems: listaSelectItems,
							itemsDisabled: false,
							itemsIsLoading: false
						});
					}).catch(function(error) {
						AjaxHandler.handleError(error);
					});
				});
			} else {
				
			}
		} else {
			this.setState({
				servicioTareaSeleccionado: null,
				tareasSeleccionadas: [],
				tareasDisabled: true
			});
		}
	}

	handleTareasChange(value) {
		this.setState({
			tareasSeleccionadas: value
		});
	}

	handleItemsChange(value){
		this.setState({
			itemsSeleccionadas: value
		});
	}

	loadTareas(tareas) {
		let tareasCopy = JSON.parse(JSON.stringify(this.state.tareas));
		tareas.map((tarea)=>{
			if(tareasCopy.findIndex(x => x.id === tarea.id && x.servicio.id === tarea.servicio.id) === -1) {
				tareasCopy.push(tarea);
			}
			return tareas;
		});

		this.setState({
			tareas: tareasCopy,
			servicioTareaSeleccionado: null,
			tareasSeleccionadas: [],
			tareasDisabled: true
		});
		this.calculateTotal(this.state.items, tareasCopy, this.state.formData.impuestos, this.state.formData.otros);
		this.handleCheckControlTotalTareas(tareasCopy)
	}

	handleAddTarea() {
		let tareas = [];
		this.state.tareasSeleccionadas.map((tareaSeleccionada)=>{
			let tarea = {
				id: tareaSeleccionada.id,
				nombre: tareaSeleccionada.nombre,
				servicio: {
					id: this.state.servicioTareaSeleccionado.value,
					nombre: this.state.servicioTareaSeleccionado.label
				},
				precio: tareaSeleccionada.precio ? tareaSeleccionada.precio : 0,
				cantidad: 1,
				total: 0
			}
			tarea.total = tarea.precio * tarea.cantidad
			tareas.push(tarea);
		 return tareaSeleccionada;
		});
		this.loadTareas(tareas);
	}

	loadItems(items) {
		let itemsCopy = JSON.parse(JSON.stringify(this.state.items));
		items.map((item)=>{
			if(itemsCopy.findIndex(x => x.id === item.id && x.servicio.id === item.servicio.id) === -1) {
				itemsCopy.push(item);
			}
			return item;
		});

		this.setState({
			items: itemsCopy,
			servicioItemSeleccionado: null,
			itemsSeleccionadas: [],
			itemsDisabled: true
		});
		this.calculateTotal(itemsCopy, this.state.tareas, this.state.formData.impuestos, this.state.formData.otros);
		this.handleCheckControlTotalItems(itemsCopy)
	}

	handleAddItem() {
		let items = [];
		this.state.itemsSeleccionadas.map((itemSeleccionada)=>{
			let item = {
				id: itemSeleccionada.id,
				descripcion: itemSeleccionada.descripcion,
				servicio: {
					id: this.state.servicioItemSeleccionado.value,
					nombre: this.state.servicioItemSeleccionado.label
				},
				costo: itemSeleccionada.costo ? itemSeleccionada.costo : 0,
				cantidad: itemSeleccionada.cantidad ? itemSeleccionada.cantidad : 0,
				total: 0
			};
			item.total = item.costo* item.cantidad
			items.push(item);
		 return itemSeleccionada;
		});
		this.loadItems(items);
	}

	handleCheckControlTotalItems(items){
		let total = 0;
		items.forEach(element => {
			total += parseFloat(element.total ? element.total : 0)
		});

		let repuestos = this.state.formData.repuestos;
		if(total > (repuestos)) {
			this.setState({
				maxLimitMonto: true
			});
		} else {
			this.setState({
				maxLimitMonto: false
			});
		}
	}

	handleCheckControlTotalTareas(tareas){
		let total = 0;
		tareas.forEach(element => {
			total += parseFloat(element.total ? element.total : 0)
		});

		let manoDeObra = this.state.formData.manoDeObra;
		if(total > (manoDeObra)) {
			this.setState({
				maxLimitObra: true
			});
		} else {
			this.setState({
				maxLimitObra: false
			});
		}
	}

	handleCheckControlTotalManoDeObra(tareas){
		let totalManoDeObra = 0;
		tareas.forEach(element => {
			totalManoDeObra += parseFloat(element.mano_de_obra ? element.mano_de_obra : 0)
		});
		let mano_de_obra = this.state.props.ticket.manoDeObra;

		if(totalManoDeObra > (mano_de_obra)) {
			this.setState({
				maxLimitObra: true
			});
		} else {
			this.setState({
				maxLimitObra: false
			});
		}

		this.setState({totalManoDeObra: totalManoDeObra})
	}

	handleDeleteTarea(index) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.tareas.splice(index, 1);
		this.setState({
			formData: formDataCopy
		});
		this.handleCheckControlTotal(formDataCopy.tareas)
	}

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
	}

	isFormCompleteCheck(){
		let tareas = this.state.formData.tareas
		const todosConValores = tareas.every(tarea => tarea.monto && tarea.mano_de_obra)
		this.setState({isFormComplete: todosConValores})
	}

	handleModalCheck(event) {
		let component = this
		let totalIngresado = this.state.totalManoDeObra + this.state.totalRepuestos
		let totalPresupuestado = this.state.props.ticket.manoDeObra + this.state.props.ticket.repuestos

		if (totalIngresado < totalPresupuestado && this.state.montosRepuesto) {
			swal({
				title: this.props.intl.formatMessage({
					id: 'ticketTareasCierre.render.swal.valoresMenores',
					defaultMessage: 'La suma de los valores ingresados es menores a lo presupuestado'
				}),
				text: this.props.intl.formatMessage({
					id: 'ticketTareasCierre.render.swal.deseaContinuar',
					defaultMessage: '¿Desea continuar?'
				}),
				icon: "warning",
				buttons: [
					this.props.intl.formatMessage({
						id: 'ticketTareasCierre.render.swal.cancel',
						defaultMessage: 'Cancelar'}),
						this.props.intl.formatMessage({
							id: 'ticketTareasCierre.render.swal.continue',
							defaultMessage: 'Continuar'}),
				],
				dangerMode: true,
			}).then(function(isConfirm) {
				if (isConfirm) {
					component.handleSubmit(event);
				}
			});

		} else {
			component.handleSubmit(event);
		}

		event.preventDefault();
	}

	renderTableTheadTarea(field, index){
		switch (field) {
			case 'nombre':
				return (
					<th key={index} scope="col" style={{ maxWidth: "3px" }}>
						<FormattedMessage id="Presupuesto.Tareas" defaultMessage="Tareas" />
					</th>
				);
			case 'cantidad':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cantidad" defaultMessage="Cantidad" />
					</th>
				);
			case 'precio':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Precio" defaultMessage="Precio" />
					</th>
				);
			case 'total':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Total" defaultMessage="Total" />
					</th>
				);
			case 'servicio':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Servicio" defaultMessage="Servicio" />
					</th>
				);
			default:
				return null;
		}
	}
																				
	renderTableTbodyTarea(element, field, index, index_tarea){
		switch (field) {
			case 'nombre':
				return (
					<td key={index} className="align-middle">
						{element.nombre}
					</td>
				);
			case 'cantidad':
				return (
					<td key={index} className="align-middle">
						<input
							type="number"
							className="form-control text-right pr-1"
							min="0"
							step="0.01"
							value={element.cantidad}
							onChange={(e) => this.handleChangeCantidadTarea(e,index_tarea)}
						/>
					</td>
				);
			case 'precio':
				return (
					<td key={index} className="align-middle">
						<input
							type="number"
							className="form-control text-right pr-1"
							min="0"
							step="0.01"
							value={element.precio}
							onChange={(e) => this.handleChangePrecioTarea(e,index_tarea)}
						/>
					</td>
				);
			case 'total':
				return (
					<td key={index} className="align-middle">
						{element.total}
					</td>
				);
			case 'servicio':
				return (
					<td key={index} className="align-middle">
						{element.servicio ? element.servicio.nombre : ''}
					</td>
				);
			default:
				return null;
		}
	}

	handleChangePrecioTarea(evt,index){
		let formDataCopy = this.state.formData
		formDataCopy.tareas[index].precio = evt.target.value
			let total = formDataCopy.tareas[index].precio &&
				formDataCopy.tareas[index].cantidad ? 
				('$ ' + Math.round((formDataCopy.tareas[index].precio*formDataCopy.tareas[index].cantidad)*100)/100) : ''
			formDataCopy.tareas[index].total = total
		this.setState({ formData: formDataCopy });
	}

	handleChangeCantidadTarea(evt,index){
		let formDataCopy = this.state.formData
		formDataCopy.tareas[index].cantidad = evt.target.value
			let total = formDataCopy.tareas[index].precio &&
				formDataCopy.tareas[index].cantidad ? 
				('$ ' + Math.round((formDataCopy.tareas[index].precio*formDataCopy.tareas[index].cantidad)*100)/100) : ''
			formDataCopy.tareas[index].total = total
		this.setState({ formData: formDataCopy });
	}

	handleSubmit(event) {
		this.state.formData.tareas = this.state.tareas;
		this.state.formData.items = this.state.items;
		this.state.formData.manoDeObra = this.state.totalManoDeObra;
		this.state.formData.repuestos = this.state.totalRepuestos;
		if((this.state.formData.tareas && this.state.formData.tareas.length > 0 ) || (this.state.formData.items && this.state.formData.items.length > 0)){
			let servicio = this.state.formData.tareas[0] ? this.state.formData.tareas[0].servicio : this.state.formData.items[0].servicio;
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.servicio = servicio;
			this.setState({
				formData: formDataCopy,
				loading: true
			},() =>{
				this.props.callbackSave(this.state.formData);
			});
		} else {
			let errors = this.state.errors;
			errors.push('TicketTareasItemsCierre.errors.deber_ingresar_al_menos_una_tarea_o_item');
			this.setState({
				errors: errors
			});
		}
		event.preventDefault();

	}

	handleChange(evt, index){
		let formDataCopy = this.state.formData
		formDataCopy.tareas[index].monto = evt.target.value
		this.setState({ formData: formDataCopy });
		this.handleCheckControlTotal(this.state.formData.tareas)
		this.isFormCompleteCheck()
	}

	handleCancel() {
		this.ajaxHandler.unsubscribe();
		this.setState({
			redirectTo: null,
			props: this.props,
			formData: {
				id: 0,
				tareas: []
			},
			listaSelectServicio: [],
			listaSelectTareas: [],
			servicioTareaSeleccionado: null,
			tareasSeleccionadas: [],
			errors: [],
			loading: false,
			tareasDisabled: true,
			tareasIsLoading: false
		 }, ()=>{
			this.props.callbackClose();
		 });
	}

	deleteServicioItem(indexArray){
		let items = [];
		this.state.items.forEach((element, index) => {
			if (index!==indexArray) {
				items.push(element);
			}
		});
		this.calculateTotal(items, this.state.tareas, this.state.formData.impuestos, this.state.formData.otros);
		this.handleCheckControlTotalItems(items)
		this.setState({
			items: items,
		});
  };

	deleteServicioTarea(indexArray){
		let tareas = [];
		this.state.tareas.forEach((element, index) => {
			if (index!==indexArray) {
				tareas.push(element);
			}
		});
		this.calculateTotal(this.state.items, tareas, this.state.formData.impuestos, this.state.formData.otros);
		//this.handleCheckControlTotalItems(items)
		this.setState({
			tareas: tareas,
		});
  };
	
	calculateTotal(items, tareas, impuestosActual, otrosActual){
		let totalRepuestosNew = 0;
		items.forEach(element => {
			if(element.cantidad){
				if(element.costo){
					totalRepuestosNew += element.cantidad*element.costo
				}
			}
		});

		let totalManoObraNew = 0;
		tareas.forEach(element => {
			if(element.precio){
				totalManoObraNew += element.cantidad*parseFloat(element.precio)
			}
		});

		let totalNew = 0;
		totalNew = totalRepuestosNew + totalManoObraNew + parseFloat(impuestosActual) + parseFloat(otrosActual);

		this.setState({
			totalManoDeObra: totalManoObraNew,
			totalRepuestos: totalRepuestosNew,
			total: totalNew
		});

	};

	addItem(newServicioItem){
		newServicioItem.cantidad = newServicioItem.cantidad ? newServicioItem.cantidad : 1;
		newServicioItem.costo = newServicioItem.costo ? newServicioItem.costo : 0;
		let copy = JSON.parse(JSON.stringify(this.state.items));
		copy.push(newServicioItem)
		this.calculateTotal(copy, this.state.tareas, this.state.formData.impuestos, this.state.formData.otros);
		this.handleCheckControlTotalItems(copy)
		this.setState({
			items: copy,
		});
  	};

	updateServicioItem(data, index){
		let copy = JSON.parse(JSON.stringify(this.state.items));
		copy[index].cantidad = data.cantidad ? data.cantidad : 1;
		copy[index].costo = data.costo ? data.costo : 0;
		copy[index].total = data.costo * data.cantidad;
		this.calculateTotal(copy, this.state.tareas, this.state.formData.impuestos, this.state.formData.otros);
		this.handleCheckControlTotalItems(copy)
		this.setState({
			items: copy
		});
	}

	updateTarea(data, index){
		let copy = JSON.parse(JSON.stringify(this.state.tareas));
		copy[index].cantidad = data.cantidad ? data.cantidad : 1;
		copy[index].precio = data.costo ? data.costo : 0;
		copy[index].total = data.costo * data.cantidad;
		this.calculateTotal(this.state.items, copy, this.state.formData.impuestos, this.state.formData.otros);
		this.handleCheckControlTotalTareas(copy)
		this.setState({
			tareas: copy
		});
	}

	setLoadingAndUpdate(state){
		this.setState({ loading: state });
	}

	handleFormChangeImpuestos(value){
		let impuestos = value ? value : 0;
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.impuestos = impuestos;
		this.calculateTotal(this.state.items, this.state.tareas, impuestos, this.state.formData.otros);
		let checkImpuestos = this.props.ticket.impuestos < impuestos
		this.setState({
			formData: formDataCopy,
			maxLimitImpuestos: checkImpuestos
		});
	}

	handleFormChangeOtros(value){
		let otros = value ? value : 0;
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.otros = otros;
		this.calculateTotal(this.state.items, this.state.tareas, this.state.formData.impuestos, otros);
		let checkOtros = this.props.ticket.otros < otros
		this.setState({
			formData: formDataCopy,
			maxLimitOtros: checkOtros
		});
	}

	render() {
		let formData = this.state.formData;
		let showTareas = ConfigBusiness.get('tickets.presupuesto.tareas.show').split(',');

		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
				<div className="row">
					<div className="col-md-12">
						<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
				{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
			</div>
						<form className="form form-horizontal" ref="form" onSubmit={this.handleModalCheck}>
							<div className="form-body">
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section mt-2">
												<i className="la la-cog"></i><FormattedMessage id="ticketTareasCierre.render.tareas.header_tareas" defaultMessage=" Tareas"/>
											</h4>
											<div className="row">
												{/* SERVICIO */}
												<div className="col-md-5">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label" htmlFor="servicio">
															<FormattedMessage id="ticketTareasCierre.render.tareas.label_servicio" defaultMessage="Servicio:"/>
														</label>
														<div className="col-md-8">
															<div>
																<Select
																	id="servicioTarea"
																	name="servicioTarea"
																	placeholder={this.props.intl.formatMessage({ id: 'ticketTareasCierre.render.tareas.placeholder_servicio', defaultMessage: 'Servicio' })}
																	options={this.state.servicios}
																	valueKey='value'
																	labelKey='label'
																	value={this.state.servicioTareaSeleccionado}
																	onChange={(e) => this.handleServicioChange(e, 'servicioTarea')}
																/>
															</div>
														</div>
													</div>
												</div>
												{/* TAREAS */}
												<div className="col-md-5">
													<div className="form-group row">
														<label className="col-md-3 label-control col-form-label" htmlFor="tareasServicio">
															<FormattedMessage id="ticketTareasCierre.render.tareas.label_tareas" defaultMessage="Tareas:"/>
														</label>
														<div className="col-md-9">
															{this.props.action === 'VIEW' ? (
															null
															) : (
															<div>
																<Select
																	id="tareasServicio"
																	name="tareasServicio"
																	placeholder={this.props.intl.formatMessage({ id: 'ticketTareasCierre.render.tareas.placeholder_tareas', defaultMessage: 'Tareas' })}
																	options={this.state.listaSelectTareas}
																	valueKey='id'
																	labelKey='nombre'
																	closeOnSelect={true}
																	multi
																	removeSelected={true}
																	joinValues
																	value={this.state.tareasSeleccionadas}
																	disabled={this.state.tareasDisabled}
																	isLoading={this.state.tareasIsLoading}
																	onChange={this.handleTareasChange }
																/>
															</div>
															)}
														</div>
													</div>
												</div>
												<div className="col-md-2">
													<button
														className="btn btn-icon-add text-center text-fleet"
														onClick={(e) => { this.handleAddTarea(); e.preventDefault();}}
														style={{backgroundColor: 'transparent'}}
														disabled={!this.state.tareasSeleccionadas || this.state.tareasSeleccionadas.length == 0}
													>
														<i className="ft-plus-circle"></i>
													</button>
												</div>
											</div>

											{ this.state.maxLimitObra &&
												<div className="row">
													<div className="col-md-12">
														<div className="form-group row">
															<div className="col-md-4"></div>
															<label className="col-md-8 col-form-label" htmlFor="warningManoDeObra" style={{color: "red"}}>
															<FormattedMessage id="TicketTareasItemsCierre.label_warning_mano_de_obra" defaultMessage="La sumatoria de los montos de mano de obra supera el valor presupuestado"/>
																{":  $"}{this.state.formData.manoDeObra}
															</label>
														</div>
													</div>
												</div>
											}

											{this.state.enabledTareas ? 
												<div>
													<PresupuestoDetalladoTareaGrid
														intl={this.props.intl}
														presupuestoTareas={this.state.tareas}
														loading={(state) => this.setLoadingAndUpdate(state)}
														deleteTarea={this.deleteServicioTarea}
														updatePresupuestoTarea={this.updateTarea}
														showTareas={this.state.showCierreTareas}
													/>
													<div className="row p-1" style={{justifyContent: "right"}}>
														<label className="col-md-2 label-control col-form-label" htmlFor="total_mano_de_obra">
															<FormattedMessage id="Presupuesto.Total_Mano_de_Obra" defaultMessage="Total Mano de Obra"/>:
														</label>
														<div className="col-md-3">
															<div>
																<input disabled 
																	type="number"
																	className="form-control text-right pr-1"
																	id="total_mano_de_obra"
																	name="total_mano_de_obra"
																	value={this.state.totalManoDeObra}
																/>
															</div>
														</div>
													</div>
												</div>
											: ''}


										</div>
									</div>
								</div>


								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section mt-2">
												<i className="la la-cog"></i><FormattedMessage id="Repuesto" defaultMessage="Repuesto"/>
											</h4>
											<div className="row">
												{/* SERVICIO */}
												<div className="col-md-5">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label" htmlFor="servicio">
															<FormattedMessage id="ticketTareasCierre.render.tareas.label_servicio" defaultMessage="Servicio:"/>
														</label>
														<div className="col-md-8">
															<div>
																<Select
																	id="servicioItem"
																	name="servicioItem"
																	placeholder={this.props.intl.formatMessage({ id: 'ticketTareasCierre.render.tareas.placeholder_servicio', defaultMessage: 'Servicio' })}
																	options={this.state.servicios}
																	valueKey='value'
																	labelKey='label'
																	value={this.state.servicioItemSeleccionado}
																	onChange={(e) => this.handleServicioChange(e, 'servicioItem')}
																/>
															</div>
														</div>
													</div>
												</div>
												{/* TAREAS */}
												<div className="col-md-5">
													<div className="form-group row">
														<label className="col-md-3 label-control col-form-label" htmlFor="tareasServicio">
															<FormattedMessage id="ticketTareasCierre.render.tareas.label_tareas" defaultMessage="Tareas:"/>
														</label>
														<div className="col-md-9">
															{this.props.action === 'VIEW' ? (
															null
															) : (
															<div>
																<Select
																	id="tareasServicio"
																	name="tareasServicio"
																	placeholder={this.props.intl.formatMessage({ id: 'ticketTareasCierre.render.tareas.placeholder_tareas', defaultMessage: 'Tareas' })}
																	options={this.state.listaSelectItems}
																	valueKey='id'
																	labelKey='descripcion'
																	closeOnSelect={true}
																	multi
																	removeSelected={true}
																	joinValues
																	value={this.state.itemsSeleccionadas}
																	disabled={this.state.itemsDisabled}
																	isLoading={this.state.itemsIsLoading}
																	onChange={this.handleItemsChange}
																/>
															</div>
															)}
														</div>
													</div>
												</div>
												<div className="col-md-2">
													<button
														className="btn btn-icon-add text-center text-fleet"
														onClick={(e) => { this.handleAddItem(); e.preventDefault();}}
														style={{backgroundColor: 'transparent'}}
														disabled={!this.state.itemsSeleccionadas || this.state.itemsSeleccionadas.length == 0}
													>
														<i className="ft-plus-circle"></i>
													</button>
												</div>
											</div>
											{ this.state.maxLimitMonto &&
												<div className="row">
													<div className="col-md-12">
														<div className="form-group row">
															<div className="col-md-4"></div>
															<label className="col-md-8 col-form-label" htmlFor="warningManoDeObra" style={{color: "red"}}>
																<FormattedMessage id="TicketTareasItemsCierre.label_warning_repuestos" defaultMessage="La sumatoria de los montos de los respuestos supera el valor presupuestado"/>
																{":  $"}{this.state.formData.repuestos}
															</label>
														</div>
													</div>
												</div>
											}

											{this.state.enabledItems ? 
												<div>
													<PresupuestoDetalladoItemGrid
														intl={this.props.intl}
														presupuestoItems={this.state.items}
														loading={(state) => this.setLoadingAndUpdate(state)}
														deleteItem={this.deleteServicioItem}
														updateServicioItem={this.updateServicioItem}
														showItems={this.state.showCierreItems}
													/>
													<div className="row p-1" style={{justifyContent: "right"}}>
														<label className="col-md-2 label-control col-form-label" htmlFor="repuestos">
															<FormattedMessage id="Presupuesto.Total_Repuestos" defaultMessage="Total Repuestos"/>:
														</label>
														<div className="col-md-3">
															<div>
																<input disabled 
																	type="number"
																	className="form-control text-right pr-1"
																	id="total_repuestos"
																	name="total_repuestos"
																	value={this.state.totalRepuestos}
																/>
															</div>
														</div>
													</div>
												</div>
												: null
											}

										</div>
									</div>
								</div>

								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section">
												<i className="la la-file-text"></i>
											</h4>
											<div className="row">
												<div className="col-md-6">
												</div>
												<div className="col-md-6">
													{ConfigBusiness.get('tickets.presupuesto.impuestos.habilitado')==="true" ?
														<div className="col-md-12">
															<div className="row p-1" style={{justifyContent: "right"}}>
																<label className="col-md-6 label-control col-form-label" htmlFor="impuestos">
																	<FormattedMessage id="Impuestos" defaultMessage="Impuestos"/> :
																</label>
																<div className="col-md-6">
																	<div>
																		<input
																			type="number"
																			className="form-control text-right pr-1"
																			id="impuestos"
																			name="impuestos"
																			value={this.state.formData.impuestos}
																			onChange={(e) => this.handleFormChangeImpuestos(e.target.value)}
																		/>
																	</div>
																</div>
															</div>
															{ this.state.maxLimitImpuestos &&
																<div className="form-group row">
																	<div className="col-md-4"></div>
																	<label className="col-md-8 col-form-label" htmlFor="warningImpuestos" style={{color: "red"}}>
																		<FormattedMessage id="TicketTareasItemsCierre.label_warning_impuestos" defaultMessage="El monto de impuestos supera el valor presupuestado"/>
																	</label>
																</div>
															}
														</div>
													: null}
													{ConfigBusiness.get('tickets.presupuesto.otros.habilitado')==="true" ?
														<div className="col-md-12">
															<div className="row p-1" style={{justifyContent: "right"}}>
																<label className="col-md-6 label-control col-form-label" htmlFor="otros">
																	<FormattedMessage id="Otros" defaultMessage="Otros"/> :
																</label>
																<div className="col-md-6">
																	<div>
																		<input
																			type="number"
																			className="form-control text-right pr-1"
																			id="otros"
																			name="otros"
																			value={this.state.formData.otros}
																			onChange={(e) => this.handleFormChangeOtros(e.target.value)}
																		/>
																	</div>
																</div>
															</div>
															{ this.state.maxLimitOtros &&
																<div className="form-group row">
																	<div className="col-md-3"></div>
																	<label className="col-md-9 col-form-label" htmlFor="warningOtros" style={{color: "red"}}>
																		<FormattedMessage id="ticketTareasCierre.render.tareas.label_warning_otros" defaultMessage="El monto de otros supera el valor presupuestado"/>
																	</label>
																</div>
															}
														</div>
													: null}
													<div className="col-md-12">
														<div className="row p-1" style={{justifyContent: "right"}}>
															<label className="col-md-6 label-control col-form-label" htmlFor="total">
																<b><FormattedMessage id="Total" defaultMessage="Total"/></b> :
															</label>
															<div className="col-md-6">
																<div>
																	<input disabled 
																		type="number"
																		className="form-control text-right pr-1"
																		id="total"
																		name="total"
																		value={this.state.total}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<div className="text-cd text-right">
												{this.props.action !== 'VIEW' && (
												<button type="submit" className="btn btn-primary mr-1" 
													disabled={
														this.state.loading ||
														this.state.maxLimitMonto ||
														this.state.maxLimitObra ||
														this.state.maxLimitImpuestos ||
														this.state.maxLimitOtros
													}
												>
													<i className="fa fa-check-circle"></i><FormattedMessage id="ticketTareasCierre.finish_button.guardar" defaultMessage=" Guardar"/>
												</button>
												)}
												<button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
													<i className="fa fa-times-circle"></i><FormattedMessage id="ticketTareasCierre.finish_button.cancelar" defaultMessage=" Cancelar"/>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(TicketTareasItemsCierre);
