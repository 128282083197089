import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import swal from 'sweetalert'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import Switch from "react-switch"
import { Transform } from 'stream'
import Loading from '../ui/Loading.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class PersonasGrid extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.columnsToPrint = [3,4,5,6,7];

		this.state = {
			redirectTo: null,
			dataToExport:{
				nombre : true,
				apellido : true,
				documentoTipoNombre : true,
				documentoNumero : true,
				nacionalidadNombre : true,
				sexo : true,
				fechaNacimiento : true,
				esActivo : true,
				esUsuario : true,
				telefonoCodArea : true,
				telefonoNumero : true,
				celularCodArea : true,
				celularNumero : true,
				email : true,
				email_SSO : ConfigBusiness.get('personas.usa_sso.habilitado') === 'true' ? true : false, // Agregar campo email_SSO
				calle : true,
				numero : true,
				piso : true,
				departamento : true,
				paisNombre : true,
				provinciaNombre : true,
				localidadNombre : true,
				observaciones : true,
				legajoNumero : true,
				categoriaNombre : true,
				fechaIngreso : true,
				fechaBaja : true,
				baseDescripcion : true,
				contrato : true,
				ypfRuta : true,
				comentariosLaborales : true,
				usuarioUsuario : true,
				usuarioNotificacionesActivas : true,
				usuarioPerfilNombre : true,
				usuarioMovilDominio : true,
				usuarioGerenciadorRazonSocial : true,
				usuarioBases : true,
				centroCostosNombre : true,
				centroCostosObservaciones : true,
				companiaOrigen : true
			},
			exportarTodo: true
		}
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('PERSONAS_LISTAR')) {
	      	this.ajaxHandler.subscribe(this);
			this.initGrid();
	    } else {
		    this.setState({
		        redirectTo: '/error'
	      	});
	    }
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}
	handleDataExport(nombre){
		const miState = {...this.state};

		//console.log("nombre",event,"dato",!this.state.dataToExport.nroTicket);

		switch (nombre) {
			case "apellido":
				miState.dataToExport.apellido = !miState.dataToExport.apellido;
			break;
			case "nombre":
			miState.dataToExport.nombre = !miState.dataToExport.nombre;
		break;
			case "documentoTipoNombre":
			miState.dataToExport.documentoTipoNombre = !miState.dataToExport.documentoTipoNombre;
		break;
			case "documentoNumero":
			miState.dataToExport.documentoNumero = !miState.dataToExport.documentoNumero;
		break;
			case "nacionalidadNombre":
			miState.dataToExport.nacionalidadNombre = !miState.dataToExport.nacionalidadNombre;
		break;
			case "sexo":
			miState.dataToExport.sexo = !miState.dataToExport.sexo;
		break;
			case "fechaNacimiento":
			miState.dataToExport.fechaNacimiento = !miState.dataToExport.fechaNacimiento;
		break;
			case "esActivo":
			miState.dataToExport.esActivo = !miState.dataToExport.esActivo; 			
		break;
			case "esUsuario": 				
				miState.dataToExport.esUsuario = !miState.dataToExport.esUsuario; 			
		break;
			case "telefonoCodArea": 				
			miState.dataToExport.telefonoCodArea = !miState.dataToExport.telefonoCodArea; 			
		break;
			case "telefonoNumero": 				
			miState.dataToExport.telefonoNumero = !miState.dataToExport.telefonoNumero; 			
		break;
			case "celularCodArea": 				
			miState.dataToExport.celularCodArea = !miState.dataToExport.celularCodArea; 			
		break;
			case "celularNumero": 				
			miState.dataToExport.celularNumero = !miState.dataToExport.celularNumero; 			
		break;
			case "email": 				
			miState.dataToExport.email = !miState.dataToExport.email; 			
		break;
			case "email_SSO": 				
			miState.dataToExport.email_SSO = !miState.dataToExport.email_SSO; 			
		break;
			case "calle": 				
			miState.dataToExport.calle = !miState.dataToExport.calle; 			
		break;
			case "numero": 				
			miState.dataToExport.numero = !miState.dataToExport.numero; 			
		break;
			case "piso": 				
			miState.dataToExport.piso = !miState.dataToExport.piso; 			
		break;
			case "departamento": 				
			miState.dataToExport.departamento = !miState.dataToExport.departamento; 			
		break;
			case "paisNombre": 				
			miState.dataToExport.paisNombre = !miState.dataToExport.paisNombre; 			
		break;
			case "provinciaNombre": 				
			miState.dataToExport.provinciaNombre = !miState.dataToExport.provinciaNombre; 			
		break;
			case "localidadNombre": 				
			miState.dataToExport.localidadNombre = !miState.dataToExport.localidadNombre; 			
		break;
			case "observaciones": 				
			miState.dataToExport.observaciones = !miState.dataToExport.observaciones; 			
		break;
			case "legajoNumero": 				
			miState.dataToExport.legajoNumero = !miState.dataToExport.legajoNumero; 			
		break;
			case "categoriaNombre": 				
			miState.dataToExport.categoriaNombre = !miState.dataToExport.categoriaNombre; 			
		break;
			case "fechaIngreso": 				
			miState.dataToExport.fechaIngreso = !miState.dataToExport.fechaIngreso; 			
		break;
			case "fechaBaja": 				
			miState.dataToExport.fechaBaja = !miState.dataToExport.fechaBaja; 			
		break;
			case "baseDescripcion": 				
			miState.dataToExport.baseDescripcion = !miState.dataToExport.baseDescripcion; 			
		break;
			case "contrato": 				
			miState.dataToExport.contrato = !miState.dataToExport.contrato; 			
		break;
			case "ypfRuta": 				
			miState.dataToExport.ypfRuta = !miState.dataToExport.ypfRuta; 			
		break;
			case "comentariosLaborales": 				
			miState.dataToExport.comentariosLaborales = !miState.dataToExport.comentariosLaborales; 			
		break;
			case "usuarioUsuario": 				
			miState.dataToExport.usuarioUsuario = !miState.dataToExport.usuarioUsuario; 			
		break;
			case "usuarioNotificacionesActivas": 				
			miState.dataToExport.usuarioNotificacionesActivas = !miState.dataToExport.usuarioNotificacionesActivas; 			
		break;
			case "usuarioPerfilNombre": 				
			miState.dataToExport.usuarioPerfilNombre = !miState.dataToExport.usuarioPerfilNombre; 			
		break;
			case "usuarioMovilDominio": 				
			miState.dataToExport.usuarioMovilDominio = !miState.dataToExport.usuarioMovilDominio; 			
		break;
			case "usuarioGerenciadorRazonSocial": 				
			miState.dataToExport.usuarioGerenciadorRazonSocial = !miState.dataToExport.usuarioGerenciadorRazonSocial; 			
		break;
			case "usuarioBases": 				
			miState.dataToExport.usuarioBases = !miState.dataToExport.usuarioBases; 			
		break;
			case "centroCostosNombre": 				
			miState.dataToExport.centroCostosNombre = !miState.dataToExport.centroCostosNombre; 			
		break;
			case "centroCostosObservaciones": 				
			miState.dataToExport.centroCostosObservaciones = !miState.dataToExport.centroCostosObservaciones; 			
		break;
			case "companiaOrigen":
			miState.dataToExport.companiaOrigen = !miState.dataToExport.companiaOrigen;
		break;
		}
		this.setState(miState);
	}
	handleExportExcel() {
		let component = this;
		this.setState({ loading: true });
		component.ajaxHandler.fetch('/personas/exportar-excel', {
			method: 'POST',
			body: JSON.stringify({
				colsAExportar: this.state.dataToExport
			})
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-personas.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			component.handleExportCancel();
			this.setState({ loading: false });
		});
	}
	handleExportCancel() {
		$('#export_modal').modal('toggle');
	}

	render() {
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row mt-2">
					<div className="col-12">
						<div className="card">
							<div className="card-content collpase show">
								<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons">
											<div className="col-6">
												{Security.renderIfHasPermission('PERSONAS_CREAR', (
												<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNew.bind(this)} data-toggle="tooltip" data-placement="right" title={this.props.intl.formatMessage({ id: 'personasGrid.render.button_add_person.title_nueva', defaultMessage: 'Nueva' })}>
													<i className="ft-plus"></i>
												</div>
												))}
											</div>
											<div className="col-6" id="buttons"></div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
													<th className=""></th>
													<th className=""></th>
													<th className="p-0"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className=""></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
				<div className="modal-xl modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="personasGrid.modal_export.header_exportar_personas" defaultMessage=" Exportar Personas"/></h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>	
						<div className="modal-body modal-grey modal-mh ovf-x-hidden">
						<div className="row">
								<div className="col-md-12">
									{/*<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
										{this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
									</div>*/}
									<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
							<div className="card pull-up">
								<div className="card-content">
									<div className="card-body">
										<h4 className="form-section">
											<i className="la la-columns"></i><FormattedMessage id="personasGrid.modal_export.export_columns.header_columnas_a_exportar" defaultMessage=" Columnas a exportar"/>
										</h4>
											<div className="row m-1">
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="idTicket">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_apellido" defaultMessage="Apellido:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("apellido")}
														checked = {this.state.dataToExport.apellido ? true : false}
														value={this.state.dataToExport.apellido}
														id = "apellido"
														name = "apellido"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="nombre">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_nombre" defaultMessage="Nombre:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("nombre")}
														checked = {this.state.dataToExport.nombre ? true : false}
														value={this.state.dataToExport.nombre}
														id = "nombre"
														name = "nombre"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="documentoTipoNombre">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_tipo_doc" defaultMessage="Tipo Doc.:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("documentoTipoNombre")}
														checked = {this.state.dataToExport.documentoTipoNombre ? true : false}
														value={this.state.dataToExport.documentoTipoNombre}
														id = "documentoTipoNombre"
														name = "documentoTipoNombre"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="documentoNumero">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_nro_doc" defaultMessage="Nro Doc.:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("documentoNumero")}
														checked = {this.state.dataToExport.documentoNumero ? true : false}
														value={this.state.dataToExport.documentoNumero}
														id = "documentoNumero"
														name = "documentoNumero"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>													
											</div>
											<div className="row m-1">
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="nacionalidadNombre">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_nacionalidad" defaultMessage="Nacionalidad:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("nacionalidadNombre")}
														checked = {this.state.dataToExport.nacionalidadNombre ? true : false}
														value={this.state.dataToExport.nacionalidadNombre}
														id = "nacionalidadNombre"
														name = "nacionalidadNombre"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="sexo">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_sexo" defaultMessage="Sexo:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("sexo")}
														checked = {this.state.dataToExport.sexo ? true : false}
														value={this.state.dataToExport.sexo}
														id = "sexo"
														name = "sexo"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="fechaNacimiento">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_fecha_nac" defaultMessage="Fecha Nac.:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("fechaNacimiento")}
														checked = {this.state.dataToExport.fechaNacimiento ? true : false}
														value={this.state.dataToExport.fechaNacimiento}
														id = "fechaNacimiento"
														name = "fechaNacimiento"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="esActivo">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_activo" defaultMessage="Activo:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("esActivo")}
														checked = {this.state.dataToExport.esActivo ? true : false}
														value={this.state.dataToExport.esActivo}
														id = "esActivo"
														name = "esActivo"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>													
											</div>
											<div className="row m-1">
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="esUsuario">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_usuario" defaultMessage="Usuario:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("esUsuario")}
														checked = {this.state.dataToExport.esUsuario ? true : false}
														value={this.state.dataToExport.esUsuario}
														id = "esUsuario"
														name = "esUsuario"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="telefonoCodArea">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_tel_codArea" defaultMessage="tel. CodArea:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("telefonoCodArea")}
														checked = {this.state.dataToExport.telefonoCodArea ? true : false}
														value={this.state.dataToExport.telefonoCodArea}
														id = "telefonoCodArea"
														name = "telefonoCodArea"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="telefonoNumero">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_nro_telefono" defaultMessage="Nro. telefono:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("telefonoNumero")}
														checked = {this.state.dataToExport.telefonoNumero ? true : false}
														value={this.state.dataToExport.telefonoNumero}
														id = "telefonoNumero"
														name = "telefonoNumero"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="celularCodArea">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_cel_codArea" defaultMessage="cel. CodArea:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("celularCodArea")}
														checked = {this.state.dataToExport.celularCodArea ? true : false}
														value={this.state.dataToExport.celularCodArea}
														id = "celularCodArea"
														name = "celularCodArea"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>													
											</div>
											<div className="row m-1">
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="celularNumero">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_nro_celular" defaultMessage="Nro. celular:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("celularNumero")}
														checked = {this.state.dataToExport.celularNumero ? true : false}
														value={this.state.dataToExport.celularNumero}
														id = "celularNumero"
														name = "celularNumero"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="email">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_email" defaultMessage="Email:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("email")}
														checked = {this.state.dataToExport.email ? true : false}
														value={this.state.dataToExport.email}
														id = "email"
														name = "email"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
												{ConfigBusiness.get('personas.usa_sso.habilitado') === 'true' ?
													<div className=" col-md-3">
														<label className="col-md-6 label-control col-form-label" htmlFor="email_SSO">
															<FormattedMessage id="personasGrid.modal_export.export_columns.label_Usuario_SSO" defaultMessage="Usuario SSO"/>:
														</label>
														<Switch
															onChange = {(event) => this.handleDataExport("email_SSO")}
															checked = {this.state.dataToExport.email_SSO ? true : false}
															value={this.state.dataToExport.email_SSO}
															id = "email_SSO"
															name = "email_SSO"
															offColor = "#FF4961"
															onColor = "#28D094"
															disabled = {false}
															className =  "col-md-6 align-middle"										
														/>
													</div>
													: ""
												}
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="calle">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_calle" defaultMessage="Calle:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("calle")}
														checked = {this.state.dataToExport.calle ? true : false}
														value={this.state.dataToExport.calle}
														id = "calle"
														name = "calle"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="numero">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_numero" defaultMessage="Numero:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("numero")}
														checked = {this.state.dataToExport.numero ? true : false}
														value={this.state.dataToExport.numero}
														id = "numero"
														name = "numero"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>													
											</div>
											<div className="row m-1">
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="piso">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_piso" defaultMessage="Piso:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("piso")}
														checked = {this.state.dataToExport.piso ? true : false}
														value={this.state.dataToExport.piso}
														id = "piso"
														name = "piso"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="departamento">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_dto" defaultMessage="Dto:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("departamento")}
														checked = {this.state.dataToExport.departamento ? true : false}
														value={this.state.dataToExport.departamento}
														id = "departamento"
														name = "departamento"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="paisNombre">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_pais" defaultMessage="Pais:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("paisNombre")}
														checked = {this.state.dataToExport.paisNombre ? true : false}
														value={this.state.dataToExport.paisNombre}
														id = "paisNombre"
														name = "paisNombre"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="provinciaNombre">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_provincia" defaultMessage="Provincia:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("provinciaNombre")}
														checked = {this.state.dataToExport.provinciaNombre ? true : false}
														value={this.state.dataToExport.provinciaNombre}
														id = "provinciaNombre"
														name = "provinciaNombre"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>													
											</div>
											<div className="row m-1">
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="localidadNombre">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_localidad" defaultMessage="Localidad:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("localidadNombre")}
														checked = {this.state.dataToExport.localidadNombre ? true : false}
														value={this.state.dataToExport.localidadNombre}
														id = "localidadNombre"
														name = "localidadNombre"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="observaciones">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_obs" defaultMessage="Obs.:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("observaciones")}
														checked = {this.state.dataToExport.observaciones ? true : false}
														value={this.state.dataToExport.observaciones}
														id = "observaciones"
														name = "observaciones"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="legajoNumero">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_legajo" defaultMessage="Legajo:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("legajoNumero")}
														checked = {this.state.dataToExport.legajoNumero ? true : false}
														value={this.state.dataToExport.legajoNumero}
														id = "legajoNumero"
														name = "legajoNumero"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="categoriaNombre">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_categoria" defaultMessage="Categoria:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("categoriaNombre")}
														checked = {this.state.dataToExport.categoriaNombre ? true : false}
														value={this.state.dataToExport.categoriaNombre}
														id = "categoriaNombre"
														name = "categoriaNombre"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>													
											</div>
											<div className="row m-1">
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="fechaIngreso">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_fec_ingreso" defaultMessage="Fec. Ingreso:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("fechaIngreso")}
														checked = {this.state.dataToExport.fechaIngreso ? true : false}
														value={this.state.dataToExport.fechaIngreso}
														id = "fechaIngreso"
														name = "fechaIngreso"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="fechaBaja">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_fec_baja" defaultMessage="Fec. Baja:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("fechaBaja")}
														checked = {this.state.dataToExport.fechaBaja ? true : false}
														value={this.state.dataToExport.fechaBaja}
														id = "fechaBaja"
														name = "fechaBaja"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="baseDescripcion">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_base" defaultMessage="Base:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("baseDescripcion")}
														checked = {this.state.dataToExport.baseDescripcion ? true : false}
														value={this.state.dataToExport.baseDescripcion}
														id = "baseDescripcion"
														name = "baseDescripcion"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="contrato">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_contrato" defaultMessage="Contrato:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("contrato")}
														checked = {this.state.dataToExport.contrato ? true : false}
														value={this.state.dataToExport.contrato}
														id = "contrato"
														name = "contrato"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>													
											</div>
											<div className="row m-1">
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="ypfRuta">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_clave_Pin_YPF" defaultMessage="Clave Pin YPF:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("ypfRuta")}
														checked = {this.state.dataToExport.ypfRuta ? true : false}
														value={this.state.dataToExport.ypfRuta}
														id = "ypfRuta"
														name = "ypfRuta"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="comentariosLaborales">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_comentarios" defaultMessage="Comentarios:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("comentariosLaborales")}
														checked = {this.state.dataToExport.comentariosLaborales ? true : false}
														value={this.state.dataToExport.comentariosLaborales}
														id = "comentariosLaborales"
														name = "comentariosLaborales"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="usuarioUsuario">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_usuario" defaultMessage="Usuario:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("usuarioUsuario")}
														checked = {this.state.dataToExport.usuarioUsuario ? true : false}
														value={this.state.dataToExport.usuarioUsuario}
														id = "usuarioUsuario"
														name = "usuarioUsuario"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="usuarioNotificacionesActivas">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_notificaciones" defaultMessage="Notificaciones:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("usuarioNotificacionesActivas")}
														checked = {this.state.dataToExport.usuarioNotificacionesActivas ? true : false}
														value={this.state.dataToExport.usuarioNotificacionesActivas}
														id = "usuarioNotificacionesActivas"
														name = "usuarioNotificacionesActivas"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>													
											</div>
											<div className="row m-1">
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="usuarioPerfilNombre">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_perfil" defaultMessage="Perfil:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("usuarioPerfilNombre")}
														checked = {this.state.dataToExport.usuarioPerfilNombre ? true : false}
														value={this.state.dataToExport.usuarioPerfilNombre}
														id = "usuarioPerfilNombre"
														name = "usuarioPerfilNombre"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="usuarioMovilDominio">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_movil" defaultMessage="Movil:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("usuarioMovilDominio")}
														checked = {this.state.dataToExport.usuarioMovilDominio ? true : false}
														value={this.state.dataToExport.usuarioMovilDominio}
														id = "usuarioMovilDominio"
														name = "usuarioMovilDominio"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="usuarioGerenciadorRazonSocial">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_gerenciador" defaultMessage="Gerenciador:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("usuarioGerenciadorRazonSocial")}
														checked = {this.state.dataToExport.usuarioGerenciadorRazonSocial ? true : false}
														value={this.state.dataToExport.usuarioGerenciadorRazonSocial}
														id = "usuarioGerenciadorRazonSocial"
														name = "usuarioGerenciadorRazonSocial"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="usuarioBases">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_bases" defaultMessage="Bases:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("usuarioBases")}
														checked = {this.state.dataToExport.usuarioBases ? true : false}
														value={this.state.dataToExport.usuarioBases}
														id = "usuarioBases"
														name = "usuarioBases"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>													
											</div>
											<div className="row m-1">
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="centroCostosNombre">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_centro_Costo" defaultMessage="Centro Costo:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("centroCostosNombre")}
														checked = {this.state.dataToExport.centroCostosNombre ? true : false}
														value={this.state.dataToExport.centroCostosNombre}
														id = "centroCostosNombre"
														name = "centroCostosNombre"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>	
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="centroCostosObservaciones">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_cc_obs" defaultMessage="C.C. Obs.:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("centroCostosObservaciones")}
														checked = {this.state.dataToExport.centroCostosObservaciones ? true : false}
														value={this.state.dataToExport.centroCostosObservaciones}
														id = "centroCostosObservaciones"
														name = "centroCostosObservaciones"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
												<div className=" col-md-3">
													<label className="col-md-6 label-control col-form-label" htmlFor="companiaOrigen">
														<FormattedMessage id="personasGrid.modal_export.export_columns.label_cnia_origen" defaultMessage="Cnia Origen:"/>
													</label>
													<Switch
														onChange = {(event) => this.handleDataExport("companiaOrigen")}
														checked = {this.state.dataToExport.companiaOrigen ? true : false}
														value={this.state.dataToExport.companiaOrigen}
														id = "companiaOrigen"
														name = "companiaOrigen"
														offColor = "#FF4961"
														onColor = "#28D094"
														disabled = {false}
														className =  "col-md-6 align-middle"										
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
								</div>
									<div className="col-md-12 alert alert-info small" role="alert">
										<FormattedMessage id="personasGrid.modal_export.information_esta_accion_puede_demorar_unos_minutos" defaultMessage="Esta acción puede demorar unos minutos."/>
									</div>
								</div>
							</div>
							<div className="modal-footer modal-grey">
								<button type="button" className="btn btn-default btn-fleet" onClick={this.handleExportExcel.bind(this)}><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="personasGrid.modal_export.button_finish.exportar_a_excel" defaultMessage=" Exportar a Excel"/></button>
								<button type="button" className="btn btn-danger" onClick={this.handleExportCancel}><i className="fa fa-times-circle"></i><FormattedMessage id="personasGrid.modal_export.button_finish.cancelar" defaultMessage=" Cancelar"/></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	initGrid() {
		window.scrollTo(0, 0);
		let component = this;

		Promise.all([
			this.getData('/bases/select-filtered-by-user/persona'),
			this.getData('/persona-categorias/select'),
			this.getData('/usuario-perfiles/select')
		]).then((data) => {
			let filters = {
				base: data[0] ? data[0] : [],
				categoria: data[1] ? data[1] : [],
				perfil: data[2] ? data[2] : [],
			}

			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
					stateSave: true,
					stateSaveCallback: function(settings, data) {
						localStorage.setItem('DataTables_PersonasGrid', JSON.stringify(data));
					},
					stateLoadCallback: function(settings) {
						return JSON.parse(localStorage.getItem('DataTables_PersonasGrid'));
					},
					processing: true,
					serverSide: true,
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + '/personas/grid',
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {
							component.ajaxHandler.handleResponseErrorsIsValid(xhr);
						},
						cache: false
					},
					initComplete: function(settings) {
						let stateColumns = JSON.parse(localStorage.getItem('DataTables_PersonasGrid')).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every(function() {
							var column = this;

							if (sets.aoColumns[index].filterType) {
								if (sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'personasGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo($(column.footer()).empty())
										.on('change', function() {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search(val ? val : '', true, false)
												.draw();
										});
									if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="' + e.value + '">' + e.label + '</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each(function(d, j) {
											select.append('<option value="' + d + '">' + d + '</option>');
										});
									}
									if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if (sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'personasGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on('keyup change', function() {
										if (column.search() !== this.value) {
											column
												.search(this.value)
												.draw()
												.ajax.reload(null, false);
										}
									});
								}
							}
							index++;
							return '';
						});

						$('tfoot tr').appendTo('thead');
					},
					columns: [{
						title: '',
						data: 'esUsuario',
						className: 'all',
						render: function(data, type, full, meta) {
							return data ? '<div class="text-center success dt-info-icon"><i class="ft-user" data-togle="tooltip" data-placement="top" title="Tiene usuario"></i></div>' : '';
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('div > i').tooltip();
						},
						filterType: 'none'
					}, {
						title: '',
						data: null,
						className: 'all',
						orderable: false,
						render: function(data, type, full, meta) {
							return '<div id="avatar"></div>';
						},
						filterType: 'none'
					}, {
						title: '',
						data: 'esActivo',
						width: 0,
						padding: 0,
						orderable: false,
						className: 'p-0 all',
						render: function(data, type, full, meta) {
							return '';
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$('div.tooltip[role="tooltip"]').tooltip('dispose');
							$('div.tooltip[role="tooltip"]').remove();
							
							$(td).attr('data-togle', 'tooltip');
							$(td).attr('data-placement', 'top');
							$(td).attr('title', cellData ? 'Activa' : 'Inactiva');
							$(td).tooltip();
							if (cellData) {
								$(td).addClass('status-green');
							} else {
								$(td).addClass('status-red');
							}
						},
						filterType: 'none'
					}, {
						title: component.props.intl.formatMessage({ id: 'personasGrid.column_lastName.label_apellido', defaultMessage: 'Apellido' }),
						className: 'all',
						data: 'apellido',
						filterType: 'input'
					}, {
						title: component.props.intl.formatMessage({ id: 'personasGrid.column_name.label_nombre', defaultMessage: 'Nombre' }),
						className: 'all',
						data: 'nombre',
						filterType: 'input'
					}, {
						title: component.props.intl.formatMessage({ id: 'personasGrid.column_legajo.label_legajo', defaultMessage: 'Legajo' }),
						className: 'all',
						data: 'legajoNumero',
						filterType: 'input'
					}, {
						name: 'base',
						title: component.props.intl.formatMessage({ id: 'personasGrid.column_base.label_apellido', defaultMessage: 'Base' }),
						className: 'all',
						data: 'base',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.descripcion ? data.descripcion : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'categoria',
						title: component.props.intl.formatMessage({ id: 'personasGrid.column_category.label_categoria', defaultMessage: 'Categoría' }),
						className: 'all',
						data: 'categoria',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.nombre ? data.nombre : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'perfil',
						title: component.props.intl.formatMessage({ id: 'personasGrid.column_profile.label_perfil', defaultMessage: 'Perfil' }),
						className: 'all',
						data: 'perfil',
						
						filterType: 'select'
					},{
						orderable: false,
						data: null,
						className: "text-left all",
						width: '120px',
						render: function(data, type, full, meta) {
							let html =
								(Security.hasPermission('PERSONAS_VISUALIZAR') ? `
								<button class="action view btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'PersonasGrid.column_actions.button_title_ver', defaultMessage: 'Ver' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-search fa-xs"></i>
								</button>` : '') +
								(Security.hasPermission('PERSONAS_MODIFICAR') ? `
								<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'PersonasGrid.column_actions.button_title_editar', defaultMessage: 'Editar' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-pencil fa-xs"></i>
								</button>` : '') +
								(Security.hasPermission('PERSONAS_BLANQUEAR_CLAVE') && data.esUsuario ? `
								<button class="action blankPassword btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'PersonasGrid.column_actions.button_title_blanquear_contrasena', defaultMessage: 'Blanquear Contraseña' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-unlock-alt fa-xs"></i>
								</button>` : '') +
								(Security.hasPermission('TICKETS_PERSONA_LISTAR') || Security.hasPermission('VENCIMIENTOS_PERSONA_LISTAR') ? `
								<button class="action mantYVenc btn btn-sm btn-icon btn-dt-grid text-success" data-title="`+component.props.intl.formatMessage({ id: 'PersonasGrid.column_actions.button_title_ver_vencimientos_tickets', defaultMessage: 'Ver Vencimientos y Tickets' })+`" data-togle="tooltip" data-placement="top">
									<i class="ft ft-clipboard"></i>
								</button>` : '');
							return html;
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('button').tooltip();
						},
						filterType: 'none'
					}],
					drawCallback: function() {
						$(this).find('.action').on('click', function() {
							let data = component.table.row($(this).parents('tr')).data();
							let redirectTo;
							if ($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
							if ($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
							if ($(this).hasClass('mantYVenc')) redirectTo = component.props.match.url + '/' + data.id + '/tickets';
							component.setState({
								redirectTo: redirectTo
							});

							if ($(this).hasClass('blankPassword')) {
								swal({
									title: '¿'+component.props.intl.formatMessage({ id: 'personasGrid.modal_blanquear_password.confirm_blanquear_la_contrasena', defaultMessage: 'Blanquear la contraseña para ' }) + data.nombre + ' ' + data.apellido + '?',
									text: component.props.intl.formatMessage({ id: 'personasGrid.modal_blanquear_password.information_se_enviara_un_email', defaultMessage: 'Se enviará un e-mail al usuario con las instrucciones para cambiar su contraseña.' }),
									icon: "warning",
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'personasGrid.modal_blanquear_password.confirm_blanquear_la_contrasena.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'personasGrid.modal_blanquear_password.confirm_blanquear_la_contrasena.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								})
								.then((isConfirm) => {
									if (isConfirm) {
										fetch(Config.get('apiUrlBase') + '/personas/blank-password/' + data.id, {
												method: 'POST',
												headers: {
													'Accept': 'application/json',
													'Content-Type': 'application/json',
													'Authorization-Token': localStorage.getItem("token")
												}
											})
											.then(function(response) {
												swal(component.props.intl.formatMessage({ id: 'personasGrid.modal_blanquear_password.information_contraseaa_blanqueada', defaultMessage: 'Contraseña blanqueada!' }), "", "success");
											})
											.catch(function(error) {
												swal(component.props.intl.formatMessage({ id: 'personasGrid.modal_blanquear_password.information_error', defaultMessage: 'Error' }), "", "error");
											});
									}
								});
							}
						});
					},
					rowCallback: function(row, data) {
						if (data.foto) {
							let photoUrl = Config.get('apiUrlBase') + '/personas/foto/' + data.foto;
							fetch(photoUrl, {
								method: 'GET',
								headers: {
									'Authorization-Token': localStorage.getItem("token")
								}
							}).then(response => {
								if (response.status === 200) {
									return response.blob();
								} else {
									$('#avatar', row).addClass('dt-user-avatar');
									$('#avatar', row).html(data.nombre.substr(0, 1).toUpperCase() + data.apellido.substr(0, 1).toUpperCase());
								}
							}).then(imgBlob => {
								$('#avatar', row).addClass('dt-user-avatar-image');
								$('#avatar', row).css('background-image', 'url(' + URL.createObjectURL(imgBlob) + ')');
							}).catch(() => {
								$('#avatar', row).addClass('dt-user-avatar');
								$('#avatar', row).html(data.nombre.substr(0, 1).toUpperCase() + data.apellido.substr(0, 1).toUpperCase());
							});
						} else {
							$('#avatar', row).addClass('dt-user-avatar');
							$('#avatar', row).html(data.nombre.substr(0, 1).toUpperCase() + data.apellido.substr(0, 1).toUpperCase());
						}
					}
				}, datatablesConfig(this.props.intl))
			);

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					name: 'filterReset',
					text: '<img id="filterReset" class="filter-reset" src="'+LimpiarFiltros+'"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'personasGrid.tools_restore_filters.title_restaurar_filtros_y_ordenamiento', defaultMessage: 'Restaurar filtros y ordenamiento' }),
					action: function (e, dt, node, config) {
						var index = 0;
						component.table.columns().every(function() {
							var column = this;

							if (dt.settings()[0].aoColumns[index].filterType) {
								column.search('', true, false);
							}
							index++;
							return null;
						});
						$(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
							$(element).val('');
						});
						dt.table().order([ 0, 'asc' ]);
						dt.ajax.reload();
					}
				}, {
					extend: 'print',
					name: 'print',
					text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win) {
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						} else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css('font-size', '10pt')
							.prepend(`<div class="container">
												<div class="row mb-2">
													<div class="col-6">
														<img style="height: 40px" src="` + LogoCompany + `" />
													</div>
													<div class="col-6	text-right">
														<img style="height: 40px" src="` + LogoVecFleet + `" />
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-12" style="border-bottom: 1px solid #000;">
														<h2>`+component.props.intl.formatMessage({ id: 'personasGrid.print_content.header_listado_de_personas', defaultMessage: 'Listado de Personas' })+`</h2>
													</div>
												</div>
											</div>`);

						$(win.document.body).find('table')
							.addClass('compact')
							.css('font-size', 'inherit');
					},
					exportOptions: {
						columns: component.columnsToPrint
					},
					titleAttr: component.props.intl.formatMessage({ id: 'personasGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
				}, {
					extend: 'colvis',
					name: 'columns',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
				}, {
					name: 'exportExcel',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export_excel.title_exportar_a_excel', defaultMessage: 'Exportar a Excel' }),
					action: function (e, dt, node, config) {
						component.setState({
						}, () => {
							$('#export_modal').modal({backdrop: 'static'});
						});
					}
				}]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('filterReset:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('print:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('columns:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('export:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('exportExcel:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();

			$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			this.setState({
				loading: false
			});
		});
	}

	getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}
}

export default injectIntl(PersonasGrid);
