
import {
  TableRow,
} from '@material-ui/core';
import moment from 'moment'
import React, { useState } from "react";
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import iconoCheklist from '../../../src/assets/images/iconos/icono-checklist.png';
import Timezone from '../../commons/timezone/Timezone.js';
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles';
const TicketsGridRow = (props) => {
  const ticket = props.ticket;
  const columnsEnabled = props.columnsEnabled;
  const intl = useIntl();
  const [redirectTo, setRedirectTo] = useState(null)

  const getAvatarTipo = (tipo) => {
    switch (tipo) {
      case "PREVENTIVO":
        return "paleta-preventivo";
      case "VENCIMIENTO":
        return "paleta-vencimiento";
      case "GESTORIA":
        return "paleta-gestoria";
      case "CORRECTIVO":
        return "paleta-correctivo";
      default:
        break;
    }
  };

  const GetTaller = ({ en_taller }) => {
    if (en_taller === 1) {
      return (
        <div className="text-center default dt-info-icon"><i className="ft-check" data-togle="tooltip" data-placement="top" title={
          intl.formatMessage({
            id: "ticketsGrid.column_taller.icon_default.label_taller_si_sin_verificacion",
            defaultMessage: "SI - Sin Verificación",
          })
        }></i></div>
      );
    } else if (en_taller === 2) {
      return (
        <div className="text-center success dt-info-icon"><i className="ft-check" data-togle="tooltip" data-placement="top" title={
          intl.formatMessage({
            id: "ticketsGrid.column_taller.icon_success.label_taller_si_verificado_gps",
            defaultMessage: "SI - Verificado GPS",
          })}></i></div>
      );
    } else if (en_taller === 3) {
      return (
        <div className="text-center danger dt-info-icon"><i className="ft-check" data-togle="tooltip" data-placement="top" title={
          intl.formatMessage({
            id: "ticketsGrid.column_taller.icon_danger.label_taller_no_verificado_gps",
            defaultMessage: "No - Verificado GPS",
          })}></i></div>
      );
    } else {
      return "";
    }
  }

  const GetEntidadIcon = ({ ticket }) => {
    if (ticket.llanta !== null) {
      return (<img src="/images/llantas/tire.svg" style={{ 'height': '16px', 'width': '16px', marginRight: '4px', marginBlockEnd: '4px' }}></img>)
    }

    if (ticket.movil !== null) {
      return (<i className="la la-car" style={{ marginRight: '3px' }}></i>)
    }

    if (ticket.persona !== null) {
      return (<i className="la la-user" style={{ marginRight: '3px' }}></i>)

    } else {
      return (<i className="la la-dog" style={{ marginRight: '3px' }}></i>)
    }
  }

  const goToTicket = (e) => {
    e.preventDefault()
    setRedirectTo(props.ticketUrl)
  }

  const gotToSource = (e) => {
    e.preventDefault()
    switch (ticket.origen) {
      case "Checklist":
        //
        setRedirectTo(`formulario/${ticket.ticket_formulario.id_formulario}`);
        break;
      case "Manual":
        //TODO: Implementar para origen manual
        break;
      case "Automático":
        //TODO: Implementar para origen automático
        break;
      default:
        // return null;
        break;
    }
  }
  let iconUrl;
  switch (ticket.origen) {
    case "Checklist":
      iconUrl = iconoCheklist;//'/images/iconos/icono-checklist.svg';
      break;
    case "Manual":
      // Puedes definir el enlace a la imagen para el caso "Manual" si es necesario.
      break;
    case "Automático":
      // Puedes definir el enlace a la imagen para el caso "Automático" si es necesario.
      break;
    default:
      //      
      break;
  }
  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}

      <TableRow className="all cursor-pointer action edit">
        <StyledTableCell show={props.generacionMasiva} align="left" style={{ maxWidth: '30px' }}>
          <input type="checkbox" checked={props.selectedTickets.includes(ticket.id)} onChange={props.handleChecked(ticket.id)} style={{ maxWidth: "22px" }} className="customCheckbox cursor-pointer" id={ticket.id} />
        </StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket}><div className="d-flex">
          <StyledTooltip title={props.ticketTipo} arrow placement="top">
            <div className={getAvatarTipo(ticket.ticket_tipo) + " avatar dt-user-avatar"}>
              {props.ticketTipo ? props.ticketTipo.substr(0, 1) : ''}
            </div>
          </StyledTooltip>
        </div>
        </StyledTableCell>
        <StyledTableCell align="left" onClick={gotToSource}>
          {ticket.origen && ticket.ticket_formulario && ticket.ticket_formulario.id_formulario ? (
            <StyledTooltip title={`${ticket.ticket_formulario.id_formulario}`} arrow placement="top">
              <div className="" style={{ textAlign: "center", display: "flex", alignItems: "center" }}>
                <img src={iconUrl} alt={ticket.origen} width="24" height="24" style={{ marginRight: "10px" }} />
                <span>{ticket.id}</span>
              </div>
            </StyledTooltip>
          ) : (
            <div><span style={{ marginLeft: "10px" }}>{ticket.id}</span></div>
          )}
        </StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} className={ticket.estadoColor === 4 ? 'status-yellow' : ticket.estadoColor === 2 ? 'status-red' : ticket.estadoColor === 1 ? 'status-green' : 'status-grey'}></StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.estado} >{props.ticketEstado}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.nivelAprobacionPresupuesto}>{ticket.nivel_aprobacion_presupuesto}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} >{ticket.fecha_hora_alta ? Timezone.getDateForClient(ticket.fecha_hora_alta, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY') : ''}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket}>
          <StyledTooltip title={ticket.detalle || ''} arrow placement="top">
            <div className="text-truncate" style={{ maxWidth: "150px" }} >{ticket.detalle}</div>
          </StyledTooltip>
        </StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} >{ticket.servicio_detail ? ticket.servicio_detail.nombre : ''}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} >
          <button className="px-0 btn btn-sm btn-icon btn-dt-grid text-dark" title={intl.formatMessage({ id: "ticketsGrid.column_actions.button_title_trabajar_con_ticket", defaultMessage: "Trabajar con ticket", })} data-togle="tooltip" data-placement="top">
            <GetEntidadIcon ticket={ticket} />
            {ticket.entidad}
          </button>
        </StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} >{ticket.gerenciador_eloquent ? ticket.gerenciador_eloquent.razon_social : ''}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.region}>{ticket.base_eloquent ? (ticket.base_eloquent.region_eloquent ? ticket.base_eloquent.region_eloquent.nombre : '') : ''}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} >{ticket.base_eloquent ? (ticket.base_eloquent.sub_region_eloquent ? ticket.base_eloquent.sub_region_eloquent.nombre : '') : ''}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} >{ticket.base_eloquent ? ticket.base_eloquent.descripcion : ''}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.pais} >{ticket.base_eloquent ? (ticket.base_eloquent.pais_eloquent ? ticket.base_eloquent.pais_eloquent.nombre : '') : ''}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.centroCostos} >{ticket.centro_costos_eloquent ? ticket.centro_costos_eloquent.nombre : ''}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.centroBeneficios} >{ticket.cebe_eloquent ? ticket.cebe_eloquent.nombre : ''}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.responsable1} >{ticket.responsable1}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket}><GetTaller en_taller={ticket.en_taller} /></StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.fechaPrometida}>
          {ticket.fecha_prometida ? moment(ticket.fecha_prometida).format('DD/MM/yyyy') : ""}
        </StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket}>{ticket.mtt_total}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket}>{ticket.mtt_parcial}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.encuestaSatisfaccion} >{ticket.encuesta_nivel_satisfaccion}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} >{ticket.fecha_hora_realizado ? moment(ticket.fecha_hora_realizado).format('DD/MM/yyyy') : ''}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.fechaHoraTurno}>
          {ticket.fecha_hora_turno ? moment(ticket.fecha_hora_turno).format('DD/MM/yyyy') : ""}
        </StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.abono} >{ticket.abono ? intl.formatMessage({ id: 'ticketsGrid.column.abono,si', defaultMessage: 'Si' }) : intl.formatMessage({ id: 'ticketsGrid.column.abono.no', defaultMessage: 'No' })}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket} show={columnsEnabled.tipoModelo} >{ticket.modelo_tipo_nombre}</StyledTableCell>
        <StyledTableCell align="left" onClick={goToTicket}>
          <button className="action edit btn btn-sm btn-icon btn-dt-grid text-success" title={
            intl.formatMessage({
              id: "ticketsGrid.column_actions.button_title_trabajar_con_ticket",
              defaultMessage: "Trabajar con ticket",
            })} data-togle="tooltip" data-placement="top">
            <i className="fa fa-pencil fa-xs"></i>
          </button>
        </StyledTableCell>
      </TableRow >
    </>
  )
}


export default TicketsGridRow
