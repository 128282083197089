import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import * as Utils from '../../commons/utils/Utils.js';
import { Collapse, TextField, CircularProgress, Grid, Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const FILTER_STATE_INIT = {
	id: '',
	descripcion: '',
	subcategoria: '',
	unidad: '',
	tipo: '',
	numero_parte: '',
	referencia: '',
	marca: '',
	costo: '',
	external_code: '',
};

const RepuestosGridFiltros = ({
	selectsData,
	dropdownActive,
	filterQuery,
	setFilterQuery,
	setSearchActive,
	loading,
	columnsEnabled,
	resetFilterInputs,
	setResetFilterInputs,
	showMarca
}) => {
	const intl = useIntl();

	const { subcategorias, unidades, tipos, marcas } = selectsData;
	const [filtersLoading, setFiltersLoading] = useState(false);
	const [filtersState, setFiltersState] = useState({ ...FILTER_STATE_INIT });

	useEffect(() => {
		!dropdownActive && setFiltersLoading(false);
		!dropdownActive && setResetFilterInputs(false)
	}, [dropdownActive]);

	useEffect(() => {
		resetFilterInputs && setFiltersState(FILTER_STATE_INIT)
	}, [resetFilterInputs]);

	const handleChange = (key, object) => {
		setSearchActive(true);

		let query = { ...filterQuery };

		switch (key) {
			// Selects
			case 'almacen':
			case 'subcategoria':
			case 'unidad':
			case 'tipo':
			case 'marca':
				query[key] = object ? object.value : '';
				break;
			// Inputs
			default:
				query[key] = object;
				break;
		}
		// setFilterQuery(query);
	};

	const handleOnEnter = (e, name, value) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			handleChange(name, value);
		}
	};

	const applyFilters = () => {

		const activeFilters = { ...filtersState };

		Object.keys(activeFilters).forEach((key) => {
			if (activeFilters[key] === '' || activeFilters[key] === null || activeFilters[key] === undefined) {
				delete activeFilters[key];
			} else if (['subcategoria', 'unidad', 'tipo', 'marca'].includes(key)) {
				activeFilters[key] = activeFilters[key].value;
			}
		});
		setFilterQuery(activeFilters);

		setSearchActive(true);
	};

	return (
		<>
			<Collapse in={dropdownActive} style={{ marginBottom: 20 }}>
				<Grid container item xs={12} spacing={3} className="filtros">

					{columnsEnabled.external_code && (
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<TextField
									label={intl.formatMessage({
										id: 'panol.external_code',
										defaultMessage: 'External Code',
									})}
									name="external_code"
									value={filtersState.external_code}
									onChange={(e) =>
										setFiltersState({ ...filtersState, external_code: e.target.value })
									}
									disabled={filtersLoading}
									className="col-12"
								/>
							</form>
						</Grid>
					)}

					{columnsEnabled.descripcion && (
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<TextField
									label={intl.formatMessage({
										id: 'panol.item',
										defaultMessage: 'Descripcion',
									})}
									name="descripcion"
									value={filtersState.descripcion}
									onChange={(e) => setFiltersState({ ...filtersState, descripcion: e.target.value })}
									disabled={filtersLoading}
									className="col-12"
								/>
							</form>
						</Grid>
					)}

					{columnsEnabled.subcategoria && (
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<Autocomplete
									size="small"
									name="subcategoria"
									disabled={filtersLoading}
									options={subcategorias}
									value={filtersState.subcategoria}
									getOptionLabel={(option) => (option ? option.label : '')}
									onChange={(e, value) => setFiltersState({ ...filtersState, subcategoria: value })}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="standard"
											label={intl.formatMessage({
												id: 'subcategoria',
												defaultMessage: 'Subcategoria',
											})}
										/>
									)}
								/>
							</form>
						</Grid>
					)}

					{columnsEnabled.unidad && (
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<Autocomplete
									size="small"
									name="unidad"
									disabled={filtersLoading}
									options={unidades}
									value={filtersState.unidad}
									getOptionLabel={(option) => (option ? option.label : '')}
									onChange={(e, value) => setFiltersState({ ...filtersState, unidad: value })}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="standard"
											label={intl.formatMessage({ id: 'unidad_medida', defaultMessage: 'Unidad' })}
										/>
									)}
								/>
							</form>
						</Grid>
					)}

					{columnsEnabled.tipo && (
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<Autocomplete
									size="small"
									name="tipo"
									disabled={filtersLoading}
									options={tipos}
									value={filtersState.tipo}
									getOptionLabel={(option) => (option ? option.label : '')}
									onChange={(e, value) => setFiltersState({ ...filtersState, tipo: value })}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="standard"
											label={intl.formatMessage({ id: 'tipo', defaultMessage: 'Tipo' })}
										/>
									)}
								/>
							</form>
						</Grid>
					)}

					{columnsEnabled.numero_parte && (
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<TextField
									label={intl.formatMessage({
										id: 'panol.numero_parte',
										defaultMessage: 'Número De Parte',
									})}
									name="numero_parte"
									value={filtersState.numero_parte}
									onChange={(e) => setFiltersState({ ...filtersState, numero_parte: e.target.value })}
									disabled={filtersLoading}
									className="col-12"
								/>
							</form>
						</Grid>
					)}

					{columnsEnabled.referencia && (
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<TextField
									label={intl.formatMessage({
										id: 'panol.referencia',
										defaultMessage: 'Referencia',
									})}
									name="referencia"
									value={filtersState.referencia}
									onChange={(e) => setFiltersState({ ...filtersState, referencia: e.target.value })}
									disabled={filtersLoading}
									className="col-12"
								/>
							</form>
						</Grid>
					)}

					{(columnsEnabled.marca && showMarca) ? (
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<Autocomplete
									size="small"
									name="marca"
									disabled={filtersLoading}
									options={marcas}
									value={filtersState.marca}
									getOptionLabel={(option) => (option ? option.label : '')}
									onChange={(e, value) => setFiltersState({ ...filtersState, marca: value })}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="standard"
											label={intl.formatMessage({ id: 'marca', defaultMessage: 'Marca' })}
										/>
									)}
								/>
							</form>
						</Grid>
					) : ''}

					{columnsEnabled.costo && (
						<Grid component={Box} item xs={3}>
							<form noValidate autoComplete="off">
								<TextField
									label={intl.formatMessage({
										id: 'panol.costo',
										defaultMessage: 'Costo',
									})}
									name="costo"
									value={filtersState.costo}
									onChange={(e) => setFiltersState({ ...filtersState, costo: e.target.value })}
									disabled={filtersLoading}
									className="col-12"
								/>
							</form>
						</Grid>
					)}
				</Grid>

				<Grid container item xs={12} justifyContent="flex-end" style={{ marginBottom: 20 }}>
					<Grid
						item
						xs={2}
						style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
					>
						<button
							disabled={loading}
							className="btn btn-primary mr-1"
							onClick={applyFilters}
						>
							<i className="fa fa-search fa-xs"></i>
							<FormattedMessage id="Buscar" defaultMessage="Buscar" />
						</button>
					</Grid>
				</Grid>
			</Collapse>
		</>
	);
};

export default RepuestosGridFiltros;
