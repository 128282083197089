import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Security from "../../commons/security/Security.js";
import $, { map } from "jquery";
import "bootstrap4-duallistbox";
import "bootstrap4-duallistbox/dist/bootstrap-duallistbox.css";
import FormValidation from "../../commons/validation/FormValidation.js";
import Validator from "../../commons/validation/Validator.js";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import Loading from "../ui/Loading.js";
import Select from "react-select";
import "react-select/dist/react-select.css";
import moment from "moment";
import "moment/min/locales";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import "react-dropzone-component/styles/filepicker.css";
import "dropzone/dist/min/dropzone.min.css";
import "../../assets/css/vec-dropzone.css";
import CorrectivosAbmAdjuntos from "./CorrectivosAbmAdjuntos.js";
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from "../../lang/backendStrings.js";
import { ID__UM_ODOMETRO, ID__UM_HOROMETRO } from '../unidadMedidor/constants.js'
import swal from "sweetalert2"

const VALUE_MOVIL = 0;
const VALUE_LLANTA = 1;

class CorrectivosAbm extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		moment.locale("es");
		let searchParams = new URLSearchParams(this.props.location.search);
		let selectedMoviles = this.props.location.state ? this.props.location.state.moviles : null;
		
		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
				id: 0,
				ticketTipo: "CORRECTIVO",
				persona: null,
				movil: props.movil ? props.movil : null,
				llanta: null,
				servicio: null,
				autogestion: false,
				inmovilizado: false,
				abono:
					ConfigBusiness.get("mantenimientos.correctivo.abono.habilitado") ===
					"true"
						? true
						: false,
				gerenciador: null,
				tareas: [],
				items: [],
				estado: "ABIERTO",
				kmGenerado: 0,
				detalle: null,
				esSiniestro: false,
				negligencia: false,
				numeroSiniestro: null,
				adjuntos: [],
				valorDinamico: searchParams.get("valorDinamico"),
				ticketGarantiaId:null,
				ticketPendiente:null,
				esReemplazoDeVehiculo: false,
				fechaEntrega: null,
				placaReemplazo: null,
				otAsociada: null,
				fechaHoraAlta: moment()
			},
			validacionCorrectivo:false,
			diasGarantia: ConfigBusiness.get("tickets.periodoGarantia.periodoDias"),
			tareasCorrectivoGarantia:[],
			entidad: null,
			llantas: [],
			llanta: null,
			moviles: [],
			gerenciadores: [],
			listaSelectServicio: [],
			listaSelectTareas: [],
			listaItems: [],
			itemsIsLoading: false,
			servicioSeleccionado: null,
			tareasSeleccionadas: [],
			itemsSeleccionadas: [],
			errors: [],
			loading: false,
			tareasDisabled: true,
			itemsDisabled: true,
			tareasIsLoading: false,
			gerenciadoresDisabled: true,
			gerenciadoresIsLoading: false,
			displaySelectTareas: false,
			abonoDisabled: false,
			gerenciadoresEnabled: ConfigBusiness.get("mantenimientos.correctivo.gerenciadores.habilitado") === 'true',
			inmovilizadoEnabled: false,
			servicioExpandido: null,
			datosUnidos: [],
			serviciosConTareasItems: [],
			rowTableTareaItems: [],
			inmovilizadoOptions: [
				{
					value: 2,
					label: this.props.intl.formatMessage({
						id: "correctivosAbm.inmovilizadoOptions.no",
						defaultMessage: "NO",
					}),
				},
				{
					value: 1,
					label: this.props.intl.formatMessage({
						id: "correctivosAbm.inmovilizadoOptions.si",
						defaultMessage: "SI",
					}),
				},
			],
			informaFechaApertura: ConfigBusiness.get("tickets.informaFechaApertura.habilitado") === "true",
			entidades: [
				{ value: VALUE_MOVIL,
					label: this.props.intl.formatMessage({id:'correctivosAbm.tickets_entidad.movil' , defaultMessage:"Movil"})
				}, {
					value: VALUE_LLANTA,
					label: this.props.intl.formatMessage({id:'correctivosAbm.tickets_entidad.llanta' , defaultMessage:"Llanta"})
				}
			],
			selectedMoviles: selectedMoviles,
			validacionGeneracionMasiva: []
		};

		this.handleServicioChange = this.handleServicioChange.bind(this);
		this.handleTareasChange = this.handleTareasChange.bind(this);
		this.handleItemsChange = this.handleItemsChange.bind(this);
		this.handleDeleteTarea = this.handleDeleteTarea.bind(this);
		this.handleDeleteServicio = this.handleDeleteServicio.bind(this);
		this.handleMovilChange = this.handleMovilChange.bind(this);
		this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.handleAutogestionChange = this.handleAutogestionChange.bind(this);
		this.handleSiniestroChange = this.handleSiniestroChange.bind(this);
		this.handleNegligenciaChange = this.handleNegligenciaChange.bind(this);
		this.handleAbonoChange = this.handleAbonoChange.bind(this);
		this.handleInmovilizadoChange = this.handleInmovilizadoChange.bind(this);
		this.handleValidacionCorrectivo = this.handleValidacionCorrectivo.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.sendTicket = this.sendTicket.bind(this);
		this.getBackendMoviles = this.getBackendMoviles.bind(this);
		this.handleReemplazoVehiculoChange = this.handleReemplazoVehiculoChange.bind(this);


		this.formValidation = new FormValidation({
			component: this,
			validators: {
				"formData.movil": (value) => Validator.notEmpty(value),
				//"formData.fechaHoraAlta": (value) =>Validator.notNull(value),
				//'formData.inmovilizado': (value) => Validator.notEmpty(value)
			},
		});
	}

	componentDidMount() {
		if (Security.hasPermission("MANTENIMIENTOS_CORRECTIVOS_CREAR")) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: "/error",
			});
		}
	}

	initForm() {
		this.setState({ loading: true });
		let component = this;
		if (this.props.option === "LLANTA") {
			let id = this.props.match.params.llantaId;
			this.ajaxHandler
				.getJson("/llantas/" + id)
				.then((data) => {
					this.setState({ entidad: component.state.entidades[VALUE_LLANTA] });
					this.setState({ llanta: data });
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					console.log("Llanta loaded");
				});
		}
		else {
			if (!ConfigBusiness.get("llantas.habilitado") || !Security.hasPermission("LLANTAS_LISTAR"))
					this.setState({ entidad: component.state.entidades[VALUE_MOVIL] });
		}
		if (this.props.option === "MOVIL") {
			this.setState({ entidad: component.state.entidades[VALUE_MOVIL] });

			if(this.state.selectedMoviles && (!this.state.abonoDisabled || this.state.gerenciadoresEnabled)) {
				
				let subregiones = this.state.selectedMoviles.map(movil => movil.base_eloquent.sub_region_eloquent.id)
				subregiones = [... new Set(subregiones)]
				let queryString = '?'
				subregiones.map(sub => queryString += `subregiones[]=${sub}&`)
				queryString = queryString.slice(0, -1)
				

				this.ajaxHandler.getJson("/gerenciadores/tipo/1/subregion-multi/select"+queryString)
					.then((data) => {
						let gerenciadores = data;
						this.setState({
							gerenciadores: gerenciadores,
							gerenciadoresIsLoading: false,
							gerenciadoresDisabled: false,
						});
						})
					.catch(function (error) {
						this.ajaxHandler.handleError(error);
					});
			}
		}
		Promise.all([
			this.ajaxHandler.getJson("/servicios/tipo-ticket/CORRECTIVO/select"),
			null,
			ConfigBusiness.get("mantenimientos.correctivo.gerenciadores.habilitado"),
			ConfigBusiness.get("tickets.inmovilizado.habilitado"),
			(ConfigBusiness.get("llantas.habilitado") && Security.hasPermission("LLANTAS_LISTAR")) ? this.ajaxHandler.getJson("/llantas") : null,
			ConfigBusiness.get("llantas.servicioGomeriaId"),
			ConfigBusiness.get("llantas.tareasGomeria"),
		])
			.then((data) => {
				let servicios = data[0];
				let gerenciadoresEnabled = data[2] === "true";
				let inmovilizadoEnabled = data[3] === "true";
				if (inmovilizadoEnabled === "true") {
					this.formValidation = new FormValidation({
						component: this,
						validators: {
							"formData.movil": (value) => Validator.notEmpty(value),
							'formData.inmovilizado': (value) => Validator.notEmpty(value)
					 
						},
					});
				}
				let llantas = data[4] ? data[4].llantas : [];
				component.setState(
					{
						llantas: llantas,
						servicios: servicios,
						gerenciadoresEnabled: gerenciadoresEnabled,
						inmovilizadoEnabled: inmovilizadoEnabled,
					},
					() => {
						window.scrollTo(0, 0);
						if (this.props.match.params.movilId) {
							this.handleMovilChange({
								id: this.props.match.params.movilId,
								label: this.props.match.params.movilDominio,
							});
						}
						$("#movil").focus();
					}
				);
				if (this.props.option === "LLANTA") {
					let servicioId = data[5];
					let tareasId = data[6].split(',');
					let tareas = [];
					this.ajaxHandler.getJson("/servicios/" + servicioId)
					.then((res) => {
						this.handleServicioChange({value: res.id, label: res.nombre}, )
					})
					.catch((er) => console.log(er))

					tareasId.forEach((tarea) => {
						this.ajaxHandler.getJson("/tareas/" + tarea)
						.then((res) => {
							tareas.push({value: res.id, label: res.nombre})

						})
						.catch(err => console.log(err))
					})

					this.setState({
						tareasSeleccionadas: tareas,
					});

					// this.handleAddTarea()
					// component.setState({
					//   servicioSeleccionado: { label: data[6].label, value: data[6].id },
					// });
				}
			})
			.catch(function (error) {
				component.ajaxHandler.handleError(error);
			})
			.finally(() => {
				component.setState({ loading: false });
			});
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}

	addServicioNoAsignable() {
		let tareas = [];
		tareas.push({
			id: null,
			nombre: null,
			servicio: {
				id: this.state.servicioSeleccionado.value,
				nombre: this.state.servicioSeleccionado.label,
			},
		});
		this.state.tareasSeleccionadas = tareas;
	}

	handleAddTarea() {
		let tareas = [];

		this.state.tareasSeleccionadas.map((tareaSeleccionada) => {
			tareas.push({
				id:
					this.state.displaySelectTareas === "false"
						? null
						: tareaSeleccionada.value,
				nombre:
					this.state.displaySelectTareas === "false"
						? null
						: tareaSeleccionada.label,
				servicio: {
					id: this.state.servicioSeleccionado.value,
					nombre: this.state.servicioSeleccionado.label,
				},
			});
			return tareaSeleccionada;
		});

		this.loadTareas(tareas);
	}

	handleAddTareaAndItem(e){
		e.preventDefault();
		let tareas = [];
		let items = [];
		this.state.tareasSeleccionadas.map((tareaSeleccionada) => {
			tareas.push({
				id: tareaSeleccionada.value,
				descripcion: tareaSeleccionada.label,
				servicio: {
					id: this.state.servicioSeleccionado.value,
					nombre: this.state.servicioSeleccionado.label,
				},
			});
			return tareaSeleccionada;
		});
		this.state.itemsSeleccionadas.map((itemSeleccionada) => {
			items.push({
				id: itemSeleccionada.id,
				descripcion: itemSeleccionada.descripcion,
				servicio: {
					id: this.state.servicioSeleccionado.value,
					nombre: this.state.servicioSeleccionado.label,
				},
				cantidad: itemSeleccionada.cantidad,
				costo: itemSeleccionada.costo
			});
			return itemSeleccionada;
		});
		this.loadTareasAndItems(tareas, items);
	}

	loadTareasAndItems(tareas, items){
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		tareas.map((tarea) => {
			if (
				formDataCopy.tareas.findIndex(
					(x) => x.id === tarea.id && x.servicio.id === tarea.servicio.id
				) === -1
			) {
				formDataCopy.tareas.push(tarea);
			}
			return tareas;
		});

		items.map((item) => {
			if (
				formDataCopy.items.findIndex(
					(x) => x.id === item.id && x.servicio.id === item.servicio.id
				) === -1
			) {
				formDataCopy.items.push(item);
			}
			return items;
		});
		
		let serviciosConTareasItems = JSON.parse(JSON.stringify(this.state.serviciosConTareasItems));
		if(serviciosConTareasItems.length > 0){
			let index = serviciosConTareasItems.findIndex((x) => x.servicio.value === this.state.servicioSeleccionado.value)
			if(index === -1){
				serviciosConTareasItems.push({
					servicio: this.state.servicioSeleccionado,
					tareas: tareas,
					items: items
				});
			}else{
				tareas.map((tarea) => {
					if (
						serviciosConTareasItems[index].tareas.findIndex(
							(x) => x.id === tarea.id
						) === -1
					) {
						serviciosConTareasItems[index].tareas.push(tarea);
					}
					return tareas;
				});
				items.map((item) => {
					if (
						serviciosConTareasItems[index].items.findIndex(
							(x) => x.id === item.id
						) === -1
					) {
						serviciosConTareasItems[index].items.push(item);
					}
					return items;
				});
			}
		}else{
			serviciosConTareasItems.push({
				servicio: this.state.servicioSeleccionado,
				tareas: tareas,
				items: items
			});
		}

		this.viewTableTareasItems(serviciosConTareasItems, null);

		this.setState({
			formData: formDataCopy,
			servicioSeleccionado: null,
			tareasSeleccionadas: [],
			listaSelectTareas: [],
			listaItems: [],
			itemsSeleccionadas: [],
			tareasDisabled: true,
			itemsDisabled: true,
			serviciosConTareasItems: serviciosConTareasItems
		});
	}

	viewTableTareasItems(serviciosConTareasItems, servicioExpandir){
		let rowTableTareaItems = [];
		serviciosConTareasItems.map((element) => {
			rowTableTareaItems.push({
				servicio: element.servicio,
				tarea: element.tareas[0] ? element.tareas[0].descripcion : "",
				repuesto: element.items[0] ? element.items[0].descripcion : "",
				principal: true
			});
			let expandido = false;
			if (servicioExpandir && this.state.rowTableTareaItems.findIndex((x) => x.servicio.value === servicioExpandir.value && x.principal === false) !== -1){
				expandido = true;
			}
			if(!expandido && servicioExpandir && servicioExpandir.value === element.servicio.value){
				let indexTareas = 1;
				let indexItems = 1;
				let maxLength = element.tareas.length < element.items.length ? element.items.length : element.tareas.length;

				for (let index = 1; index < maxLength; index++) {
					let tarea = "";
					let item = "";
					if(indexTareas <  element.tareas.length){
						tarea = element.tareas[indexTareas].descripcion
						indexTareas++;
					}
					if(indexItems <  element.items.length){
						item = element.items[indexItems].descripcion
						indexItems++;
					}
					rowTableTareaItems.push({
						servicio: element.servicio,
						tarea: tarea,
						repuesto: item,
						principal: false
					});
				}
			}
			return serviciosConTareasItems;
		});
		
		this.setState({
			rowTableTareaItems: rowTableTareaItems
		});
	}

	handleFilaClick(element){
		if(element.principal){
			this.viewTableTareasItems(this.state.serviciosConTareasItems, element.servicio)
		}
	};

	handleDeleteServicio(servicio) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		let serviciosConTareasItems = JSON.parse(JSON.stringify(this.state.serviciosConTareasItems));
		let filtered = serviciosConTareasItems.filter((el) => { return el.servicio.value != servicio.value; });
		formDataCopy.items = formDataCopy.items.filter((el) => { return el.servicio.id != servicio.value; });
		formDataCopy.tareas = formDataCopy.tareas.filter((el) => { return el.servicio.id != servicio.value; });
		this.viewTableTareasItems(filtered, null);
		this.setState({
			serviciosConTareasItems: filtered,
			formData: formDataCopy
		});
	}

	loadTareas(tareas) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		tareas.map((tarea) => {
			if (
				formDataCopy.tareas.findIndex(
					(x) => x.id === tarea.id && x.servicio.id === tarea.servicio.id
				) === -1
			) {
				formDataCopy.tareas.push(tarea);
			}
			return tareas;
		});
		this.setState({
			formData: formDataCopy,
			servicioSeleccionado: null,
			tareasSeleccionadas: [],
			listaSelectTareas: [],
			tareasDisabled: true,
		});
	}

	handleDeleteTarea(index) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.tareas.splice(index, 1);
		this.setState({
			formData: formDataCopy,
		});
	}

	handleMovilChange(object) {
		let component = this;
		if (object) {
			this.setState({ loading: true });
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.movil = object;
			this.setState(
				{
					formData: formDataCopy,
				},
				() => {
					this.ajaxHandler
						.getJson("/moviles/" + object.id)
						.then((data) => {
							let movil = data;
							let gerenciador =
								this.state.formData.autogestion &&
								!this.state.gerenciadoresEnabled
									? null
									: movil.base && movil.base.gerenciador
									? movil.base.gerenciador
									: null;
							let formDataCopy = JSON.parse(
								JSON.stringify(this.state.formData)
							);
							formDataCopy.kmGenerado = data.kmActual ? data.kmActual : 0;
							if (
								ConfigBusiness.get(
									"mantenimientos.correctivo.abono.habilitado"
								) === "true" ||
								this.state.gerenciadoresEnabled
							) {
								this.setState({
									formData: formDataCopy,
									gerenciadoresIsLoading: true,
								});
								this.ajaxHandler
									.getJson(
										"/gerenciadores/tipo/1/subregion/" +
											movil.base.subregion.id +
											"/select"
									)
									.then((data2) => {
										let gerenciadores = data2;
										let formDataCopy = JSON.parse(
											JSON.stringify(this.state.formData)
										);
										if (movil.gerenciador && ConfigBusiness.get('moviles.gerenciador.asignacionManual')) {
											formDataCopy.gerenciador = movil.gerenciador;
										} else {
											formDataCopy.gerenciador = gerenciador;
										}
										formDataCopy.movil = movil;
										this.setState({
											gerenciadores: gerenciadores,
											gerenciadoresIsLoading: false,
											gerenciadoresDisabled: false,
											formData: formDataCopy,
										});
									})
									.catch(function (error) {
										this.ajaxHandler.handleError(error);
									});
							} else {
								let formDataCopy = JSON.parse(
									JSON.stringify(this.state.formData)
								);
								formDataCopy.movil = movil;
								formDataCopy.gerenciador = gerenciador;
								this.setState({
									formData: formDataCopy,
								});
							}
							this.setState({ loading: false });
						})
						.catch(function (error) {
							component.ajaxHandler.handleError(error);
							component.setState({ loading: false });
						});
				}
			);
		} else {
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.movil = null;
			formDataCopy.gerenciador = null;
			this.setState({
				formData: formDataCopy,
				gerenciadoresDisabled: true,
			});
		}
	}

	handleGerenciadorChange(object) {
		if (object) {
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.gerenciador = {
				id: object.value,
				razonSocial: object.label,
			};
			this.setState({
				formData: formDataCopy,
			});
		} else {
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.gerenciador = null;
			this.setState({
				formData: formDataCopy,
			});
		}
	}

	handleAutogestionChange(autogestion) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.autogestion = autogestion;
		if (autogestion) {
			formDataCopy.abono = false;
			if (!this.state.gerenciadoresEnabled) formDataCopy.gerenciador = null;
		}
		let abonoDisabled = autogestion ? true : false;
		this.setState({
			formData: formDataCopy,
			abonoDisabled: abonoDisabled,
		});
	}

	handleSiniestroChange(siniestro) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state));
		formDataCopy.formData.esSiniestro = siniestro;
		if (siniestro === false) {
			formDataCopy.formData.numeroSiniestro = null;
		}
		if (
			ConfigBusiness.get("mantenimientos.correctivo.abono.habilitado") ===
			"true"
		) {
			if (siniestro === true) {
				//si tiene el abono habilitado y el selector de siniestro pasó a true desactivo el abono
				formDataCopy.formData.abono = false;
				//si el gerenciador no esta siempre visible se lo borra ya que es solo para el caso de que el abono sea true
				if (!this.state.gerenciadoresEnabled)
					formDataCopy.formData.gerenciador = null;
			} else {
				formDataCopy.formData.abono = true;
			}
		}

		this.setState(formDataCopy);
	}

	handleNegligenciaChange(negligencia){
		let formDataCopy = JSON.parse(JSON.stringify(this.state));
		formDataCopy.formData.negligencia = negligencia;
		this.setState(formDataCopy);
	}

	handleReemplazoVehiculoChange(reemplazoVehiculo) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state));
		formDataCopy.formData.esReemplazoDeVehiculo = reemplazoVehiculo;
		if (reemplazoVehiculo === false) {
			formDataCopy.formData.fechaEntrega = null;
			formDataCopy.formData.placaReemplazo = null;
		}
		this.setState(formDataCopy);
	}

	handleDatePickerFormChange(name, e){    
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		let date = e ? e.format() : formDataCopy[name] ? moment(formDataCopy[name]): null;
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
	}
	
	handleDatePickerFormRawChange(name, e){
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = null;
		this.setState({
			formData: formDataCopy
		});
	}

	handleAbonoChange(abono) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.abono = abono;
		if (abono && !this.state.gerenciadoresEnabled)
			formDataCopy.gerenciador = null;
		this.setState({
			formData: formDataCopy,
		});
	}

	handleServicioChange(object) {
		if (object) {
			this.setState(
				{
					servicioSeleccionado: object,
					tareasIsLoading: true,
					itemsIsLoading: true,
				},
				() => {
					Promise.all([
						this.ajaxHandler.getJson("/servicios/" + object.value),
						this.ajaxHandler.getJson(
							"/tareas/servicio/" + object.value + "/select"
						),
						(ConfigBusiness.get('mantenimientos.correctivo.tareasItems') === 'true'
							|| ConfigBusiness.get('tickets.trabajaConRepuestos.habilitado') === 'true')
							? this.ajaxHandler.getJson("/items/items-de-servicio/" + object.value)
							 : null,
					])
						.then((data) => {
							let servicio = data[0];
							let listaSelectTareas = data[1];
							let listaItems = data[2] ? data[2] : [];
							this.setState(
								{
									listaSelectTareas: listaSelectTareas,
									displaySelectTareas: true,
									tareasDisabled: false,
									tareasIsLoading: false,
									itemsDisabled: false,
									listaItems: listaItems,
									itemsIsLoading: false
								},
							);
						})
						.catch(function (error) {
							AjaxHandler.handleError(error);
						});
				}
			);
		} else {
			this.setState({
				servicioSeleccionado: null,
				tareasSeleccionadas: [],
				tareasDisabled: true,
				itemsDisabled: true,
				itemsSeleccionadas: []
			});
		}
	}

	handleInmovilizadoChange(inmovilizado) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.inmovilizado = inmovilizado ? inmovilizado.value : null;
		this.setState({
			formData: formDataCopy,
		});
	}

	handleTareasChange(value) {
		this.setState({
			tareasSeleccionadas: value,
		});
	}

	handleItemsChange(value){
		this.setState({
			itemsSeleccionadas: value,
		});
	}

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === "checkbox" ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy,
		});
	}

	handleValidacionCorrectivo(formData){
		let selectedMoviles = this.state.selectedMoviles
		let diasGarantia = this.state.diasGarantia
		this.setState({loading:true})
		this.setState({tareasCorrectivoGarantia:[]})
		this.ajaxHandler
			.fetch("/tickets/correctivoValidacion", {
				method: "POST",
				body: JSON.stringify({
					...formData,
					selectedMoviles: selectedMoviles
				}),
			})
			.then((response) => {
				if (response.status !== 400) {
					response.json().then((data) => {
						
						if(data.length == 0){
							this.setState({validacionCorrectivo:true})
							this.handleSubmit() 
						}
						else {

						this.setState({loading:false})

						let html = ''
						for (const [movil, validacion] of Object.entries(data)) {
							if(validacion.pendiente !== null)
								html += `<p>
												${this.props.intl.formatMessage({ id: "correctivosAbm.validacion_el_movil", defaultMessage:"El vehículo "})}
												<b>${movil}</b>
												${this.props.intl.formatMessage({ id: "correctivosAbm.validacion_pendiente", defaultMessage:"tiene un mantenimiento de tipo correctivo pendiente en el "})}
												<b><a href="/tickets/${validacion.pendiente.ticketId}" target="_blank">Ticket ${validacion.pendiente.ticketId}: </a></b>
												${validacion.pendiente.servicioNombre}
											</p>`
							
							if(validacion.pendiente !== null && validacion.tareasGarantia !== null)
								html += `<p>
													${this.props.intl.formatMessage({ id: "correctivosAbm.validacion_ademas_garantia_dias", defaultMessage:" Y a su vez tuvo un mantenimiento de tipo correctivo hace menos de "})}
												`

							if(validacion.tareasGarantia !== null && validacion.pendiente == null)
								html += `<p>
													${this.props.intl.formatMessage({ id: "correctivosAbm.validacion_el_movil", defaultMessage:"El vehículo "})}
													<b>${movil}</b>
													${this.props.intl.formatMessage({ id: "correctivosAbm.validacion_garantia", defaultMessage:" tuvo un mantenimiento de tipo correctivo hace menos de"})}
												`

							if(validacion.tareasGarantia !== null) {
								html += ` ${diasGarantia}
													${this.props.intl.formatMessage({ id: "correctivosAbm.validacion_garantia_dias", defaultMessage:" días en el "})}
													<b><a href="/tickets/${validacion.tareasGarantia.ticketId}" target="_blank">Ticket ${validacion.tareasGarantia.ticketId}</a></b>
													${this.props.intl.formatMessage({ id: "correctivosAbm.validacion_garantia_tareas_coincidentes", defaultMessage:" con las siguientes tareas coincidentes: "})}
													${validacion.tareasGarantia.tareas}
												</p>`
							}
						}
				 

						html += `<br> ${this.props.intl.formatMessage({ id: "correctivosAbm.title.validacion_desea_continuar", defaultMessage:" ¿Desea continuar de todas maneras?"})}`

						if(data.length !== 0){
							swal({
								title: this.props.intl.formatMessage({ id: "correctivosAbm.title.validacion", defaultMessage:" Ticket Validación"}),
								html: html,
								icon: "info",
								customClass: 'swal-wide',
								allowOutsideClick: false,
								allowEscapeKey: false,
								showCancelButton: true,
								cancelButtonText: `<i class="fa fa-times-circle"></i>${this.props.intl.formatMessage({ id: "cancelar", defaultMessage:" Cancelar"})}`,
								confirmButtonText:`<i class="fa fa-check-circle"></i>${this.props.intl.formatMessage({ id: "aceptar", defaultMessage:" Aceptar"})}`,
								cancelButtonColor: '#FF3145',
								confirmButtonColor: '#0ABFBC',
								focusConfirm: false
								}).then(res => {
								if (res.value) {
										this.setState({validacionCorrectivo:true})
										this.handleSubmit()
								} 
							});
						}
						else{
							this.handleSubmit()
						}
						}
					})
				} else {
					response.json().then((data) => {
						this.setState({
							errors: data.detalle,
						});
					});
				}
				window.scrollTo(0, 0);
			})

	}


	handleSubmit(event) {

		event && event.preventDefault();
		let formDataFirstCopy = this.state.formData;
		if (this.state.llanta) {
			formDataFirstCopy.llanta = this.state.llanta;
			this.setState({ formData: formDataFirstCopy });
		}
		if(ConfigBusiness.get('mantenimientos.correctivo.tareasItems') === 'true' || ConfigBusiness.get('tickets.trabajaConRepuestos.habilitado') === 'true'){
			if((this.state.formData.tareas && this.state.formData.tareas.length > 0) || (this.state.formData.items && this.state.formData.items.length > 0)){
				this.sendTicket();
			}else{
				let errors = this.state.errors;
				errors.push("Debes_ingresar_al_menos_una_tarea_o_un_repuesto");
				this.setState({
					errors: errors,
				});
				window.scrollTo(0, 0);
			}

		} else if (this.state.formData.tareas && this.state.formData.tareas.length > 0) {
			this.sendTicket();
		} else {
			let errors = this.state.errors;
			errors.push("correctivosAbm.errors.deber_ingresar_al_menos_una_tarea");
			this.setState({
				errors: errors,
			});
			window.scrollTo(0, 0);
		}
	}

	sendTicket(){
		let servicio = null;
		if(ConfigBusiness.get('mantenimientos.correctivo.tareasItems') === 'true' || ConfigBusiness.get('tickets.trabajaConRepuestos.habilitado') === 'true'){
			servicio = this.state.formData.tareas[0] ? this.state.formData.tareas[0].servicio : this.state.formData.items[0].servicio;
		}else{
			servicio = this.state.formData.tareas[0].servicio ;
		}
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.servicio = servicio;
		
		if(this.state.validacionCorrectivo === false && !this.state.llanta){
			this.handleValidacionCorrectivo(formDataCopy);
		} else {
			this.setState(
				{
					formData: formDataCopy,
					loading: true,
				},
				() => {
					let component = this;
					if( this.state.selectedMoviles ) {
						let selectedMoviles = component.state.selectedMoviles
						this.ajaxHandler
						.fetch("/tickets/generacionMasiva", {
							method: "POST",
							body: JSON.stringify({
								...this.state.formData,
								selectedMoviles: selectedMoviles
							}),
						})
						.then((response) => {
							if (response.status !== 400) {
								if (formDataCopy.valorDinamico) {
									response.json().then((data) => {
										data.forEach((id) => {
										this.ajaxHandler
											.fetch(
												"/valordinamico/" +
													formDataCopy.valorDinamico +
													"/ticket",
												{
													method: "PUT",
													body: JSON.stringify({
														ticket_id: id,
													}),
												}
											)
											// .then((response) => {
											//   window.history.back();
											// });
										});
									});
								} else {
									component.exit();
								}
							} else {
								response.json().then((data) => {
									this.setState({
										errors: data.detalle,
									});
								});
							}
							window.scrollTo(0, 0);
						})
						.catch((error) => {
							this.ajaxHandler.handleError(error);
						})
						.finally(() => {
							this.setState({ loading: false });
						});
					} else {
					this.ajaxHandler
						.fetch("/tickets", {
							method: "POST",
							body: JSON.stringify({
								...this.state.formData,
							}),
						})
						.then((response) => {
							if (response.status !== 400) {
								if (formDataCopy.valorDinamico) {
									response.json().then((data) => {
										this.ajaxHandler
											.fetch(
												"/valordinamico/" +
													formDataCopy.valorDinamico +
													"/ticket",
												{
													method: "PUT",
													body: JSON.stringify({
														ticket_id: data.id,
													}),
												}
											)
											.then((response) => {
												window.history.back();
											});
									});
								} else {
									component.exit();
								}
							} else {
								response.json().then((data) => {
									this.setState({
										errors: data.detalle,
									});
								});
							}
							window.scrollTo(0, 0);
						})
						.catch((error) => {
							this.ajaxHandler.handleError(error);
						})
						.finally(() => {
							this.setState({ loading: false });
						});
					}
				}
			);
		}



	}

	handleCancel(event) {
		window.history.back();
	}

	exit() {
		this.setState({
			redirectTo: "/tickets",
		});
	}

	handleAdjuntosChange(adjuntos) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.adjuntos = adjuntos;
		this.setState({
			formData: formDataCopy,
		});
	}

	handleEntidadChange(e) {
		this.setState({ entidad: e });
	}

	handleLlantaChange(e) {
		this.setState({ llanta: e });
	}

	handleLlantaInputChange(e) {
		this.ajaxHandler.getJson("/llantas?serial=" + e).then((res) => {
			this.setState({ llantas: res.llantas });
		});
	}

	getBackendMoviles (searchTerm) {
		return this.ajaxHandler.getJson('/moviles/simple-search?search=' + searchTerm).then(res => {
			return {
				options: res,
				complete: true
			}
		});
	}

	validateCorrectivoEntidad(){
		if(this.state.entidad.value === VALUE_MOVIL){
			if(this.state.formData.movil){
				return false;
			}else{
				if(this.state.selectedMoviles && Array.isArray(this.state.selectedMoviles)){
					if(this.state.selectedMoviles.length > 0){
						return false;
					}
				}
			}
		}
		if(this.state.entidad.value === VALUE_LLANTA){
			if(this.state.llanta){
				return false;
			}
		}
		return true;
	}

	render() {
		this.formValidation.validate();
		let formData = this.state.formData;
		let validationState = this.formValidation.state;
		let requiredSymbol = this.state.props.action !== "VIEW" ? " *" : "";
		let entidades = this.state.entidades

		let generacionMasiva = this.state.selectedMoviles && this.state.selectedMoviles.length > 0 ? true :false
		let moviles = this.state.selectedMoviles && this.state.selectedMoviles.map((movil) => {return {value: movil.id, label: movil.dominio}})
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
				<div className="row">
					<div className="col-md-12">
						<div
							className="alert alert-danger"
							role="alert"
							hidden={this.state.errors.length === 0}
						>
							{this.state.errors.map((e, i) => (
								<li key={i}>
									<FormattedMessage
										{...(backendStrings[e]
											? backendStrings[e]
											: backendStrings["errors.default"])}
									/>
								</li>
							))}
						</div>
						<form
							className="form form-horizontal"
							ref="form"
							onSubmit={this.handleSubmit}
						>
							<div className="form-body">
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section">
												<i className="la la-info-circle"></i>
												<FormattedMessage
													id="correctivosAbm.datos_generales"
													defaultMessage=" Datos Generales "
												/>
												<div
													className="float-right"
													style={{ fontSize: "14px" }}
												>
													<FormattedMessage
														id="correctivosAbm.datos_generales.campos_requeridos"
														defaultMessage="* campos requeridos"
													/>
												</div>
											</h4>
											<div className="row">
												<div className="col-md-6">
													<div className="form-group row">
														<label
															className="col-md-4 label-control col-form-label"
															htmlFor="base"
														>
															<FormattedMessage
																id="correctivosAbm.datos_generales.label_entidad"
																defaultMessage="Entidad"
															/> *:
														</label>
														<div className="col-md-8">
															{this.props.action === "VIEW" ? (
																<div className="form-control-static col-form-label form-value">
																	{this.state.entidad
																		? this.state.entidad.label
																		: ""}
																</div>
															) : (
																<div>
																	<Select
																		id="entidad"
																		name="entidad"
																		placeholder={this.props.intl.formatMessage({
																			id:
																				"correctivosAbm.datos_generales.placeholder_entidad",
																			defaultMessage: "Entidad",
																		})}
																		options={entidades}
																		valueKey="value"
																		labelKey="label"
																		disabled={!ConfigBusiness.get("llantas.habilitado") || !Security.hasPermission("LLANTAS_LISTAR") || generacionMasiva }
																		value={
																			this.state.entidad
																				? {
																						value: this.state.entidad.value,
																						label: this.state.entidad.label,
																					}
																				: null
																		}
																		onChange={(e) =>
																			this.handleEntidadChange(e)
																		}
																	/>
																	<div
																		className="help-block text-danger field-message"
																		hidden={
																			validationState.formData.movil.pristine ||
																			validationState.formData.movil.valid
																		}
																	>
																		{validationState.formData.movil.message}
																	</div>
																</div>
															)}
														</div>
													</div>
												</div>
												{ConfigBusiness.get("mantenimientos.correctivo.tipoMantenimiento.habilitado")==="true" &&
													formData.movil && this.state.entidad &&  this.state.entidad.value === VALUE_MOVIL ? (
													<div className="col-md-6">
														<div className="form-group row">
															<label
																className="col-md-4 label-control col-form-label"
																htmlFor="kmActual"
															>
																<FormattedMessage
																	id="Tipo_de_Mantenimiento"
																	defaultMessage="Tipo de Mantenimiento"
																/>
															</label>
															<div className="col-md-8">
																<div className="form-control-static col-form-label form-value">
																	{
																		formData.movil ?
																			(formData.movil.funcion ? formData.movil.funcion.nombre : '')
																		: ''
																	}
																</div>
															</div>
														</div>
													</div>)
													: (
														this.state.llanta && this.state.llanta.movil ? (
															<div className="col-md-6">
																<div className="form-group row">
																	<label
																		className="col-md-4 label-control col-form-label"
																		htmlFor="kmActual"
																	>
																		<FormattedMessage
																			id="correctivosAbm.datos_generales.label_vehiculo_asociado"
																			defaultMessage="Vehiculo Asociado :"
																		/>
																	</label>
																	<div className="col-md-8">
																		<div className="form-control-static col-form-label form-value">
																			{this.state.llanta.movil.dominio}
																		</div>
																	</div>
																</div>
															</div>
													) : null)
												}
											</div>
											{this.state.entidad ? (
												this.state.entidad.value === VALUE_LLANTA ? (
													<div className="row">
														<div className="col-md-6">
															<div className="form-group row">
																<label
																	className="col-md-4 label-control col-form-label"
																	htmlFor="base"
																>
																	<FormattedMessage
																		id="correctivosAbm.datos_generales.label_llanta"
																		defaultMessage="Llanta"
																	/> *:
																</label>
																<div className="col-md-8">
																	{this.props.action === "VIEW" ? (
																		<div className="form-control-static col-form-label form-value">
																			{formData.movil
																				? formData.movil.dominio
																				: ""}
																		</div>
																	) : (
																		<div>
																			<Select
																				id="llanta"
																				name="llanta"
																				placeholder={this.props.intl.formatMessage(
																					{
																						id:
																							"correctivosAbm.datos_generales.placeholder.select_llanta",
																						defaultMessage: "Llanta",
																					}
																				)}
																				options={this.state.llantas}
																				valueKey="id"
																				labelKey="serial"
																				value={this.state.llanta}
																				onInputChange={(e) =>
																					this.handleLlantaInputChange(e)
																				}
																				onChange={(e) =>
																					this.handleLlantaChange(e)
																				}
																			/>
																			<div
																				className="help-block text-danger field-message"
																				hidden={
																					validationState.formData.movil
																						.pristine ||
																					validationState.formData.movil.valid
																				}
																			>
																				{validationState.formData.movil.message}
																			</div>
																		</div>
																	)}
																</div>
															</div>
														</div>
														{this.state.llanta ? (
															<div className="col-md-6">
																<div className="form-group row">
																	<label
																		className="col-md-4 label-control col-form-label"
																		htmlFor="kmActual"
																	>
																		<FormattedMessage
																			id="correctivosAbm.datos_generales.label_odometro_llanta"
																			defaultMessage="Odometro Llanta :"
																		/>
																	</label>
																	<div className="col-md-8">
																		<div className="form-control-static col-form-label form-value">
																			{this.state.llanta.odometro
																				? this.state.llanta.odometro
																				: ""}
																		</div>
																	</div>
																</div>
															</div>
														) : null}
													</div>
												) : null
											) : null}
											{this.state.entidad ? (
												this.state.entidad.value === VALUE_MOVIL ? (
													generacionMasiva ? (
														<div className="row">
															<div className="col-md-6">
																<div className="form-group row">
																	<label
																		className="col-md-4 label-control col-form-label"
																		htmlFor="moviles"
																	>
																		<FormattedMessage
																			id="correctivosAbm.datos_generales.label_moviles"
																			defaultMessage="Moviles"
																		/> *:
																	</label>
																	<div className="col-md-8">
																			<Select
																					id="moviles"
																					name="moviles"
																					valueKey='value'
																					labelKey='label'
																					multi={true}
																					value={moviles}
																					disabled={true}
																			/>
																	</div>
																</div>
															</div>
														</div>
													) : (
													<div className="row">
														<div className="col-md-6">
															<div className="form-group row">
																<label
																	className="col-md-4 label-control col-form-label"
																	htmlFor="base"
																>
																	<FormattedMessage
																		id="correctivosAbm.datos_generales.label_base"
																		defaultMessage="Movil"
																	/>*:
																</label>
																<div className="col-md-8">
																	{this.props.action === "VIEW" ? (
																		<div className="form-control-static col-form-label form-value">
																			{formData.movil
																				? formData.movil.dominio
																				: ""}
																		</div>
																	) : (
																		<div>
																			<Select.Async
																					name="storageTypeId"
																					value={formData.movil}
																					valueKey="id"
																					labelKey="dominio"
																					loadOptions={this.getBackendMoviles}
																					onChange={(e) =>
																						this.handleMovilChange(e)
																					}
																					disabled={this.props.action === "VIEW"}
																					placeholder={this.props.intl.formatMessage(
																						{
																							id:
																								"correctivosAbm.datos_generales.placeholder_select_movil",
																							defaultMessage: "Móvil",
																						}
																					)}
																				/>
																			<div
																				className="help-block text-danger field-message"
																				hidden={
																					validationState.formData.movil
																						.pristine ||
																					validationState.formData.movil.valid
																				}
																			>
																				{validationState.formData.movil.message}
																			</div>
																		</div>
																	)}
																</div>
															</div>
														</div>
														<div className="col-md-6">
															{formData.movil && formData.movil.unidadMedidorId ? (
															<div className="form-group row">
																<label
																	className="col-md-4 label-control col-form-label"
																	htmlFor="kmActual"
																>
																	{ ID__UM_ODOMETRO === formData.movil.unidadMedidorId ? (
																		<FormattedMessage
																			id="correctivosAbm.datos_generales.km_actual"
																			defaultMessage="Km. Actual :"/>
																	):''}
																	{ ID__UM_HOROMETRO ===  formData.movil.unidadMedidorId ? (
																		<FormattedMessage
																			id="correctivosAbm.datos_generales.horometro_actual"
																			defaultMessage="Horometro Actual :"/>
																	):''}
																</label>
																<div className="col-md-8">
																	<div className="form-control-static col-form-label form-value">
																		{formData.movil && formData.movil.kmActual
																			? Math.floor(formData.movil.kmActual)
																			: ""}
																	</div>
																</div>
															</div>
															):''}
														</div>
													</div>
													)
												) : null
											) : null}
											<div className="row">
												{/* DETALLE */}
												<div className="col-md-12">
													<div className="form-group row">
														<label
															className="col-md-2 label-control col-form-label"
															htmlFor="detalle"
														>
															<FormattedMessage
																id="correctivosAbm.datos_generales.detallle"
																defaultMessage="Detallle:"
															/>
														</label>
														<div className="col-md-10">
															<div>
																<textarea
																	className="form-control"
																	id="detalle"
																	name="detalle"
																	rows="3"
																	placeholder={this.props.intl.formatMessage({
																		id:
																			"correctivosAbm.datos_generales.placeholder.detalle",
																		defaultMessage: "Detalle",
																	})}
																	value={
																		formData.detalle ? formData.detalle : ""
																	}
																	onChange={this.handleInputFormChange}
																></textarea>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* EDICION FECHA ALTA */}
											{ this.state.informaFechaApertura ? (
											<div className="row">
												<div className="col-md-6">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label"><FormattedMessage id="correctivosAbm.datos_generales.fecha_apertura" defaultMessage="Fecha Apertura:"/>{requiredSymbol}</label>
														<div className="col-md-8">
															<DatePicker
																	id="fechaHoraAlta"
																	name="fechaHoraAlta"
																	className="form-control date-picker-placeholder"																	
																	placeholderText={this.props.intl.formatMessage({ id: 'correctivosAbm.placeholder_fecha_hora_de_alta', defaultMessage: 'DD/MM/AAAA HH:MM' })}																	
																	selected={formData.fechaHoraAlta === null || formData.fechaHoraAlta === '0000-00-00 00:00' ? null : moment(formData.fechaHoraAlta)}																	
																	onChange={(event) => this.handleDatePickerFormChange("fechaHoraAlta", event)}
																	onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaHoraAlta", event.target.value)}
																	showTimeSelect
																	timeIntervals = { 10 }
																	dateFormat="DD/MM/yyyy HH:mm"
																	maxDate={moment()}
																	required
																	/>
														</div>
													</div>
												</div>
											</div>
											):  null}

											<div className="row">
												{/* AUTOGESTIÓN */}
												<div className="col-md-3">
													<div className="form-group row">
														<label
															className="col-md-8 label-control col-form-label text-nowrap"
															htmlFor="autogestion"
														>
															<FormattedMessage
																id="correctivosAbm.datos_generales.autogestion"
																defaultMessage="Autogestión:"
															/>
														</label>
														<div className="col-md-4">
															<Switch
																onChange={this.handleAutogestionChange}
																checked={formData.autogestion}
																id="autogestion"
																name="autogestion"
																disabled={
																	this.state.props.action === "VIEW"
																		? true
																		: false
																}
																offColor="#FF4961"
																onColor="#28D094"
															/>
														</div>
													</div>
												</div>
												<div className="col-md-5">
													<div className="row">
														<div className="col-md-4">
															<div className="form-group row">
																<label
																	className="col-md-8 label-control col-form-label text-nowrap"
																	htmlFor="siniestro"
																>
																	<FormattedMessage
																		id="correctivosAbm.datos_generales.siniestro"
																		defaultMessage="Siniestro:"
																	/>
																</label>
																<div style={{paddingLeft: '10px'}} className="col-md-4">
																	<Switch
																		onChange={this.handleSiniestroChange}
																		checked={formData.esSiniestro}
																		id="siniestro"
																		name="siniestro"
																		offColor="#FF4961"
																		onColor="#28D094"
																	/>
																</div>
															</div>
														</div>
														{this.state.formData.esSiniestro && !generacionMasiva ? (
															<div className="col-md-8">
																<div className="form-group row">
																	<label
																		className="col-md-5 label-control col-form-label text-nowrap"
																		htmlFor="numeroSiniestro"
																	>
																		<FormattedMessage
																			id="correctivosAbm.datos_generales.nro_siniestro"
																			defaultMessage="nro_siniestro"
																		/>
																	</label>
																	<div className="col-md-7">
																		<input
																			type="text"
																			className="form-control"
																			id="numeroSiniestro"
																			name="numeroSiniestro"
																			placeholder="Número de Siniestro"
																			value={
																				formData.numeroSiniestro
																					? formData.numeroSiniestro
																					: ""
																			}
																			onChange={this.handleInputFormChange}
																		></input>
																	</div>
																</div>
															</div>
														) : null}
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group row">
														<label
															className="col-md-5 label-control col-form-label text-nowrap"
															htmlFor="negligencia"
														>
															<FormattedMessage
																id="Negligencia_SI_o_NO"
																defaultMessage="Negligencia (SI/NO)"
															/>:
														</label>
														<div className="col-md-7">
															<Switch
																onChange={this.handleNegligenciaChange}
																checked={formData.negligencia}
																id="negligencia"
																name="negligencia"
																disabled={
																	this.state.props.action === "VIEW"
																		? true
																		: false
																}
																offColor="#FF4961"
																onColor="#28D094"
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													{/* Inmovilizado */}
													{this.state.inmovilizadoEnabled ? (
														<div className="form-group row">
															<label
																className="col-md-4 label-control col-form-label"
																htmlFor="inmovilizado"
															>
																<FormattedMessage
																	id="correctivosAbm.datos_generales.inmovilizado"
																	defaultMessage="Inmovilizado"
																/>
																{requiredSymbol}:
															</label>
															<div className="col-md-8">
																{this.props.action === "VIEW" ? (
																	<div className="form-control-static col-form-label form-value">
																		{this.state.formData.inmovilizado
																			? this.state.formData.inmovilizado
																			: ""}
																	</div>
																) : (
																	<div>
																		<Select
																			id="inmovilizado"
																			name="inmovilizado"
																			placeholder={"Seleccione"}
																			options={this.state.inmovilizadoOptions}
																			valueKey="value"
																			labelKey="label"
																			value={
																				formData.inmovilizado
																					? {
																							value: formData.inmovilizado,
																							label:
																								formData.inmovilizado === 2
																									? "NO"
																									: "SI",
																						}
																					: null
																			}
																			onChange={(e) =>
																				this.handleInmovilizadoChange(e)
																			}
																			//isLoading={state.estadosLoading}
																			//disabled={state.estadosLoading}
																		/>
																		{validationState.formData.inmovilizado ? (
																			<div
																				className="help-block text-danger field-message"
																				hidden={
																					validationState.formData.inmovilizado
																						.pristine ||
																					validationState.formData.inmovilizado
																						.valid
																				}
																			>
																				{
																					validationState.formData.inmovilizado
																						.message
																				}
																			</div>
																		) : (
																			""
																		)}
																	</div>
																)}
															</div>
														</div>
													) : (
														""
													)}
												</div>
												<div className="col-md-6">
													<div className="row">
														{/* ABONO */}
														{ConfigBusiness.get(
															"mantenimientos.correctivo.abono.habilitado"
														) === "true" ? (
															<div className="col-md-4">
																<div className="form-group row">
																	<label
																		className="col-md-4 label-control col-form-label text-nowrap"
																		htmlFor="abono"
																	>
																		<FormattedMessage
																			id="correctivosAbm.datos_generales.abono"
																			defaultMessage="Abono:"
																		/>
																	</label>
																	<div className="col-md-8">
																		<Switch
																			onChange={this.handleAbonoChange}
																			checked={formData.abono}
																			id="abono"
																			name="abono"
																			disabled={
																				this.state.props.action === "VIEW" ||
																				this.state.abonoDisabled
																					? true
																					: false
																			}
																			offColor="#FF4961"
																			onColor="#28D094"
																		/>
																	</div>
																</div>
															</div>
														) : (
															""
														)}
														{/* GERENCIADOR */}
														<div
															className="col-md-8"
															style={{
																display:
																	formData.abono ||
																	this.state.gerenciadoresEnabled
																		? ""
																		: "none",
															}}
														>
															<div className="form-group row">
																<label
																	className="col-md-4 label-control col-form-label"
																	htmlFor="gerenciador"
																>
																	<FormattedMessage
																		id="correctivosAbm.datos_generales.gerenciador"
																		defaultMessage="Gerenciador:"
																	/>
																</label>
																<div className="col-md-8">
																	{this.props.action === "VIEW" ? (
																		<div className="form-control-static col-form-label form-value">
																			{formData.gerenciador
																				? formData.gerenciador
																				: ""}
																		</div>
																	) : (
																		<div>
																			<Select
																				id="gerenciador"
																				name="gerenciador"
																				placeholder={this.props.intl.formatMessage(
																					{
																						id:
																							"correctivosAbm.datos_generales.placeholder.gerenciador",
																						defaultMessage: "Gerenciador",
																					}
																				)}
																				options={this.state.gerenciadores}
																				valueKey="value"
																				labelKey="label"
																				value={
																					formData.gerenciador
																						? {
																								value: formData.gerenciador.id,
																								label:
																									formData.gerenciador
																										.razonSocial,
																							}
																						: null
																				}
																				onChange={(e) =>
																					this.handleGerenciadorChange(e)
																				}
																			/>
																			<div
																				className="help-block text-danger field-message"
																				hidden={
																					validationState.formData.movil
																						.pristine ||
																					validationState.formData.movil.valid
																				}
																			>
																				{validationState.formData.movil.message}
																			</div>
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											{(ConfigBusiness.get("tickets.reemplazoVehiculo.habilitado") === "true" && (this.state.entidad && this.state.entidad.value === VALUE_MOVIL) && !generacionMasiva) ? (
											<div className="row">
												<div className="col-md-3">
													<div className="form-group row">
														<label
															className="col-md-8 label-control col-form-label text-nowrap" htmlFor="reemplazoVehiculo">
															<FormattedMessage
																id="correctivosAbm.datos_generales.reemplazo_vehiculo"
																defaultMessage="Reemplazo de Vehículo:"
															/>
														</label>
														<div className="col-md-4">
															<Switch
																onChange={this.handleReemplazoVehiculoChange}
																checked={formData.esReemplazoDeVehiculo}
																id="reemplazoVehiculo"
																name="reemplazoVehiculo"
																offColor="#FF4961"
																onColor="#28D094"
																/>
														</div>
													</div>
												</div>
												{this.state.formData.esReemplazoDeVehiculo ? (
													<div className="col-md-3">
														<div className="col-md-12">
															<div className="form-group row">
																<label className="col-md-7 label-control col-form-label text-nowrap" htmlFor="fechaEntrega" >
																	<FormattedMessage id="correctivosAbm.datos_generales.fecha_entrega" defaultMessage="Fecha de entrega:" />
																</label>
																<div className="col-md-5">
																	<DatePicker
																	id="fechaEntrega"
																	name="fechaEntrega"
																	className="form-control date-picker-placeholder"
																	placeholderText={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_fecha_de_alta', defaultMessage: 'DD/MM/AAAA' })}
																	selected={formData.fechaEntrega === null || formData.fechaEntrega === '0000-00-00' ? null : moment(formData.fechaEntrega) }
																	onChange={(event) => this.handleDatePickerFormChange("fechaEntrega", event)}
																	onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaEntrega", event.target.value)}
																	/>
																</div>
															</div>
														</div>
													</div>
													) : null}
													{this.state.formData.esReemplazoDeVehiculo ? (
													<div className="col-md-3">
														<div className="col-md-12">
															<div className="form-group row">
																<label className="col-md-7 label-control col-form-label text-nowrap" htmlFor="placaReemplazo" >
																	<FormattedMessage id="correctivosAbm.datos_generales.placa_reemplazo" defaultMessage="Placa de reemplazo:" />
																</label>
																<div className="col-md-5">
																<input
																	type="text"
																	className="form-control"
																	id="placaReemplazo"
																	name="placaReemplazo"
																	placeholder="Placa de reemplazo"
																	value={formData.placaReemplazo ? formData.placaReemplazo : ""}
																	onChange={this.handleInputFormChange}
																></input>
																</div>
															</div>
														</div>
													</div>
													) : null}
											</div>
											) : ("")}
											{(ConfigBusiness.get("tickets.OTAsociada.habilitado") === "true") ? (
											<div className="row">
												<div className="col-md-6">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="otAsociada" >
															<FormattedMessage id="correctivosAbm.datos_generales.ot_asociada" defaultMessage="OT. Asociada" />:
														</label>
														<div className="col-md-8">
															<input
																type="text"
																className="form-control"
																id="otAsociada"
																name="otAsociada"
																placeholder="OT. Asociada"
																maxLength="20"
																value={formData.otAsociada ? formData.otAsociada : ""}
																onChange={this.handleInputFormChange}
															></input>
														</div>
													</div>
												</div>
											</div>
											) : ("")}
										</div>
									</div>
								</div>
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section mt-2">
												<i className="la la-cog"></i>
												<FormattedMessage
													id="correctivosAbm.tareas"
													defaultMessage=" Tareas"
												/>
											</h4>
											<div
												className="row"
												style={{
													display: this.state.displaySelectTareas ? "" : "none",
												}}
											>
												{/* SERVICIO CON TAREAS NO ASIGNABLES*/}
												<div className="col-md-5">
													<div className="form-group row">
														<label
															className="col-md-4 label-control col-form-label"
															htmlFor="servicio"
														>
															<FormattedMessage
																id="correctivosAbm.tareas.servicio"
																defaultMessage="Servicio"
															/> *:
														</label>
														<div className="col-md-8">
															<div>
																<Select
																	id="servicioNA"
																	name="servicioNA"
																	placeholder={this.props.intl.formatMessage({
																		id:
																			"correctivosAbm.tareas.placeholder.servicio",
																		defaultMessage: "Servicio",
																	})}
																	options={this.state.servicios}
																	valueKey="value"
																	labelKey="label"
																	value={this.state.servicioSeleccionado}
																	onChange={(e) => this.handleServicioChange(e)}
																/>
															</div>
														</div>
													</div>
												</div>
												{ConfigBusiness.get('mantenimientos.correctivo.tareasItems') === 'true' || ConfigBusiness.get('tickets.trabajaConRepuestos.habilitado') === 'true' ? ""
													: (
														<div
															className="col-md-1 p-0"
															style={{
																display:
																	this.state.displaySelectTareas ||
																	this.state.servicioSeleccionado === null ||
																	this.state.tareasIsLoading
																		? "none"
																		: "",
															}}
														>
															<button
																className="btn btn-icon-add text-center text-fleet p-0"
																role="button"
																onClick={(e) => {
																	this.addServicioNoAsignable();
																	this.handleAddTarea();
																	e.preventDefault();
																}}
																style={{ backgroundColor: "#FFF" }}
															>
																<i className="ft-plus-circle"></i>
															</button>
														</div>
													)
												}
												<div className="col-md-6">
													<div className="bs-callout-info callout-border-left mb-1 p-1">
														<strong>
															<FormattedMessage
																id="correctivosAbm.tareas.information.servicio.recuerde_que"
																defaultMessage="Recuerde que..."
															/>
														</strong>
														<p>
															<FormattedMessage
																id="correctivosAbm.tareas.information.servicio.primer_servicio_seleccionado_mayor_importancia"
																defaultMessage="El primer servicio seleccionado debe ser el de mayor importancia."
															/>
														</p>
													</div>
												</div>
												{ConfigBusiness.get('mantenimientos.correctivo.tareasItems') === 'true' || ConfigBusiness.get('tickets.trabajaConRepuestos.habilitado') === 'true' ? 
													<>
														{/* TAREAS Y REPUESTOS */}
														<div
															className="col-md-5"
															style={{
																display: this.state.displaySelectTareas
																	? ""
																	: "none",
															}}
														>
															<div className="form-group row">
																<label
																	className="col-md-3 label-control col-form-label"
																	htmlFor="tareasServicio"
																>
																	<FormattedMessage
																		id="correctivosAbm.tareas.select.add.tareas"
																		defaultMessage="Tareas"
																	/>:
																</label>
																<div className="col-md-9">
																	{this.props.action === "VIEW" ? null : (
																		<div>
																			<Select
																				id="tareasServicioNA"
																				name="tareasServicioNA"
																				placeholder={this.props.intl.formatMessage({
																					id:
																						"correctivosAbm.tareas.select.add.placeholder",
																					defaultMessage: "Tareas",
																				})}
																				options={this.state.listaSelectTareas}
																				valueKey="value"
																				labelKey="label"
																				closeOnSelect={true}
																				multi
																				removeSelected={true}
																				joinValues
																				value={this.state.tareasSeleccionadas}
																				disabled={this.state.tareasDisabled}
																				isLoading={this.state.tareasIsLoading}
																				onChange={this.handleTareasChange}
																			/>
																		</div>
																	)}
																</div>
															</div>
														</div>
														<div
															className="col-md-5"
															style={{
																display: this.state.displaySelectTareas
																	? ""
																	: "none",
															}}
														>
															<div className="form-group row">
																<label
																	className="col-md-3 label-control col-form-label"
																	htmlFor="RepuestosServicio"
																>
																	<FormattedMessage
																		id="Repuestos"
																		defaultMessage="Repuestos"
																	/> :
																</label>
																<div className="col-md-9">
																	{this.props.action === "VIEW" ? null : (
																		<div>
																			<Select
																				id="RepuestosServicio"
																				name="RepuestosServicio"
																				placeholder={this.props.intl.formatMessage({
																					id: "Repuestos",
																					defaultMessage: "Repuestos",
																				})}
																				options={this.state.listaItems}
																				valueKey="id"
																				labelKey="descripcion"
																				closeOnSelect={true}
																				multi
																				removeSelected={true}
																				joinValues
																				value={this.state.itemsSeleccionadas}
																				disabled={this.state.itemsDisabled}
																				isLoading={this.state.itemsIsLoading}
																				onChange={this.handleItemsChange}
																			/>
																		</div>
																	)}
																</div>
															</div>
														</div>
														<div
															className="col-md-2 p-0"
															style={{
																display: this.state.displaySelectTareas
																	? ""
																	: "none",
															}}
														>
															<button
																className="btn btn-icon-add text-center text-fleet p-0"
																role="button"
																onClick={(e) => {
																	this.handleAddTareaAndItem(e);
																	e.preventDefault();
																}}
																disabled={this.state.itemsSeleccionadas.length === 0 && this.state.tareasSeleccionadas.length === 0}
																style={{ backgroundColor: "#FFF" }}
															>
																<i className="ft-plus-circle"></i>
															</button>
														</div>
													</>
													:

													<>
														{/* TAREAS */}
														<div
															className="col-md-5"
															style={{
																display: this.state.displaySelectTareas
																	? ""
																	: "none",
															}}
														>
															<div className="form-group row">
																<label
																	className="col-md-3 label-control col-form-label"
																	htmlFor="tareasServicio"
																>
																	<FormattedMessage
																		id="correctivosAbm.tareas.select.add.tareas"
																		defaultMessage="Tareas"
																	/> *:
																</label>
																<div className="col-md-9">
																	{this.props.action === "VIEW" ? null : (
																		<div>
																			<Select
																				id="tareasServicioNA"
																				name="tareasServicioNA"
																				placeholder={this.props.intl.formatMessage({
																					id:
																						"correctivosAbm.tareas.select.add.placeholder",
																					defaultMessage: "Tareas",
																				})}
																				options={this.state.listaSelectTareas}
																				valueKey="value"
																				labelKey="label"
																				closeOnSelect={true}
																				multi
																				removeSelected={true}
																				joinValues
																				value={this.state.tareasSeleccionadas}
																				disabled={this.state.tareasDisabled}
																				isLoading={this.state.tareasIsLoading}
																				onChange={this.handleTareasChange}
																			/>
																		</div>
																	)}
																</div>
															</div>
														</div>
														<div
															className="col-md-2 p-0"
															style={{
																display: this.state.displaySelectTareas
																	? ""
																	: "none",
															}}
														>
															<button
																className="btn btn-icon-add text-center text-fleet p-0"
																role="button"
																onClick={(e) => {
																	this.handleAddTarea();
																	e.preventDefault();
																}}
																style={{ backgroundColor: "#FFF" }}
															>
																<i className="ft-plus-circle"></i>
															</button>
														</div>
													</>
												}
											</div>

											<div
												className="row"
												style={{
													display: this.state.displaySelectTareas ? "none" : "",
												}}
											>
												{/* SERVICIO */}
												<div className="col-md-6 pt-1">
													<div className="form-group row">
														<label
															className="col-md-4 label-control col-form-label"
															htmlFor="servicio"
														>
															<FormattedMessage
																id="correctivosAbm.tareas.select.add.servicio"
																defaultMessage="Servicio"
															/> *:
														</label>
														<div className="col-md-8">
															<div>
																<Select
																	id="servicio"
																	name="servicio"
																	placeholder={this.props.intl.formatMessage({
																		id:
																			"correctivosAbm.tareas.select.add.placeholder.servicio",
																		defaultMessage: "Servicio",
																	})}
																	options={this.state.servicios}
																	valueKey="value"
																	labelKey="label"
																	value={this.state.servicioSeleccionado}
																	onChange={(e) => this.handleServicioChange(e)}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="bs-callout-info callout-border-left mb-1 p-1">
														<strong>
															<FormattedMessage
																id="correctivosAbm.tareas.select.add.information.recuerde_que"
																defaultMessage="Recuerde que..."
															/>
														</strong>
														<p>
															<FormattedMessage
																id="correctivosAbm.tareas.select.add.information.primer_servicio_seleccionado_mayor_importancia"
																defaultMessage="El primer servicio seleccionado debe ser el de mayor importancia."
															/>
														</p>
													</div>
												</div>
											</div>
											{ConfigBusiness.get('mantenimientos.correctivo.tareasItems') === 'true' || ConfigBusiness.get('tickets.trabajaConRepuestos.habilitado') === 'true' ?
												<div
													className="row"
													style={{
														display: this.state.rowTableTareaItems.length > 0? "" : "none",
													}}
												>
													{/* TAREAS Y ITEMS SELECCIONADAS */}
													<div className="col-md-12">
														<table className="table">
															<thead className="thead-fleet">
																<tr>
																	<th scope="col">
																		<FormattedMessage
																			id="Servicio"
																			defaultMessage="Servicio"
																		/>
																	</th>
																	<th scope="col">
																		<FormattedMessage
																			id="Tareas"
																			defaultMessage="Tareas"
																		/>
																	</th>
																	<th scope="col">
																		<FormattedMessage
																			id="Repuestos"
																			defaultMessage="Repuestos"
																		/>
																	</th>
																	<th scope="col">
																		<FormattedMessage
																			id="correctivosAbm.tareas.added_tasks.column_table.eliminar"
																			defaultMessage="Eliminar"
																		/>
																	</th>
																</tr>
															</thead>
															<tbody>
																{this.state.rowTableTareaItems.map((element, index) => {
																	return (
																		<tr key={index} onClick={(e) => this.handleFilaClick(element)} style={{cursor:element.principal ? 'pointer': ''}}>
																			<td className="align-middle">
																				{element.servicio && element.principal ? element.servicio.label : ""}
																			</td>
																			<td className="align-middle">
																				{element.tarea}
																			</td>
																			<td className="align-middle">
																				{element.repuesto}
																			</td>
																			<td onClick={e => e.stopPropagation()}>
																				<a
																					className="btn btn-icon text-danger"
																					role="button"
																					onClick={(e) =>
																						this.handleDeleteServicio(element.servicio)
																					}
																					style={{
																						display: !element.principal ? "none" : "",
																					}}
																				>
																					<i className="fa fa-trash fa-xs"></i>
																				</a>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</table>
													</div>
												</div>
												:
												<div
													className="row"
													style={{
														display: formData.tareas.length > 0 ? "" : "none",
													}}
												>
													{/* TAREAS SELECCIONADAS */}
													<div className="col-md-12">
														<table className="table">
															<thead className="thead-fleet">
																<tr>
																	<th scope="col">
																		<FormattedMessage
																			id="correctivosAbm.tareas.added_tasks.column_table.servicio"
																			defaultMessage="Servicio"
																		/>
																	</th>
																	<th scope="col">
																		<FormattedMessage
																			id="correctivosAbm.tareas.added_tasks.column_table.tareas"
																			defaultMessage="Tareas"
																		/>
																	</th>
																	<th scope="col">
																		<FormattedMessage
																			id="correctivosAbm.tareas.added_tasks.column_table.eliminar"
																			defaultMessage="Eliminar"
																		/>
																	</th>
																</tr>
															</thead>
															<tbody>
																{formData.tareas.map((tarea, index) => {
																	return (
																		<tr key={index}>
																			<td className="align-middle">
																				{tarea.servicio.nombre}
																			</td>
																			<td className="align-middle">
																				{tarea.nombre}
																			</td>
																			<td>
																				<a
																					className="btn btn-icon text-danger"
																					role="button"
																					onClick={(e) =>
																						this.handleDeleteTarea(index)
																					}
																				>
																					<i className="fa fa-trash fa-xs"></i>
																				</a>
																			</td>
																		</tr>
																	);
																})}
															</tbody>
														</table>
													</div>
												</div>
											}
										</div>
									</div>
								</div>

								<CorrectivosAbmAdjuntos
									callbackUpdate={this.handleAdjuntosChange.bind(this)}
								></CorrectivosAbmAdjuntos>

								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<div className="text-cd text-right">
												{this.props.action !== "VIEW" && (
													<button
														type="submit"
														className="btn btn-primary mr-1"
														disabled={(this.state.loading || !this.state.entidad) || this.validateCorrectivoEntidad()}
													>
														<i className="fa fa-check-circle"></i>
														<FormattedMessage
															id="correctivosAbm.button.finish.guardar"
															defaultMessage=" Guardar"
														/>
													</button>
												)}
												<button
													type="button"
													className="btn btn-danger"
													onClick={this.handleCancel.bind(this)}
												>
													<i className="fa fa-times-circle"></i>{" "}
													{this.props.action === "VIEW"
														? this.props.intl.formatMessage({
																id: "correctivosAbm.button.finish.volver",
																defaultMessage: "Volver",
															})
														: this.props.intl.formatMessage({
																id: "correctivosAbm.button.finish.cancelar",
																defaultMessage: "Cancelar",
															})}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(CorrectivosAbm);
