import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'; 
import { FormattedMessage } from 'react-intl'; 
import InsumosMarcasGrid from './InsumosMarcasGrid';
import InsumosMarcasAbm from './InsumosMarcasAbm';
import ConfigBusiness from '../../commons/config/ConfigBusiness';

const InsumosMarcas = ({ match }) => { 

  const showCategoria = ConfigBusiness.get('inventario.marcas.obligatorio') === 'false' ? true : false; 

  return (
    <div className="content-wrapper">
      <div className="content-header row">
        <div className="content-header-left col-md-6 col-12 mb-2">
          <h3 className="content-header-title">
            <FormattedMessage
              id="insumos_marcas.header_administracion_de_marcas"
              defaultMessage="Administración de Marcas"
            />
          </h3>
        </div>
      </div>
      <div className="content-body">
        <Switch>
          <Route exact path={`${match.url}`} render={(props) => <InsumosMarcasGrid {...props} showCategoria={showCategoria} />} />
          <Route exact path={`${match.url}/add`} render={props => <InsumosMarcasAbm {...props} action="ADD" showCategoria={showCategoria} />} />
          <Route exact path={`${match.url}/:id`} render={props => <InsumosMarcasAbm {...props} action="VIEW" showCategoria={showCategoria}/>} />
          <Route exact path={`${match.url}/:id/edit`} render={props => <InsumosMarcasAbm {...props} action="EDIT" showCategoria={showCategoria}/>} />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(InsumosMarcas);