import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js';
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import Loading from '../ui/Loading.js'
import swal from 'sweetalert'
import { FormattedMessage, injectIntl } from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

class TwoFA extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formData: {
				phoneNumber: '',  // Cambiamos email por phoneNumber
				code: '' // Campo para el código de verificación
			},

			codeSent: true,  // Estado para saber si el código fue enviado
			redirectTo: null,
			errors: [],
			loading: false,
			persona: {}
		};

		this.handleFormChange = this.handleFormChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCodeSubmit = this.handleCodeSubmit.bind(this); // Nuevo método para verificar el código

		this.loginRandom = null;
	}

	componentWillMount() {
		if(!localStorage.getItem("persona")){
			this.setState({
				redirectTo: '/',
			})
		}else{
			this.setState({
				persona: JSON.parse(localStorage.getItem("persona"))
			})
		}
		
		this.formValidation = new FormValidation({
			component: this,
			validators: {
				'formData.phoneNumber': (value) => Validator.notEmpty(value),
			}
		});
		this.formValidation2 = new FormValidation({
			component: this,
			validators: {
				'formData.code': (value) => Validator.notEmpty(value),
			}
		});
	}

	handleGoHome() {
		this.setState({
			redirectTo: '/'
		});
	}


	componentDidMount() {
		document.body.className = 'vertical-layout vertical-menu 1-column menu-expanded blank-page';
		document.body.setAttribute('data-col', '1-column');

		// this.refs.phoneNumber.focus(); // Cambiado para referenciar al número de teléfono
	}

	handleFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({ formData: formDataCopy });
	}

	handleSubmit(event) {
		this.setState({ loading: true });
		this.setState({ errors: [] });

		// Enviar código 2FA al número de teléfono
		fetch(Config.get('apiUrlBase') + '/public/2fa/send-code', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				phoneNumber: this.state.formData.phoneNumber,
				token: localStorage.getItem('token')
			}),
		}).then(response => {
			if (response.status === 200) {
				this.setState({
					codeSent: true // Cambiar a estado donde se espera el código
				});
			} else {
				if (response.status === 400) {
					response.json()
						.then(data => {
							this.setState({ errors: data.detalle });
						}).catch(error => {
							this.error();
						});
				} else {
					this.error();
				}
			}
		}).catch((error) => {
			this.error();
		}).finally(() => {
			this.setState({ loading: false });
		});

		event.preventDefault();
	}

	handleCodeSubmit(event) {
		this.setState({ loading: true });
		this.setState({ errors: [] });

		// Verificar el código 2FA ingresado
		fetch(Config.get('apiUrlBase') + '/public/2fa/verify', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				code: this.state.formData.code,
				phoneNumber: this.state.formData.phoneNumber,
				token: localStorage.getItem('token')
			}),
		}).then(response => {
			response.json()
				.then(data => {
					if (response.status === 200) {
						this.setState({
							redirectTo: '/'
						})
						window.location.reload();
					}else if(response.status === 400){
						swal({
							title: this.props.intl.formatMessage({...(backendStrings[data.error] ? backendStrings[data.error] : backendStrings['errors.default']) }),
							text: "",
							icon: "warning",
							buttons: {
								confirm: {
									text: this.props.intl.formatMessage({ id: '2fa.errors.swal.button.aceptar', defaultMessage: 'Aceptar' }),
									value: true,
									visible: true,
									className: "btn btn-primary",
									closeModal: true
								}
							}
						});
					}else {
						this.error();
					}
				}).catch(error => {
					this.error();
				});
		}).catch((error) => {
			this.error();
		}).finally(() => {
			this.setState({ loading: false });
		});

		event.preventDefault();
	}

	error() {
		swal({
			title: this.props.intl.formatMessage({ id: '2fa.errors.swal.title', defaultMessage: 'Error interno del sistema.' }),
			text: this.props.intl.formatMessage({ id: '2fa.errors.swal.text', defaultMessage: 'Contacte al administrador.' }),
			icon: "error",
			buttons: {
				confirm: {
					text: this.props.intl.formatMessage({ id: '2fa.errors.swal.button.aceptar', defaultMessage: 'Aceptar' }),
					value: true,
					visible: true,
					className: "btn btn-primary",
					closeModal: true
				}
			}
		});
	}

	render() {
		this.formValidation.validate();
		this.formValidation2.validate();
		let validationState = this.formValidation.state;
		let validationState2 = this.formValidation2.state;

		if (!this.loginRandom) {
			this.loginRandom = 1 + Math.floor((4 - 1) * Math.random());
		}

		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading hideSpinner />}
				<div className="app-content content">
					<div className="content-wrapper">
						<div className="content-header row">
						</div>
						<div className="content-body">
							<video className="video-login-bg" autoPlay muted loop type="video/mp4" poster={process.env.PUBLIC_URL + "/images/images-login/image" + this.loginRandom + ".png"}>
								<source src={process.env.PUBLIC_URL + "/images/videos-login/video" + this.loginRandom + ".mp4"} type="video/mp4" />
								<img src={process.env.PUBLIC_URL + "/images/images-login/image" + this.loginRandom + ".png"} alt={this.props.intl.formatMessage({ id: '2fa.video.noSoportado', defaultMessage: 'Video no soportado' })} />
							</video>
							<section className="flexbox-container">
								<div className="col-12 d-flex align-items-center justify-content-center">
									<div className="col-md-4 col-10 box-shadow-2 p-0">
										<div className="card border-grey border-lighten-3 px-2 py-2 m-0">
											<div className="card-header border-0 text-center">
												<img src={process.env.PUBLIC_URL + "/images/company/logo-login-app.png"} alt="" className="rounded-circle img-fluid center-block" />
											</div>

											{!this.state.codeSent ? (
												<React.Fragment>
													<p className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2">
														<span><FormattedMessage id="2fa.enviar.info" defaultMessage="Verificación de teléfono para 2FA" /></span>
													</p>
													<div className="card-content">
														<div className="card-body">
															<p><FormattedMessage id="2fa.phone.info" defaultMessage="Ingrese su teléfono para recibir un código" /></p>
															<form className="form-horizontal" noValidate ref="form" onSubmit={this.handleSubmit}>
																<fieldset className="form-group position-relative has-icon-left">
																	<input type="text" className="form-control form-control-lg input-lg" id="phoneNumber" name="phoneNumber" placeholder={this.props.intl.formatMessage({ id: '2fa.phone.placeholder', defaultMessage: 'Número de teléfono' })} ref="phoneNumber" tabIndex="2" required onChange={this.handleFormChange} />
																	<div className="form-control-position">
																		<i className="la la-phone"></i>
																	</div>
																	<div className="help-block text-danger font-small-3" hidden={validationState.formData.phoneNumber.pristine || validationState.formData.phoneNumber.valid}>{validationState.formData.phoneNumber.message}</div>
																</fieldset>
																<div className="alert alert-danger" role="alert" hidden={this.state.errors.length === 0}>
																	{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])} /></li>)}
																</div>
																<button type="submit" className="btn btn-outline-primary btn-lg btn-block" disabled={!validationState.form.valid}><i className={this.state.loading ? 'la la-spinner spinner' : 'la la-check'}></i><FormattedMessage id="2fa.enviar.button" defaultMessage="Enviar Código" /></button>
																<div className="form-group row mt-2">
																	<div className="col-md-6 col-12 text-center text-sm-left">
																		<button className="buttonAsLink card-link primary" onClick={this.handleGoHome.bind(this)}><i className="la la-chevron-circle-left"></i> <span className="align-top"><FormattedMessage id="2fa.volver_inicio" defaultMessage="Volver al inicio" /></span></button>
																	</div>
																</div>
															</form>
														</div>
													</div>
												</React.Fragment>
											) : (
												// Si el código ya fue enviado, mostramos el campo para ingresar el código
												<React.Fragment>
													<p className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2">
														<span>
															<FormattedMessage
																id='2fa.verificar.info'
																defaultMessage="Ingrese el código 2FA enviado a: *********{number}"
																values={{number: this.state.persona && this.state.persona.celularNumero ? Math.abs(this.state.persona.celularNumero) % 10000 : '****'}}
															/>
														</span>

													</p>
													<div className="card-content">
														<div className="card-body">
															<form className="form-horizontal" noValidate ref="form" onSubmit={this.handleCodeSubmit}>
																<fieldset className="form-group position-relative has-icon-left">
																	<input type="text" className="form-control form-control-lg input-lg" id="code" name="code" placeholder={this.props.intl.formatMessage({ id: '2fa.code.placeholder', defaultMessage: 'Código de verificación' })} ref="code" tabIndex="2" required onChange={this.handleFormChange} />
																	<div className="form-control-position">
																		<i className="la la-key"></i>
																	</div>
																	<div className="help-block text-danger font-small-3" hidden={validationState2.formData.code.pristine || validationState2.formData.code.valid}>{validationState2.formData.code.message}</div>
																</fieldset>
																<div className="alert alert-danger" role="alert" hidden={this.state.errors.length === 0}>
																	{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])} /></li>)}
																</div>
																<button type="submit" className="btn btn-outline-primary btn-lg btn-block" disabled={!validationState2.form.valid}><i className={this.state.loading ? 'la la-spinner spinner' : 'la la-check'}></i><FormattedMessage id="2fa.enviar.button" defaultMessage="Verificar Código" /></button>
															</form>
														</div>
													</div>
												</React.Fragment>
											)}

										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(TwoFA);
