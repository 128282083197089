import { Collapse, TableCell, TableRow } from '@material-ui/core';
import 'moment/min/locales';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { StyledTableCell, StyledTableCellEditable } from '../../commons/utils/TableStyles.js';
import * as Utils from '../../commons/utils/Utils';
import swal from 'sweetalert';

const PanolGridRow = ({ columnsEnabled, item, handleEditItem, handleDelete, marcaObligatorio }) => {
  const intl = useIntl();
  const [redirectTo, setRedirectTo] = useState(null);

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <TableRow>
        {columnsEnabled.external_code && (
          <StyledTableCell align="left">{item.external_code}</StyledTableCell>
        )}
        {columnsEnabled.descripcion && (
          <StyledTableCell align="left">{item.descripcion}</StyledTableCell>
        )}
        {columnsEnabled.categoria && (
          <StyledTableCell align="left">{item.categoria_eloquent && item.categoria_eloquent.nombre}</StyledTableCell>
        )}
        {columnsEnabled.subcategoria && (
          <StyledTableCell align="left">{item.subcategoria_eloquent && item.subcategoria_eloquent.nombre}</StyledTableCell>
        )}
        {columnsEnabled.unidad && (
          <StyledTableCell align="left">{item.unidad_medida && item.unidad_medida.descripcion}</StyledTableCell>
        )}
        {columnsEnabled.tipo && (
          <StyledTableCell align="left">{item.tipo}</StyledTableCell>
        )}
        {columnsEnabled.numero_parte && (
          <StyledTableCell align="left">{item.numero_parte}</StyledTableCell>
        )}
        {columnsEnabled.referencia && (
          <StyledTableCell align="left">{item.referencia}</StyledTableCell>
        )}
        {columnsEnabled.marca && marcaObligatorio ? (
          <StyledTableCell align="left">{item.marca_eloquent && item.marca_eloquent.nombre}</StyledTableCell>
        ) : ''}
        {columnsEnabled.costo && (
          <StyledTableCell align="left">
            {item.costo ? parseFloat(item.costo).toLocaleString('de-DE') : ''}
          </StyledTableCell>
        )}
        <StyledTableCell>
          <button
            className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
            data-title="Editar"
            data-togle="tooltip"
            data-placement="top"
            onClick={handleEditItem}
          >
            <i className="fa fa-pencil fa-xs" />
          </button>
          
          <button
            className="action edit btn btn-sm btn-icon btn-dt-grid text-danger"
            data-title="Editar"
            data-togle="tooltip"
            data-placement="top"
            onClick={handleDelete}
          >
            <i className="fa fa-trash fa-xs" />
          </button>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export default PanolGridRow;
