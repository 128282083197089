import {
	Box,
	Collapse,
	Grid,
	Switch,
	TextField
} from '@material-ui/core'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState, useRef } from "react"
import { FormattedMessage, useIntl } from 'react-intl'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import * as Utils from '../../commons/utils/Utils'
import GravatarOption from './GravatarOption'

const SELECT_OPTIONS_INIT = {
	bases: [],
	tipoModelos: [],
	gerenciadores: [],
	servicios: [],
	estados: [],
	paises: [],
	tipos: [],
	enTallerSelect: [],
	abonoSelect: [],
	realizadoSelect: [],
	regiones: [],
	subregiones: [],
	cecos: [],
	cebes: [],
	nivelesAprobacion: [],
	origenes: [],
}

const FILTER_STATE_INIT = {
	ticketTipo: '',
	id: '',
	estado: '',
	nivelAprobacionPresupuesto: null,
	fechaHoraAlta: '',
	detalle: '',
	servicio: null,
	entidad: '',
	gerenciador: null,
	region: null,
	subRegion: [],
	base: null,
	pais: [],
	centroCostos: '',
	centroBeneficios: '',
	responsable1: '',
	enTaller: null,
	mttTotal: '',
	mttParcial: '',
	encuestaNivelSatisfaccion: '',
	abono: null,
	fechaRealizado: '',
	fechaHoraTurno: '',
	tipoModelo: null,
	origen: null,
	turno: null,
	fechaPrometida: null,
	turnoInicio: '',
	turnoFinal: '',
	fechaPrometidaInicio: '',
	fechaPrometidaFinal: ''
}

const FILTER_QUERY_INIT = {
	entidad: '',
	ticketTipo: 'DEFAULT',
	id: '',
	estado: 'PENDIENTES',
	fechaHoraAlta: '',
	servicio: '',
	movilPersona: '',
	gerenciador: '',
	region: '',
	subRegion: [],
	base: '',
	pais: [],
	fechaRealizado: '',
	enTaller: '',
	detalle: '',
	abono: '',
	centroCostos: '',
	centroBeneficios: '',
	responsable1: '',
	encuestaNivelSatisfaccion: '',
	fechaHoraTurno: '',
	nivelAprobacionPresupuesto: '',
	tipoModelo: '',
	enTallerKpi: '',
	mttTotal: '',
	mttParcial: '',
	origen: '',
	turnoInicio: '',
	turnoFinal: '',
	fechaPrometidaInicio: '',
	fechaPrometidaFinal: ''
}

const getFilterState = (intl) => {
	let filterStateInit =  JSON.parse(JSON.stringify(FILTER_STATE_INIT));
	filterStateInit.ticketTipo = ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? { value: 'DEFAULT', label: intl.formatMessage({ id: 'ticketsGridFilters.select_por_defecto', defaultMessage: 'POR DEFECTO' }) } : ''
	filterStateInit.estado = { value: 'PENDIENTES', label: intl.formatMessage({ id: 'ticketsGridFilters.select_pendientes', defaultMessage: 'PENDIENTES' }) }
	let file = JSON.parse(window.localStorage.getItem('TicketsGridFilterNew'))

	if (file && file.activo && file.filtros) {
		filterStateInit = file.filtros
	}

	return filterStateInit
}

const getPersistedStoragedState = () => {
	let file = JSON.parse(window.localStorage.getItem('TicketsGridFilterNew'))
	return (file !== null && file.activo === true)
}

const TicketGridFilters = (props) => {
	const intl = useIntl()
	const [selectOptions, setSelectOptions] = useState(SELECT_OPTIONS_INIT)
	const [filtersLoading, setFiltersLoading] = useState(false)
	const [filtersState, setFiltersState] = useState(getFilterState(intl))
	const [dataTableColumns, setDataTableColumns] = useState([])
	const [init, setInit] = useState(false)
	const [persisteFiltros, setPersisteFiltros] = useState(getPersistedStoragedState)
	const [validatorSearch, setValidatorSearch] = useState(false)
	const [subRegion, setSubRegion] = useState(null);
	const periodos = [
		{id:1, label: intl.formatMessage({ id: 'Hoy', defaultMessage: 'Hoy' })},
		{id:2, label: intl.formatMessage({ id: 'Ayer', defaultMessage: 'Ayer' })},
		{id:7, label: intl.formatMessage({ id: 'Ultimos_7_dias', defaultMessage: 'Últimos 7 días' })},
		{id:14, label: intl.formatMessage({ id: 'Ultimos_14_dias', defaultMessage: 'Últimos 14 días' })},
		{id:20, label: intl.formatMessage({ id: 'Ultimos_30_dias', defaultMessage: 'Últimos 30 días' })},
		{id:30, label: intl.formatMessage({ id: 'Este_mes', defaultMessage: 'Este mes' })},
		{id:60, label: intl.formatMessage({ id: 'Mes_Pasado', defaultMessage: 'Mes Pasado' })},
		{id:365, label: intl.formatMessage({ id: 'Este_anio', defaultMessage: 'Este año' })},
		{id:400, label: intl.formatMessage({ id: 'Anio_Pasado', defaultMessage: 'Año Pasado' })},
		{id:0, label: intl.formatMessage({ id: 'Todo', defaultMessage: 'Todo' })},
		{id:12, label:""}
	];
	const [openTurno, setOpenTurno] = useState(false);
	const [openFechaPrometida, setOpenFechaPrometida] = useState(false);

	useEffect(() => {
		if (props.filterResetInputs) {
			props.setFilterResetInputs(false);
			let filterStateInit = JSON.parse(JSON.stringify(FILTER_STATE_INIT));
			filterStateInit.ticketTipo = ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? { value: 'DEFAULT', label: intl.formatMessage({ id: 'ticketsGridFilters.select_por_defecto', defaultMessage: 'POR DEFECTO' }) } : null
			filterStateInit.estado = { value: 'PENDIENTES', label: intl.formatMessage({ id: 'ticketsGridFilters.select_pendientes', defaultMessage: 'PENDIENTES' }) }
			setFiltersState(filterStateInit);
			window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: persisteFiltros, filtros: filterStateInit }));
		}
	}, [props.filterResetInputs])

	useEffect(() => {
		setFiltersLoading(true)
		//set selectOption values
		Promise.all([
			Utils.getData('/bases/select-filtered-by-user/base/'),
			Utils.getData('/modelo-tipos/select'),
			Utils.getData('/gerenciadores/select-filtered-by-user'),
			Utils.getData('/tickets/estados'),
			Utils.getData('/servicios/select'),
			Utils.getData('/regiones/select'),
			Utils.getData('/subregiones/select-all'),
			Utils.getData('/centros-costos/select'),
			Utils.getData('/cebes/simple-search'),
			Utils.getData('/paises/select'),
			Utils.getData('/tickets/origenes/select'),
		]).then((data) => {
			let ticketEstados = localStorage.getItem('ticketsEstadoStrings') ? JSON.parse(localStorage.getItem('ticketsEstadoStrings')) : []
			let estados = [{ value: 'PENDIENTES', label: intl.formatMessage({ id: 'tickets.estados.PENDIENTES', defaultMessage: 'PENDIENTES' }) }]
			if (data[3]) data[3].map(e => (estados.push({ value: e, label: ticketEstados[e] })))

			let tipos = [
				{ value: 'DEFAULT', label: intl.formatMessage({ id: 'ticketsGridFilters.select_por_defecto', defaultMessage: 'POR DEFECTO' }) },
				{ value: 'CORRECTIVO', label: intl.formatMessage({ id: 'ticketsGridFilters.select_correctivo', defaultMessage: 'CORRECTIVO' }) },
				{ value: 'PREVENTIVO', label: intl.formatMessage({ id: 'ticketsGridFilters.select_preventivo', defaultMessage: 'PREVENTIVO' }) },
				{ value: 'VENCIMIENTO', label: intl.formatMessage({ id: 'ticketsGridFilters.select_vencimiento', defaultMessage: 'VENCIMIENTO' }) }
			]
			if (ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true') {
				tipos.push({ value: 'GESTORIA', label: intl.formatMessage({ id: 'ticketsGridFilters.select_gestoria', defaultMessage: 'GESTORIA' }) })
			}

			let selectOptions = {
				bases: data[0] ? data[0] : [],
				tipoModelos: data[1] ? data[1] : [],
				gerenciadores: data[2] ? data[2] : [],
				estados: estados,
				servicios: data[4] ? data[4] : [],
				regiones: data[5] ? data[5] : [],
				subregiones: data[6] ? data[6] : [],
				cecos: data[7] ? data[7] : [],
				cebes: data[8] ? data[8] : [],
				paises: data[9] ? data[9] : [],
				origenes: data[10] ? data[10] : [],
				tipos: tipos,
				enTallerSelect: [
					{ value: 'true', label: intl.formatMessage({ id: 'ticketsGridFilters.select_en_taller', defaultMessage: 'EN TALLER' }) },
					{ value: 1, label: intl.formatMessage({ id: 'ticketsGridFilters.select_si_sin_verificacion', defaultMessage: 'SI - Sin Verificación' }) },
					{ value: 2, label: intl.formatMessage({ id: 'ticketsGridFilters.select_si_verificado_GPS', defaultMessage: 'SI - Verificado GPS' }) },
					{ value: 3, label: intl.formatMessage({ id: 'ticketsGridFilters.select_no_verificado_GPS', defaultMessage: 'No - Verificado GPS' }) },
					{ value: 0, label: intl.formatMessage({ id: 'ticketsGridFilters.select_no', defaultMessage: 'NO' }) }
				],
				abonoSelect: [
					{ value: true, label: intl.formatMessage({ id: 'ticketsGridFilters.abono_select_si', defaultMessage: 'SI' }) },
					{ value: false, label: intl.formatMessage({ id: 'ticketsGridFilters.abono_select_no', defaultMessage: 'NO' }) }
				],
				realizadoSelect: [
					{ value: true, label: intl.formatMessage({ id: 'ticketsGridFilters.realizado_select_si', defaultMessage: 'SI' }) },
					{ value: false, label: intl.formatMessage({ id: 'ticketsGridFilters.realizado_select_no', defaultMessage: 'NO' }) }
				],
				nivelesAprobacion: [{ value: 'N1', label: 'N1' }, { value: 'N2', label: 'N2' }, { value: 'N3', label: 'N3' }]
			}

			setSelectOptions(selectOptions)
			setFiltersLoading(false)
		})
	}, [])

	useEffect(() => {
		setFiltersLoading(true)
		if (init === false) {
			let query = JSON.parse(JSON.stringify(filtersState))
			query['estado'] = query['estado'] ? query['estado']['value'] : ''
			query['base'] = query['base'] ? query['base']['value'] : ''
			query['entidad'] = query['entidad'] ? query['entidad'] : ''
			query['ticketTipo'] = query['ticketTipo'] ? query['ticketTipo']['value'] : ''
			query['nivelAprobacionPresupuesto'] = query['nivelAprobacionPresupuesto'] ? query['nivelAprobacionPresupuesto']['value'] : ''
			query['servicio'] = query['servicio'] ? query['servicio']['value'] : ''
			query['gerenciador'] = query['gerenciador'] ? query['gerenciador']['value'] : ''
			query['region'] = query['region'] ? query['region']['value'] : ''
			query['subRegion'] = query['subRegion'] ? query['subRegion'].map(e => e.value).join(',') : ''
			query['pais'] = query['pais'] ? query['pais'].map(e => e.value).join(',') : ''
			query['centroCostos'] = query['centroCostos'] ? query['centroCostos']['value'] : ''
			query['centroBeneficios'] = query['centroBeneficios'] ? query['centroBeneficios']['value'] : ''
			query['enTaller'] = query['enTaller'] ? query['enTaller']['value'] : ''
			query['fechaRealizado'] = query['fechaRealizado'] ? query['fechaRealizado']['value'] : ''
			query['abono'] = query['abono'] ? query['abono']['value'] : ''
			query['tipoModelo'] = query['tipoModelo'] ? query['tipoModelo']['value'] : ''
			query['fechaHoraTurno'] = query['fechaHoraTurno'] ? query['fechaHoraTurno'] : ''
			query['fechaHoraAlta'] = query['fechaHoraAlta'] ? query['fechaHoraAlta'] : ''
			query['movilPersona'] = query['movilPersona'] ? query['movilPersona'] : ''
			query['origen'] = query['origen'] ? query['origen']['value'] : ''

			query['turnoInicio'] = query['turnoInicio'] ? query['turnoInicio'] : ''
			query['turnoFinal'] = query['turnoFinal'] ? query['turnoFinal'] : ''
			query['fechaPrometidaInicio'] = query['fechaPrometidaInicio'] ? query['fechaPrometidaInicio'] : ''
			query['fechaPrometidaFinal'] = query['fechaPrometidaFinal'] ? query['fechaPrometidaFinal'] : ''
			//filtros export
			setInit(true)
			window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: props.persisteFiltros, filtros: filtersState }));
			props.setFilterQuery(query);
			setFiltersLoading(false)

			if (props.persisteFiltros) {
				props.setSearchActive(true);
			}
			return;
		}

		if (persisteFiltros) {
			props.setDropdownActive(true)
		}
		else {
			let filterStateInit = JSON.parse(JSON.stringify(FILTER_STATE_INIT));
			filterStateInit.ticketTipo = ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? { value: 'DEFAULT', label: intl.formatMessage({ id: 'ticketsGridFilters.select_por_defecto', defaultMessage: 'POR DEFECTO' }) } : null
			filterStateInit.estado = { value: 'PENDIENTES', label: intl.formatMessage({ id: 'ticketsGridFilters.select_pendientes', defaultMessage: 'PENDIENTES' }) }
			setFiltersState(filterStateInit);
			props.setDropdownActive(false)
		}
		setFiltersLoading(false)

		window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: persisteFiltros, filtros: filtersState }))
	}, [persisteFiltros])

	useEffect(() => {
		if (props.limpiarFiltros) {
			props.filterResetInputs()
			setFiltersState(FILTER_STATE_INIT)
		}

		if (props.cardKpi) {
			let obj = null;
			let key = null;
			let filter = JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(FILTER_STATE_INIT))));
			if (props.cardKpi === 'EN_TALLER') {
				obj = selectOptions.enTallerSelect.find(option => option.value === 'true')
				filter['enTaller'] = obj;
				key = 'enTaller';

			} else {
				obj = selectOptions.tipos.find(option => option.value === props.cardKpi)
				filter['ticketTipo'] = obj;
				key = 'ticketTipo';

			}
			let query = JSON.parse(JSON.stringify(filter));
			setFiltersState(filter);
			query['estado'] = query['estado'] ? query['estado']['value'] : ''
			query['base'] = query['base'] ? query['base']['value'] : ''
			query['entidad'] = query['entidad'] ? query['entidad']['value'] : ''
			query['movilPersona'] = query['movilPersona'] ? query['movilPersona']['value'] : ''
			query['ticketTipo'] = query['ticketTipo'] ? query['ticketTipo']['value'] : ''
			query['nivelAprobacionPresupuesto'] = query['nivelAprobacionPresupuesto'] ? query['nivelAprobacionPresupuesto']['value'] : ''
			query['servicio'] = query['servicio'] ? query['servicio']['value'] : ''
			query['gerenciador'] = query['gerenciador'] ? query['gerenciador']['value'] : ''
			query['region'] = query['region'] ? query['region']['value'] : ''
			query['subRegion'] = query['subRegion'] ? query['subRegion']['value'] : ''
			query['pais'] = query['pais'] ? query['pais'].map(e => e.value).join(',') : ''
			query['centroCostos'] = query['centroCostos'] ? query['centroCostos']['value'] : ''
			query['centroBeneficios'] = query['centroBeneficios'] ? query['centroBeneficios']['value'] : ''
			query['enTaller'] = query['enTaller'] ? query['enTaller']['value'] : ''
			query['fechaRealizado'] = query['fechaRealizado'] ? query['fechaRealizado']['value'] : ''
			query['abono'] = query['abono'] ? query['abono']['value'] : ''
			query['tipoModelo'] = query['tipoModelo'] ? query['tipoModelo']['value'] : ''
			query['fechaHoraTurno'] = query['fechaHoraTurno'] ? query['fechaHoraTurno'] : ''
			query['fechaHoraAlta'] = query['fechaHoraAlta'] ? query['fechaHoraAlta'] : ''
			query['origen'] = query['origen'] ? query['origen'] : ''

			//props.setFilterQuery(query);
			setFiltersLoading(false)
			props.setSearchActive(true)
			window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: persisteFiltros, filtros: filter }));
			return;
		}
	}, [props.limpiarFiltros, props.cardKpi])

	const updateToDataTables = (filters) => {
		setFiltersLoading(true)
		if (dataTableColumns.length) {
			for (let filter in filters) {
				let data = '';

				if (filters[filter] !== null && filters[filter] !== '') {
					if (Array.isArray(filters[filter])) {
						data = filters[filter].map(e => e.value).join(',') // para inputs multiples
					}
					else if (filters[filter].value) {
						data = filters[filter].value
					}
					else {
						data = filters[filter]
					}

					props.dtData.column(dataTableColumns.indexOf(filter)).search(data).draw()
				}

			}
		}
		setFiltersLoading(false)
	}

	const handleChange = (key, object) => {
		let currentFilters = JSON.parse(window.localStorage.getItem('TicketsGridFilterNew'))['filtros'];
		if (JSON.stringify(currentFilters[key]) !== JSON.stringify(object)) {
			if (key === 'turno' || key === 'fechaPrometida') {
				let inicio = "";
				let fin = "";
				if (object) {
					if(object && object.id !== 12){
						switch (object.id) {
							case 0:
								inicio = '';
								fin = '';
								break;
							case 1:
								inicio = moment().format('YYYY-MM-DD');
								fin = moment().format('YYYY-MM-DD');
								break;
							case 2:
								inicio = moment().subtract(1, 'days').format('YYYY-MM-DD');
								fin = inicio;
								break;
							case 7:
								inicio = moment().subtract(7, 'days').format('YYYY-MM-DD');
								fin = moment().format('YYYY-MM-DD');
								break;
							case 14:
								inicio = moment().subtract(14, 'days').format('YYYY-MM-DD');
								fin = moment().format('YYYY-MM-DD');
								break;
							case 20:
								inicio = moment().subtract(30, 'days').format('YYYY-MM-DD');
								fin = moment().format('YYYY-MM-DD');
								break;
							case 30:
								inicio = moment().startOf('month').format('YYYY-MM-DD');
								fin = moment().format('YYYY-MM-DD');
								break;
							case 60:
								inicio = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
								fin = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
								break;
							case 400:
								inicio = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
								fin = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
								break;
							default:
								inicio = moment().startOf('year').format('YYYY-MM-DD');
								fin = moment().format('YYYY-MM-DD');
						}
	
					}else if(object.inicio){
						inicio = moment(object.inicio).format('YYYY-MM-DD');
						fin = moment(object.fin).format('YYYY-MM-DD');
					}
					if(key === 'turno'){
						filtersState['turnoInicio'] = inicio;
						filtersState['turnoFinal'] = fin;
	
					}
					if(key === 'fechaPrometida'){
						filtersState['fechaPrometidaInicio'] = inicio;
						filtersState['fechaPrometidaFinal'] = fin;
	
					}
				}else{
					if(key === 'turno'){
						filtersState['turnoInicio'] = '';
						filtersState['turnoFinal'] = '';
	
					}
					if(key === 'fechaPrometida'){
						filtersState['fechaPrometidaInicio'] = '';
						filtersState['fechaPrometidaFinal'] = '';
	
					}
				}
			}
			if (key === 'base') {
				if (!object) {
					searchBase('');
				}
			}
			if (key === 'centroBeneficios') {
				if (!object) {
					searchCebe('');
				}
			}

			let valor;
			valor = object ? object : "";
			switch (key) {
				case 'subRegion': 
					setSubRegion(valor);break;
			}

			filtersState[key] = object;
			let copyFiltersState = JSON.parse(JSON.stringify(filtersState));
			setFiltersState(copyFiltersState);
			window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({activo: persisteFiltros, filtros: copyFiltersState}));
			return;
		}
	}

	
	useEffect(() => {
		setValidatorSearch(validator());
	},[filtersState])

	const validator = () => {
		var result = Object.entries(filtersState);
		for (var index in result){
			if(Array.isArray(result[index][1])){
				if(result[index][1].length > 0 ){
					return false
				}
			}else{
				if(result[index][1]){
					return false
				}
			}
		}
		return true
	}
	
	const queryTable = () => {
		let query = {}
		query = JSON.parse(JSON.stringify(filtersState));
		query['estado'] = filtersState.estado ? filtersState.estado.value : ''
		query['ticketTipo'] = filtersState.ticketTipo ? filtersState.ticketTipo.value : ''
		query['id'] = filtersState.id ? filtersState.id : ''
		query['entidad'] = filtersState.entidad ? filtersState.entidad : ''
		query['estado'] = filtersState.estado ? filtersState.estado.value : ''
		query['nivelAprobacionPresupuesto'] = filtersState.nivelAprobacionPresupuesto ? filtersState.nivelAprobacionPresupuesto.value : ''
		query['fechaHoraAlta'] = filtersState.fechaHoraAlta ? filtersState.fechaHoraAlta : ''
		query['detalle'] = filtersState.detalle ? filtersState.detalle : ''
		query['titular'] = filtersState.titular ? filtersState.titular.value : ''
		query['servicio'] = filtersState.servicio ? filtersState.servicio.value : ''
		query['base'] = filtersState.base ? filtersState.base.value : ''

		query['gerenciador'] = filtersState.gerenciador ? filtersState.gerenciador.value : ''
		query['region'] = filtersState.region ? filtersState.region.value : ''
		query['subRegion'] = filtersState.subRegion ? filtersState.subRegion.map(e => e.value).join(',') : ''
		query['pais'] = filtersState.pais ? filtersState.pais.map(e => e.value).join(',') : ''
		query['fechaRealizado'] = filtersState.fechaRealizado ? filtersState.fechaRealizado.value : ''
		query['enTaller'] = filtersState.enTaller ? filtersState.enTaller.value : ''
		query['detalle'] = filtersState.detalle ? filtersState.detalle : ''
		query['abono'] = filtersState.abono ? filtersState.abono.value : ''
		query['centroCostos'] = filtersState.centroCostos ? filtersState.centroCostos.value : ''
		query['responsable1'] = filtersState.responsable1 ? filtersState.responsable1 : ''
		query['centroBeneficios'] = filtersState.centroBeneficios ? filtersState.centroBeneficios.value : ''
		query['fechaHoraTurno'] = filtersState.fechaHoraTurno ? filtersState.fechaHoraTurno : ''
		query['tipoModelo'] = filtersState.tipoModelo ? filtersState.tipoModelo.value : ''
		query['movilPersona'] = filtersState.movilPersona ? filtersState.movilPersona : ''
		query['origen'] = filtersState.origen ? filtersState.origen.value : ''
		query['encuestaNivelSatisfaccion'] = filtersState.encuestaNivelSatisfaccion ? filtersState.encuestaNivelSatisfaccion : ''
		query['mttTotal'] = filtersState.mttTotal ? filtersState.mttTotal : ''
		query['mttParcial'] = filtersState.mttParcial ? filtersState.mttParcial : ''
		query['turnoInicio'] = filtersState.turno ? filtersState.turnoInicio : ''
		query['turnoFinal'] = filtersState.turno ? filtersState.turnoFinal : ''
		query['fechaPrometidaInicio'] = filtersState.fechaPrometida ? filtersState.fechaPrometidaInicio : ''
		query['fechaPrometidaFinal'] = filtersState.fechaPrometida ? filtersState.fechaPrometidaFinal : ''
		return query;
	}

	const searchBase = (e) => {
		let value = '';
		if (e)
			value = e.target.value;
		Utils.getData("/bases/simple-search").then((res) => {
			let selectOptionsCopy = JSON.parse(JSON.stringify(selectOptions));
			selectOptionsCopy.bases = res.map((b) => ({ value: b.id, label: b.descripcion }));
			setSelectOptions(selectOptionsCopy);
		});
	};

	const searchCebe = (e) => {
		let value = '';
		if (e)
			value = e.target.value;
		Utils.getData("/cebes/simple-search").then((res) => {
			let selectOptionsCopy = JSON.parse(JSON.stringify(selectOptions));
			selectOptionsCopy.cebes = res;
			setSelectOptions(selectOptionsCopy);
		});
	};

	useEffect(() => {
		if (init) {
			if (props.persisteFiltros) {
				window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: true, filtros: filtersState }));
			}
			else {
				let filterStateInit = JSON.parse(JSON.stringify(FILTER_STATE_INIT));
				filterStateInit.ticketTipo = ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? { value: 'DEFAULT', label: intl.formatMessage({ id: 'ticketsGridFilters.select_por_defecto', defaultMessage: 'POR DEFECTO' }) } : null
				filterStateInit.estado = { value: 'PENDIENTES', label: intl.formatMessage({ id: 'ticketsGridFilters.select_pendientes', defaultMessage: 'PENDIENTES' }) }
				setFiltersState(filterStateInit);
				props.setFilterQuery(FILTER_QUERY_INIT);
				window.localStorage.setItem('TicketsGridFilterNew', JSON.stringify({ activo: persisteFiltros, filtros: filterStateInit }));
			}
		}
	}, [props.persisteFiltros])

	const handleSubmit = (event) => {
		event.preventDefault();
		let copyFiltersState = JSON.parse(JSON.stringify(filtersState));
		props.onFilterChange();
		props.setSearchActive(true)
		let query = queryTable();
		console.log(query)
		console.log(copyFiltersState)
		props.setFilterQuery(query);
		return;
	}
	
	const handleSubmitInto = (event) => {
		if(!props.loading && !validatorSearch){
			handleSubmit(event);
		}
		event.preventDefault();
	}

	return (
		<>
			<Collapse in={props.dropdownActive}>
				<Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }}>
					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
						<Autocomplete
							size="small"
							name="ticketTipo"
							disabled={filtersLoading}
							options={selectOptions.tipos}
							value={filtersState.ticketTipo}
							getOptionLabel={(option) => option ? option.label : ""}
							onChange={(e, value) => handleChange("ticketTipo", value)}
							renderInput={(params) => (
								<TextField {...params} variant="standard"
									label={intl.formatMessage({ id: "ticketsGridFilters.render.tipo_label", defaultMessage: "Tipo" })}
								/>
							)}
						/>
						</form>
					</Grid>

					<Grid component={Box} item xs={1}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.id_label", defaultMessage: "Tkt" })}
								name="id"
								value={filtersState.id}
								onChange={(e) => handleChange("id", e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>

					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="estado"
								disabled={filtersLoading}
								options={selectOptions.estados}
								value={filtersState.estado}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e,value) => handleChange("estado", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.estado_label", defaultMessage: "Estado" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid component={Box} item xs={2}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.creado_label", defaultMessage: "Creado" })}
								name="fechaHoraAlta"
								value={filtersState.fechaHoraAlta}
								onChange={(e) => handleChange("fechaHoraAlta", e.target.value)}
								disabled={filtersLoading}
								className="col-12"
								type="date"
								format={"DD-MM-YYYY"}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.nivelAprobacionPresupuesto && { display: "none" }} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="nivelesDeAprobacion"
								disabled={filtersLoading}
								options={selectOptions.nivelesAprobacion}
								value={filtersState.nivelAprobacionPresupuesto}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("nivelAprobacionPresupuesto", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.nivelesDeAprobacion_label", defaultMessage: "Nivel Aprobacion" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.detalle_label", defaultMessage: "Detalle" })}
								name="detalle"
								value={filtersState.detalle}
								onChange={(e) => handleChange("detalle", e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>

					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="servicio"
								disabled={filtersLoading}
								options={selectOptions.servicios}
								value={filtersState.servicio}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("servicio", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.servicio_label", defaultMessage: "Servicio" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.entidad_label", defaultMessage: "Entidad" })}
								name="entidad"
								value={filtersState.entidad}
								onChange={(e) => handleChange("entidad", e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>

					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="proveedor"
								disabled={filtersLoading}
								options={selectOptions.gerenciadores}
								value={filtersState.gerenciador}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("gerenciador", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.proveedor_label", defaultMessage: "Proveedor" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.region && { display: "none" }} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="region"
								disabled={filtersLoading}
								options={selectOptions.regiones}
								value={filtersState.region}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("region", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.region_label", defaultMessage: "Region" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid  component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="subRegion"
								multiple={true}
								disabled={filtersLoading}
								options={selectOptions.subregiones}
								value={subRegion ? subRegion : []}
								defaultValue={[]}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("subRegion", value)}
								renderInput={(params) => (
								<TextField {...params} variant="standard" 
									label={intl.formatMessage({id: "combustiblesGrid.render.filtros_subregion.label", defaultMessage: "Sub-Región"})} 
									placeholder={intl.formatMessage({id: "combustiblesGrid.render.filtros_subregion.placeholder", defaultMessage: "Sub-Región"})}
								/>
								)}
							/>
						</form>
					</Grid>

					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="base"
								disabled={filtersLoading}
								options={selectOptions.bases}
								value={filtersState.base}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("base", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.base_label", defaultMessage: "Base" })}
										onChange={searchBase}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.pais && { display: "none" }} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="pais"
								multiple={true}
								disabled={filtersLoading}
								options={selectOptions.paises}
								value={filtersState.pais}
								defaultValue={[]}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("pais", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.pais_label", defaultMessage: "Pais" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.centroCostos && { display: "none" }} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="centroCostos"
								disabled={filtersLoading}
								options={selectOptions.cecos}
								value={filtersState.centroCostos}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("centroCostos", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.ceco_label", defaultMessage: "Centro Costos" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.centroBeneficios && { display: "none" }} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="centroBeneficios"
								disabled={filtersLoading}
								options={selectOptions.cebes}
								value={filtersState.centroBeneficios}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("centroBeneficios", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.cebe_label", defaultMessage: "Centro Beneficios" })}
										onChange={searchCebe}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.responsable1 && { display: "none" }} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.responsable_label", defaultMessage: "Responsable" })}
								name="responsable1"
								value={filtersState.responsable1}
								onChange={(e) => handleChange("responsable1", e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>

					<Grid component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="taller"
								disabled={filtersLoading}
								options={selectOptions.enTallerSelect}
								value={filtersState.enTaller}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("enTaller", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.taller_label", defaultMessage: "Taller" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.fechaHoraTurno && { display: "none" }} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="turno"
								disabled={filtersLoading}
								options={periodos}
								value={filtersState.turno ? filtersState.turno : null}
								defaultValue={null}
								getOptionLabel={(option) => (option ? option.label : "")}
								open={openTurno}
								onOpen={() => setOpenTurno(true)}
								onClose={() => setOpenTurno(false)} 
								onChange={(e, value) => {
									if (value===null || (value && value.label !== '')){
										handleChange("turno", value)
										setOpenTurno(false)
									}
								}}
								disableCloseOnSelect
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({ id: "Fecha_de_turno", defaultMessage: "Fecha de turno" })}
										onBlur={() => setOpenTurno(false)}
									/>
								)}
								renderOption={(option, { selected }) => (
									<GravatarOption
										option={option}
										isSelected={selected}
										onSelect={(option, event) => {
											handleChange("turno", option);
											setOpenTurno(false); 
										}}
										onFocus={() => {}}
										className={selected ? 'selected' : ''}
										>
										{option.label}
									</GravatarOption>
								)}
							/>		
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.fechaPrometida && { display: "none" }} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="fechaPrometida"
								disabled={filtersLoading}
								options={periodos}
								value={filtersState.fechaPrometida ? filtersState.fechaPrometida : null}
								defaultValue={null}
								getOptionLabel={(option) => (option ? option.label : "")}
								open={openFechaPrometida}
								onOpen={() => setOpenFechaPrometida(true)}
								onClose={() => setOpenFechaPrometida(false)} 
								onChange={(e, value) => {
									if (value===null || (value && value.label !== '')){
										handleChange("fechaPrometida", value);
										setOpenFechaPrometida(false);
									}
								}}
								disableCloseOnSelect
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({ id: "Fecha_Prometida", defaultMessage: "Fecha Prometida" })}
										onBlur={() => setOpenFechaPrometida(false)}
									/>
								)}
								renderOption={(option, { selected }) => (
									<GravatarOption
										option={option}
										isSelected={selected}
										onSelect={(option, event) => {
											handleChange("fechaPrometida", option);
											setOpenFechaPrometida(false);
										}}
										onFocus={() => {}}
										className={selected ? 'selected' : ''}
										>
										{option.label}
									</GravatarOption>
								)}
							/>		
						</form>
					</Grid>

					<Grid component={Box} item xs={2}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.tt_label", defaultMessage: "TT" })}
								name="mttTotal"
								value={filtersState.mttTotal}
								onChange={(e) => handleChange("mttTotal", e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>

					<Grid component={Box} item xs={2}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.tp_label", defaultMessage: "TP" })}
								name="mttParcial"
								value={filtersState.mttParcial}
								onChange={(e) => handleChange("mttParcial", e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.encuestaSatisfaccion && { display: "none" }} component={Box} item xs={2}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<TextField
								label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.encuestaSatisfaccion_label", defaultMessage: "Satisfaccion" })}
								name="encuestaNivelSatisfaccion"
								value={filtersState.encuestaNivelSatisfaccion}
								onChange={(e) => handleChange("encuestaNivelSatisfaccion", e.target.value)}
								disabled={filtersLoading}
								className="col-12"
							/>
						</form>
					</Grid>

					<Grid component={Box} item xs={2}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="fechaRealizado"
								disabled={filtersLoading}
								options={selectOptions.realizadoSelect}
								value={filtersState.fechaRealizado}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("fechaRealizado", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.realizado_label", defaultMessage: "Realizado" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.abono && { display: "none" }} component={Box} item xs={2}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="abono"
								disabled={filtersLoading}
								options={selectOptions.abonoSelect}
								value={filtersState.abono}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("abono", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.abono_label", defaultMessage: "Abono" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid {...!props.columnsEnabled.tipoModelo && { display: "none" }} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="tipoModelo"
								disabled={filtersLoading}
								options={selectOptions.tipoModelos}
								value={filtersState.tipoModelo}
								defaultValue={null}
								getOptionLabel={(option) => option ? option.label : ""}
								onChange={(e, value) => handleChange("tipoModelo", value)}
								renderInput={(params) => (
									<TextField {...params} variant="standard"
										label={intl.formatMessage({ id: "ticketsGridFilters.render.tipoModelo_label", defaultMessage: "Tipo Modelo" })}
									/>
								)}
							/>
						</form>
					</Grid>

					<Grid style={!props.columnsEnabled.origen_checklist ? { display: "none" } : {}} component={Box} item xs={3}>
						<form noValidate autoComplete="off" onSubmit={handleSubmitInto}>
							<Autocomplete
								size="small"
								name="origen"
								disabled={filtersLoading}
								options={selectOptions.origenes}
								value={filtersState.origen} // Make sure filtersState.origen is defined and set initially
								getOptionLabel={(option) => (option ? option.label : "")}
								onChange={(e, value) => handleChange("origen", value)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({
											id: "ticketsGridFilters.render.origen",
											defaultMessage: "Origen ticket",
										})}
									/>
								)}
							/>
						</form>
					</Grid>
					
					<Grid
						xs={12}
						container
						justifyContent="space-between"
						alignItems="center"
						flexDirection={{ xs: 'column', sm: 'row' }}
						sx={{ fontSize: '12px' }}
					>
						<Grid component={Box} item xs={2} className="align-self-end">
							<div className="d-flex inline justify-content-around align-items-center">
								<FormattedMessage
									id="ticketsGridFilters.render.filtros.persistir_filtros"
									defaultMessage="Persistir Filtros"
								/>
								<Switch
									onChange={(e) => setPersisteFiltros(e.target.checked)}
									checked={persisteFiltros}
									disabled={filtersLoading}
									offColor="#FF4961"
									onColor="#28D094"
								/>
							</div>
						</Grid>
						<Grid component={Box} item xs={1} className="align-self-end"
						>
							<button
								disabled={props.loading || validatorSearch}
								className="btn btn-primary mr-1"
								onClick={(e) => handleSubmit(e)}
							>
								<i className="fa fa-search fa-xs"></i>   <FormattedMessage id="Buscar" defaultMessage="Buscar"/>
							</button>
						</Grid>
					</Grid>


				</Grid>
			</Collapse>
		</>
	)
}


export default TicketGridFilters
