import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import Loading from "../ui/Loading.js";
import $ from "jquery";
import "moment/min/locales";
import "xlsx/dist/xlsx.full.min.js";
import { FormattedMessage, injectIntl } from "react-intl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Backdrop from "@material-ui/core/Backdrop";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Utils from '../../commons/utils/Utils';
import swal from 'sweetalert'
import Fade from "@material-ui/core/Fade";
import {Grid} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import PresupuestoDetalladoEditModal from "./PresupuestoDetalladoEditModal";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

const requiredSymbol = ' *';

function Row(props) {
	const intl = props.intl;
	const [presupuestoItem, setPresupuestoItem] = useState(props.presupuestoItem);
	const [showModalEdit, setShowModalEdit] = useState(false);
	
	const useStylesCambioLlanta = makeStyles((theme) => ({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "15px",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			borderRadius: "15px",
		},
	}));

	const classesCambio = useStylesCambioLlanta();

	const total = () => {
		let total = null;
		if(presupuestoItem.cantidad !== null){
			if(presupuestoItem.costo !== null){
				total = presupuestoItem.cantidad * presupuestoItem.costo;
			}
		}
		return total;
	};

	const updateElement = (data) => {
		props.updateServicioItem(data, props.index);
		presupuestoItem.cantidad = data.cantidad;
		presupuestoItem.costo = data.costo;
		closeModalEdit();
	}

	const closeModalEdit = (event) => {
		setShowModalEdit(false);
	};

	const edit = (event) => {
		event.preventDefault();
		setShowModalEdit(true)
	}

	const renderTableTbody = (field, index) => {
		switch (field) {
			case 'index':
				return (
					<td key={index} className="all custom-lineHeight">
						{props.index + 1}
					</td>
				);
			case 'id':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.id_item}
					</td>
				);
			case 'external_code':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.external_code}
					</td>
				);
			case 'descripcion':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.descripcion}
					</td>
				);
			case 'cuenta_mayor':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.cuenta_mayor}
					</td>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.cuenta_mayor_descripcion}
					</td>
				);
			case 'cantidad':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.cantidad}
					</td>
				);
			case 'costo':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.costo}
					</td>
				);
			case 'marcaNombre':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoItem.marcaNombre}
					</td>
				);
			case 'total':
				return (
					<td key={index} className="all custom-lineHeight">
						{total()}
					</td>
				);
			case 'servicio':
				return (
					<td key={index} className="all custom-lineHeight">
						{
							presupuestoItem.servicio ? 
								presupuestoItem.servicio.nombre :
								(
									presupuestoItem.servicio_nombre ? presupuestoItem.servicio_nombre : ''
								)
						}
					</td>
				);
			default:
				return null;
		}
	}

	return (
		<React.Fragment>
			<tr key={presupuestoItem.id}>
				<Modal
					open={showModalEdit}
					onClose={closeModalEdit}
					className={classesCambio.modal}
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={showModalEdit}>
						<Box sx={style}>
							<PresupuestoDetalladoEditModal
								type="presupuestoItem"
								intl={props.intl}
								element={presupuestoItem}
								updateElement={updateElement}
								closeModalEdit={closeModalEdit}
								enabledCosto={props.showItems.includes('costo')}
								enabledCantidad={props.showItems.includes('cantidad')}
							/>
						</Box>
					</Fade> 
				</Modal>
				{props.showItems.map((field, index) => renderTableTbody(field, index))}
				<td className="all custom-lineHeight">
					<button
						className="btn btn-sm btn-icon btn-dt-grid text-warning"
						title={intl.formatMessage({ id: 'Editar', defaultMessage: 'Editar' })}
						data-togle="tooltip"
						data-placement="top"
						onClick={(event) => edit(event)}
					>
						<i className="fa fa-pencil fa-xs"/>
					</button>
					<button
						className="btn btn-sm btn-icon btn-dt-grid text-danger"
						title={intl.formatMessage({ id: 'Eliminar', defaultMessage: 'Eliminar' })}
						data-togle="tooltip"
						data-placement="top"
						onClick={() => props.deleteItem(props.index)}
					>
						<i className="fa fa-trash fa-xs"></i>
					</button>
				</td>
			</tr>


		</React.Fragment>
	);
}

const NEW_ITEM_INIT = {
	external_code: '',
	descripcion: '',
	cuenta_mayor: '',
	cuenta_mayor_descripcion: '',
	cantidad: '',
	costo: '',
	id_item: null,
	servicio_id: null,
	servicio: '',
	marcaNombre: '',
	id: null,
};

const PresupuestoDetalladoItemGrid = (props) => {
	const [loading, setLoading] = useState(false);
	const [presupuestoItems, setPresupuestoItems] = useState([]);
	useEffect(() => {
		setPresupuestoItems(props.presupuestoItems);
	}, [props.presupuestoItems]);


	const setLoadingAndUpdate = (state) => {
		setLoading(state);
	};

	const renderTableThead = (field, index) => {
		switch (field) {
			case 'index':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Items" defaultMessage="Items" />
					</th>
				);
			case 'id':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Id" defaultMessage="Id" />
					</th>
				);
			case 'external_code':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Repuesto" defaultMessage="Repuesto" />
					</th>
				);
			case 'descripcion':
				return (
					<th key={index} scope="col" style={{ maxWidth: "3px" }}>
						<FormattedMessage id="Presupuesto.Descripción" defaultMessage="Descripción" />
					</th>
				);
			case 'cuenta_mayor':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cuenta_Mayor" defaultMessage="Cuenta Mayor" />
					</th>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Descripción_Cuenta_Mayor" defaultMessage="Descripción Cuenta Mayor" />
					</th>
				);
			case 'cantidad':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cantidad" defaultMessage="Cantidad" />
					</th>
				);
			case 'costo':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Precio" defaultMessage="Precio" />
					</th>
				);
			case 'marcaNombre':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Marca" defaultMessage="Marca" />
					</th>
				);
			case 'total':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Total" defaultMessage="Total" />
					</th>
				);
			case 'servicio':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Servicio" defaultMessage="Servicio" />
					</th>
				);
			default:
				return null;
		}
	}

	return (
		<React.Fragment>
			<table
					id="dataTable"
					className="dataTable table nowrap server-side table-hover dataTableMovil"
					width="100%"
			>
				<thead>
					<tr>
						{props.showItems.map((field, index) => renderTableThead(field, index))}
						<th scope="col">
						</th>
					</tr>
				</thead>
				<tbody>
					{props.presupuestoItems ? (
						props.presupuestoItems.map((presupuestoItem, index) => (
							<Row
								index={index}
								intl={props.intl}
								presupuestoItem={presupuestoItem}
								loading={(state) => setLoadingAndUpdate(state)}
								key={(presupuestoItem.external_code ? presupuestoItem.external_code: '') + presupuestoItem.descripcion + index}
								deleteItem={props.deleteItem}
								updateServicioItem={props.updateServicioItem}
								showItems={props.showItems}
							/>
						))
					) : (
						<tr>
							<td align={"center"} colSpan={14}>
								{loading ? (
									<CircularProgress />
								) : (
									<FormattedMessage
										id="Presupuesto.No_hay_datos_disponibles"
										defaultMessage="No hay datos disponibles."
									/>
								)}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</React.Fragment>
	);
};

export default injectIntl(PresupuestoDetalladoItemGrid);
