import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'; 
import { FormattedMessage } from 'react-intl'; 
import RepuestosGrid from './RepuestosGrid.jsx';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';

const Repuestos = ({ match }) => { 

  const marcaObligatorio = (ConfigBusiness.get('inventario.marcas.obligatorio') === 'true') &&
                    (ConfigBusiness.get('tickets.presupuesto.tipo') === 'detallado') ? true : false; 

  return (
    <div className="content-wrapper">
      <div className="content-header row">
        <div className="content-header-left col-md-6 col-12 mb-2">
          <h3 className="content-header-title">
            <FormattedMessage
              id="repuestos.header_administracion_de_repuestos"
              defaultMessage="Administración de Repuestos"
            />
          </h3>
        </div>
      </div>
      <div className="content-body">
        <Switch>
          <Route exact path={`${match.url}`} render={(...props) => <RepuestosGrid {...props} marcaObligatorio={marcaObligatorio}/>} />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(Repuestos);
