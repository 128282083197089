import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage} from "react-intl";
import TextField from '@material-ui/core/TextField';

const PresupuestoDetalladoEditModal = (props) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({cantidad: 0, costo: 0});

	useEffect(() => {
		init();
	}, []);

	const init = () => {
		let copy = JSON.parse(JSON.stringify(data));
        copy.cantidad = props.element.cantidad;
		switch (props.type) {
			case "presupuestoTarea":
				copy.costo = props.element.precio;
				break;
			case "presupuestoItem":
				copy.costo = props.element.costo;
				break;
			default:
				console.log('default');
		}
		setData(copy);
	};

	const handleMaterialChange = (name, value) => {
		let copy = JSON.parse(JSON.stringify(data));
		if ((value && value >= 0) || !value) {
			copy[name] = value;
		}
		setData(copy);
	}

	return (
		<>
			<div>
				<div className="text-center mt-2">
					<h3>
						<FormattedMessage id="Presupuesto.Repuesto" defaultMessage="Presupuesto.Repuesto" /> : 
						{props.element.descripcion ? (props.element.descripcion) : (props.element.nombre ? props.element.nombre : '')}
					</h3>
				</div>
				<div className="table-responsive" style={{padding: "15px"}}>
					<table
						id="dataTable"
						className="dataTable table nowrap server-side table-hover dataTableMovil"
						width="100%"
					>
						<thead>
							<tr>
								{
									props.enabledCantidad ? 
										<th scope="col">
											<FormattedMessage id="Presupuesto.Cantidad" defaultMessage="Cantidad" />
										</th>
									: ''
								}
                                {
									props.enabledCosto ? 
                                        <th scope="col">
                                            <FormattedMessage id="Presupuesto.Precio" defaultMessage="Precio" />
                                        </th>
									: ''
								}
							</tr>
						</thead>
						<tbody>
							<tr key={props.element.id}>
								{
									props.enabledCantidad ?
										<td className="all custom-lineHeight">
											<TextField
												id="number"
												type="number"
												onChange={e => handleMaterialChange('cantidad', e.target.value)}
												InputProps={{
													inputProps: { 
														min: 0 
													}
												}}
												variant="standard"
												value={data.cantidad}
											/>
										</td>
									: null
								}
                                {
									props.enabledCosto ?
										<td className="all custom-lineHeight">
											<TextField
                                                id="standard"
                                                type="number"
                                                onChange={e => handleMaterialChange('costo', e.target.value)}
                                                InputProps={{
                                                    inputProps: { 
                                                        min: 0 
                                                    }
                                                }}
                                                variant="standard"
                                                value={data.costo}
                                                fullWidth
                                            />
										</td>
									: null
								}
							</tr>
						</tbody>
					</table>
				</div>
				<div className="text-center d-flex justify-content-center">
					<button type="button" className="btn btn-primary mr-1" onClick={(e) => props.updateElement(data)}>
						<i className="fa fa-check-circle"></i><FormattedMessage id="Presupuesto.guardar" defaultMessage=" Guardar"/>
					</button>
					<button type="button" className="btn btn-danger" onClick={(e) => props.closeModalEdit()}>
						<i className="fa fa-times-circle"></i> { props.intl.formatMessage({ id: 'Presupuesto.Cancelar', defaultMessage: 'Cancelar' })}
					</button>
				</div>
			</div>
		</>
	);
};

export default PresupuestoDetalladoEditModal;
