import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Sort from '../../assets/icons/table/sort.svg';
import SortAsc from '../../assets/icons/table/sort-asc.svg';
import SortDesc from '../../assets/icons/table/sort-desc.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import Loading from '../ui/Loading.js';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination
} from '@material-ui/core';
import { StyledTableCell, StyledTooltip, rowsPerPage } from '../../commons/utils/TableStyles.js';
import * as Utils from '../../commons/utils/Utils.js';
import $ from 'jquery';
import { FormattedMessage, useIntl } from 'react-intl';
import RepuestosGridRow from './RepuestosGridRow.jsx';
import RepuestosGridFiltros from './RepuestosGridFiltros.jsx';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import swal from 'sweetalert2';
import RepuestoEditModal from './RepuestoEditModal.jsx';


const PAGINATION_INIT = {
  page: 0,
  count: 0,
  perPage: 25,
};

const FILTER_QUERY_INIT = {
  id: '',
  descripcion: '',
  categoria: '',
  subcategoria: '',
  unidad: '',
  tipo: '',
  numero_parte: '',
  referencia: '',
  marca: '',
  costo: '',
  external_code: '',
};

const SORT_QUERY_INIT = {
  id: '',
  descripcion: 'asc',
  categoria: '',
  subcategoria: '',
  unidad: '',
  tipo: '',
  numero_parte: '',
  referencia: '',
  marca: '',
  costo: '',
  external_code: '',
};

const COLUMNS_ENABLED_INIT = {
  external_code: true,
  descripcion: false,
  categoria: false,
  subcategoria: false,
  unidad: false,
  tipo: false,
  numero_parte: false,
  referencia: false,
  marca: false,
  costo: false,
};

const RepuestosGrid = (props) => {
  const intl = useIntl();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ ...PAGINATION_INIT });
  const [paginationCount, setPaginationCount] = useState(0);
  const [sortQuery, setSortQuery] = useState({ ...SORT_QUERY_INIT });
  const [searchActive, setSearchActive] = useState(false);
  const [filterQuery, setFilterQuery] = useState({ ...FILTER_QUERY_INIT });
  const [columnsEnabled, setColumnsEnabled] = useState({ ...COLUMNS_ENABLED_INIT });

  const [dropdownActive, setDropdownActive] = useState(false);
  const [resetFilterInputs, setResetFilterInputs] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [subcategorias, setSubcategorias] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [action, setAction] = useState('add');


  useEffect(() => {
    const unidadMedidaReq = Utils.getData('/panol2/select/unidadMedida'),
    tiposReq = Utils.getData('/panol2/select/tipos'),
    categoriasReq = Utils.getData('/panol2/select/categorias'),
    marcasReq = Utils.getData('/panol2/select/marcas');

    Promise.all([
      unidadMedidaReq,
      tiposReq,
      categoriasReq,
      marcasReq
    ])
      .then(([unidadMedida, tipos, categorias, marcas]) => {
        setUnidades(unidadMedida);
        setTipos(tipos);
        setCategorias(categorias);
        setMarcas(marcas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    configColumns();
    filterQuery && fetchItems();
  }, [pagination, filterQuery, sortQuery]);

  const fetchItems = () => {
    Utils.getData(`/panol2/repuestos/grid${getQuery()}`)
      .then((data) => {
        setItems(data.items);
        setLoading(false);
        setPaginationCount(data.pagination.count);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const selectsData = {
    categorias,
    subcategorias,
    unidades,
    tipos,
    marcas,
  };

  const getQuery = () => {
    let query = `?page=${pagination.page}&perPage=${pagination.perPage}`;

    Object.keys(filterQuery).forEach((key) => {
      if (filterQuery[key]) {
        query += `&${key}=${encodeURIComponent(filterQuery[key])}`;
      }
    });

    Object.keys(sortQuery).forEach((key) => {
      if (sortQuery[key]) {
        query += `&${key}Sort=${encodeURIComponent(sortQuery[key])}`;
      }
    });

    return query;
  };

  const handleSort = (key) => {
    let query = { ...sortQuery };
    let orden = sortQuery[key] == '' || sortQuery[key] === 'desc' ? 'asc' : 'desc';

    query['id'] = '';
    query['descripcion'] = '';
    query['categoria'] = '';
    query['subcategoria'] = '';
    query['unidad'] = '';
    query['tipo'] = '';
    query['numero_parte'] = '';
    query['referencia'] = '';
    query['marca'] = '';
    query['costo'] = '';
    query['external_code'] = '';

    query[key] = orden;
    setSortQuery(query);
  };

  const configColumns = () => {
    let aux = Object.keys(COLUMNS_ENABLED_INIT).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});

    const columnsToShow = ConfigBusiness.get('panol.items.show') || '';

    const columnsArray = columnsToShow.split(',').map((col) => col.trim());

    columnsArray.forEach((column) => {
      if (aux.hasOwnProperty(column)) {
        aux[column] = true;
      }
    });

    setColumnsEnabled(aux);
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
  };

  const handleFilterReset = () => {
    setPagination(PAGINATION_INIT);
    setFilterQuery(FILTER_QUERY_INIT);
    setSortQuery(SORT_QUERY_INIT);
    setDropdownActive(false);
    setSearchActive(false);
    setResetFilterInputs(true);
  };

  const handleEditItem = (item, action) => {

    setSelectedItem(item);
    setAction(action);
    $('#editItemsModal').modal('show');
  };

  const closeModal = () => {
    setSelectedItem(null);
    $('#editItemsModal').modal('toggle');
  };

  const onModalEditSubmit = (newItem) => {
    Utils.sendData(`/panol2/repuestos/${newItem.id}`, 'PUT', { body: newItem })
      .then((data) => {
        if (data.id) {
          swal(intl.formatMessage({ id: 'actualizado_ok', defaultMessage: 'Se actualizó con Éxito!' }), " ", "success");
          fetchItems();
        }
      });
    closeModal();
  };


  const handleDelete = (itemId) => {
    swal.fire({
      title: intl.formatMessage({ id: 'delete_confirm_pregunta', defaultMessage: '¿Esta seguro que desea Eliminar?' }),
      text: intl.formatMessage({ id: 'delete_confirm_disclamer', defaultMessage: 'Esta acción no se puede deshacer' }),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({ id: 'delete_confirm_button.cancelar', defaultMessage: 'Cancelar' }),
      cancelButtonColor: '#FF394F',
      confirmButtonColor: '#079391',
      confirmButtonText: intl.formatMessage({ id: 'delete_confirm_button.si_eliminar', defaultMessage: 'Sí, eliminar' }),
    }).then((result) => {
      if (result.value) {
        Utils.deleteData(`/panol2/repuestos/${itemId}`)
          .then(() => {
            swal.fire(intl.formatMessage({ id: 'delete_confirm_success', defaultMessage: 'Eliminado!' }), '', 'success');
            fetchItems();
          })
          .catch((error) => {
            swal.fire('Error', 'Hubo un problema al eliminar el ítem.', 'error');
          });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire(intl.formatMessage({ id: 'delete_confirm_cancel', defaultMessage: 'Cancelado' }), '');
      }
    });
  };

  const onSelectCategoria = (id) => {
    if (id === 0) {
      setSubcategorias([]);
      return;
    }
    if (typeof id === 'object') return;
    if (id) {
      Utils.getData('/panol2/select/subcategorias?categoria=' + id)
      .then(subCategorias => setSubcategorias(subCategorias))
    }
  }
  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      {loading && <Loading />}

      <div className="card">
        <div className="card-content">
          <div className="card-body card-dashboard">
            <div className="container-fluid mb-1">
              <div className="row dt-icons">
                <StyledTooltip
                  title={intl.formatMessage({
                    id: 'repuestosGrid.button.label_add',
                    defaultMessage: 'Agregar',
                  })}
                  arrow
                  placement="right"
                >
                  <div
                    className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                    style={{ height: 55 }}
                    onClick={() => handleEditItem({ ...FILTER_QUERY_INIT }, 'add')}
                  >
                    <i className="ft-plus"></i>
                  </div>
                </StyledTooltip>
                <StyledTooltip
                  title={intl.formatMessage({
                    id: 'repuestosGrid.button.label_filtros',
                    defaultMessage: 'Filtros',
                  })}
                  arrow
                  placement="right"
                >
                  <div
                    className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                    style={{ height: 52 }}
                    onClick={() => setDropdownActive(!dropdownActive)}
                  >
                    {dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
                  </div>
                </StyledTooltip>
                {searchActive && (
                  <div className="btn-dt-main round-icon">
                    <div
                      className="text-center"
                      style={{
                        height: '25px',
                        width: '300px',
                        borderRadius: '3px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: '8px',
                        backgroundColor: '#5AAC43',
                        color: 'white',
                      }}
                    >
                      <div
                        className="text-center"
                        style={{
                          width: '100%',
                          fontSize: '12px',
                          fontWeight: '500',
                        }}
                      >
                        {items.length} de {paginationCount} resultados de busqueda
                      </div>
                      <div className="resultados-busqueda-notif">
                        <i
                          className="las la-times la-xs cursor-pointer"
                          onClick={handleFilterReset}
                        ></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <RepuestosGridFiltros
                selectsData={selectsData}
                dropdownActive={dropdownActive}
                filterQuery={filterQuery}
                setFilterQuery={setFilterQuery}
                setSearchActive={setSearchActive}
                loading={loading}
                columnsEnabled={columnsEnabled}
                resetFilterInputs={resetFilterInputs}
                setResetFilterInputs={setResetFilterInputs}
                marcaObligatorio={props.marcaObligatorio}
              />

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columnsEnabled.external_code && (
                        <StyledTableCell
                          align="left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('external_code')}
                        >
                          <FormattedMessage
                            id="table_column_externalCode"
                            defaultMessage="Código Externo"
                          />
                          <img
                            className="ml-1"
                            src={
                              sortQuery.modelo === ''
                                ? Sort
                                : sortQuery.external_code === 'asc'
                                  ? SortAsc
                                  : SortDesc
                            }
                            alt={'/'}
                            style={{ height: '12px', width: '22px' }}
                          />
                        </StyledTableCell>
                      )}

                      {columnsEnabled.descripcion && (
                        <StyledTableCell
                          align="left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('descripcion')}
                        >
                          <FormattedMessage
                            id="table_column_descripcion"
                            defaultMessage="Descripción"
                          />
                          <img
                            className="ml-1"
                            src={
                              sortQuery.modelo === ''
                                ? Sort
                                : sortQuery.descripcion === 'asc'
                                  ? SortAsc
                                  : SortDesc
                            }
                            alt={'/'}
                            style={{ height: '12px', width: '22px' }}
                          />
                        </StyledTableCell>
                      )}

                      {columnsEnabled.categoria && (
                        <StyledTableCell
                          align="left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('categoria')}
                        >
                          <FormattedMessage
                            id="table_column_categoria"
                            defaultMessage="Categoría"
                          />
                          <img
                            className="ml-1"
                            src={
                              sortQuery.modelo === ''
                                ? Sort
                                : sortQuery.categoria === 'asc'
                                  ? SortAsc
                                  : SortDesc
                            }
                            alt={'/'}
                            style={{ height: '12px', width: '22px' }}
                          />
                        </StyledTableCell>
                      )}

                      {columnsEnabled.subcategoria && (
                        <StyledTableCell
                          align="left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('subcategoria')}
                        >
                          <FormattedMessage
                            id="table_column_subcategoria"
                            defaultMessage="Subcategoría"
                          />
                          <img
                            className="ml-1"
                            src={
                              sortQuery.modelo === ''
                                ? Sort
                                : sortQuery.subcategoria === 'asc'
                                  ? SortAsc
                                  : SortDesc
                            }
                            alt={'/'}
                            style={{ height: '12px', width: '22px' }}
                          />
                        </StyledTableCell>
                      )}

                      {columnsEnabled.unidad && (
                        <StyledTableCell
                          align="left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('unidad')}
                        >
                          <FormattedMessage id="table_column_unidad" defaultMessage="Unidad" />
                          <img
                            className="ml-1"
                            src={
                              sortQuery.modelo === ''
                                ? Sort
                                : sortQuery.unidad === 'asc'
                                  ? SortAsc
                                  : SortDesc
                            }
                            alt={'/'}
                            style={{ height: '12px', width: '22px' }}
                          />
                        </StyledTableCell>
                      )}

                      {columnsEnabled.tipo && (
                        <StyledTableCell
                          align="left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('tipo')}
                        >
                          <FormattedMessage id="table_column_tipo" defaultMessage="Tipo" />
                          <img
                            className="ml-1"
                            src={
                              sortQuery.modelo === ''
                                ? Sort
                                : sortQuery.tipo === 'asc'
                                  ? SortAsc
                                  : SortDesc
                            }
                            alt={'/'}
                            style={{ height: '12px', width: '22px' }}
                          />
                        </StyledTableCell>
                      )}

                      {columnsEnabled.numero_parte && (
                        <StyledTableCell
                          align="left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('numero_parte')}
                        >
                          <FormattedMessage
                            id="table_column_numeroParte"
                            defaultMessage="Número de Parte"
                          />
                          <img
                            className="ml-1"
                            src={
                              sortQuery.modelo === ''
                                ? Sort
                                : sortQuery.numero_parte === 'asc'
                                  ? SortAsc
                                  : SortDesc
                            }
                            alt={'/'}
                            style={{ height: '12px', width: '22px' }}
                          />
                        </StyledTableCell>
                      )}

                      {columnsEnabled.referencia && (
                        <StyledTableCell
                          align="left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('referencia')}
                        >
                          <FormattedMessage
                            id="table_column_referencia"
                            defaultMessage="Referencia"
                          />
                          <img
                            className="ml-1"
                            src={
                              sortQuery.modelo === ''
                                ? Sort
                                : sortQuery.referencia === 'asc'
                                  ? SortAsc
                                  : SortDesc
                            }
                            alt={'/'}
                            style={{ height: '12px', width: '22px' }}
                          />
                        </StyledTableCell>
                      )}

                      {columnsEnabled.marca && props.marcaObligatorio ? (
                        <StyledTableCell
                          align="left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('marca')}
                        >
                          <FormattedMessage id="table_column_marca" defaultMessage="Marca" />
                          <img
                            className="ml-1"
                            src={
                              sortQuery.modelo === ''
                                ? Sort
                                : sortQuery.marca === 'asc'
                                  ? SortAsc
                                  : SortDesc
                            }
                            alt={'/'}
                            style={{ height: '12px', width: '22px' }}
                          />
                        </StyledTableCell>
                      ) : ''}

                      {columnsEnabled.costo && (
                        <StyledTableCell
                          align="left"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSort('costo')}
                        >
                          <FormattedMessage id="table_column_costo" defaultMessage="Costo" />
                          <img
                            className="ml-1"
                            src={
                              sortQuery.modelo === ''
                                ? Sort
                                : sortQuery.costo === 'asc'
                                  ? SortAsc
                                  : SortDesc
                            }
                            alt={'/'}
                            style={{ height: '12px', width: '22px' }}
                          />
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="left" style={{ cursor: 'pointer' }}>
                        <FormattedMessage
                          id="repuestosGrid.render.table_column_acciones"
                          defaultMessage="Acciones"
                        />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && (
                      <TableRow>
                        <StyledTableCell colSpan={11} align="center">
                          <FormattedMessage id="Cargando" defaultMessage="Cargando" />
                        </StyledTableCell>
                      </TableRow>
                    )}
                    {!loading &&
                      items &&
                      items.map((item) => (
                        <RepuestosGridRow
                          key={`repuestosGridRow_${item.id}`}
                          columnsEnabled={columnsEnabled}
                          item={item}
                          handleEditItem={() => { handleEditItem(item, 'edit') }}
                          handleDelete={() => handleDelete(item.id)}
                          marcaObligatorio={props.marcaObligatorio}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={rowsPerPage}
                component="div"
                count={paginationCount}
                rowsPerPage={pagination.perPage}
                page={pagination.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Mostrar"
                labelDisplayedRows={({ from, to, count }) =>
                  `Mostrando ${from} a ${to} de ${count}`
                }
              />
            </div>
          </div>
        </div>
      </div>

      <RepuestoEditModal
        action={action}
        item={selectedItem}
        selectsData={selectsData}
        marcas={marcas}
        closeModal={closeModal}
        onModalEditSubmit={onModalEditSubmit}
        columnsEnabled={columnsEnabled}
        marcaObligatorio={props.marcaObligatorio}
        onSelectCategoria={(id) => onSelectCategoria(id)}
      />
    </>
  );
};

export default RepuestosGrid;
