import $ from "jquery";
import moment from "moment";
import "moment/min/locales";
import React, { Component } from "react";
import EnTallerGrisImage from "../../assets/images/moviles/herramienta-gris.svg";
import EnTallerRojoImage from "../../assets/images/moviles/herramienta-rojo.svg";
import EnTallerVerdeImage from "../../assets/images/moviles/herramienta-verde.svg";
import TicketGarantiaImage from "../../assets/images/moviles/ticket-garantia.svg";
import TicketPendienteImage from "../../assets/images/moviles/ticket-pendiente.png";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import Dialog from "../../commons/dialog/Dialog.js";
import Security from "../../commons/security/Security.js";
import TicketDatosCierreVencimiento from "./TicketDatosCierreVencimiento.js";
import TicketTareasCierre from "./TicketTareasCierre.js";
import TicketTareasItemsCierre from "./TicketTareasItemsCierre.js";

import DatePicker from 'react-datepicker';
import { FormattedMessage, injectIntl } from "react-intl";
import Select from 'react-select';
import '../../assets/css/encuesta-satisfaccion-tickets.css';
import TicketAlertaChecklist from "../../assets/images/moviles/ticket-alerta.svg";
import { ID_CANCELACION } from '../../commons/constants/tipoMotivos.js';
import Timezone from "../../commons/timezone/Timezone.js";
import { getTicketEstadosComponent } from "../../commons/utils/Multilanguage";
import * as Utils from '../../commons/utils/Utils';
import backendStrings from "../../lang/backendStrings.js";
import TicketInformarFechaRealizado from './TicketInformarFechaRealizado.js';

class TicketHeader extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.ajaxHandler = new AjaxHandler();
    this.serviciosNoautogestionables = [];
    this.configAdjuntosObligatorios = null;

    moment.locale("es");

    this.state = {
      fotoMarcaUrl: null,
      observaciones_cancelacion: "",
      motivo_cancelacion_id: null,
      motivos: [],
      motivosLoading: false,
      fechaCancelacion: null,
      modalDatosCierreVencimientoKey: 0,
      modalTareasCierreKey: 0,
      encuestaNivelSatisfaccion:null,
      encuestaComentariosSatisfaccion:'',
      formDataProximoVencimiento:null,
      errors: [],
      alertaChecklistHabilitado: ConfigBusiness.get("tickets.alertaCheckList.habilitado") == 'true' ? true : false,
      encuestaCorrectivo: ConfigBusiness.get("tickets.solicitarEncuestaSatisfaccion.correctivo") == 'true' ? true : false,
      encuestaPreventivo: ConfigBusiness.get("tickets.solicitarEncuestaSatisfaccion.preventivo") == 'true' ? true : false,
      encuestaVencimiento: ConfigBusiness.get("tickets.solicitarEncuestaSatisfaccion.vencimiento") == 'true' ? true : false,
      informaFechaSalidaTaller: ConfigBusiness.get("tickets.informaFechaSalidaTaller.habilitado") == 'true' ? true : false,
      fechaHoraSalidaTaller: null, 
      ticketTipo: localStorage.getItem('ticketsTipoStrings') ? JSON.parse(localStorage.getItem('ticketsTipoStrings')) : [],
      tareasCierreInfo:null,
      modalFechaRealizadoKey: 0,
    };
    
    this.handleDatosCierreVencimientoSave = this.handleDatosCierreVencimientoSave.bind(this);
    this.handleInputMotivoChange = this.handleInputMotivoChange.bind(this);
    this.handleCancelModalCancelacion = this.handleCancelModalCancelacion.bind(this);
    this.handleSubmitCancelar = this.handleSubmitCancelar.bind(this);
    this.handleAprobarAuditor = this.handleAprobarAuditor.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
    this.init();
  }

  componentDidUpdate() {
    $('[data-tooltip="tooltip"]').tooltip();
  }

  init() {
    this.ajaxHandler.subscribe(this);
    let ticket = this.props.ticket;
    let serviciosNoautogestionables = ConfigBusiness.get(
      "servicios.noAutogestionables"
    ).split(",");

    this.configAdjuntosObligatorios = ConfigBusiness.get(
      "tickets.cambiosEstados"
    );

    if (
      ticket &&
      ticket.movil &&
      ticket.movil.marca &&
      ticket.movil.marca.foto
    ) {
      let component = this;
      if(ticket.movil.marca.codigo){
      let photoUrl =
        process.env.PUBLIC_URL + "/images/marcas/" + ticket.movil.marca.codigo+".png";
        component.setState({
          fotoMarcaUrl: photoUrl,
        });
      }
    }
    this.handleInputMotivoChange('');
  }

  canDo(action) {
    return this.props.actions.indexOf(action) >= 0;
  }

  componentWillMount(){
    this.checkAprobarPresupuesto();
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
    this.ajaxHandler.unsubscribe();
  }

  checkAprobarPresupuesto(){
    const total = this.props.ticket.repuestos + this.props.ticket.manoDeObra + this.props.ticket.impuestos + this.props.ticket.otros;
    const n1 = this.props.ticket.servicio.montoMaximoAprobacionN1;
    const n2 = this.props.ticket.servicio.montoMaximoAprobacionN2;

    if (ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.habilitado') === 'true') {
      if(Security.hasPermission("PERMISO_APRO_N1") && total <= n1) {
        return true;
      } else if (Security.hasPermission("PERMISO_APRO_N2") && total <= n2) {
        return true;
      } else if (Security.hasPermission("PERMISO_APRO_N3")) {
        return true;
      } else {
        return false;
      }
    } else {
      //No Trabaja con niveles de aprobacion
      return true;
    }
  };
  showEntityAvatar() {
    let ticket = this.props.ticket;
    if (ticket.movil && ticket.movil.marca)
      return ticket.movil.marca.nombre.substr(0, 2).toUpperCase()

    if (ticket.persona)
      return ticket.persona.nombre.substr(0, 1).toUpperCase() +
             " " +
             ticket.persona.apellido.substr(0, 1).toUpperCase()

    if (ticket.llanta)
      return ticket.llanta.llanta_marca.nombre.substr(0, 2).toUpperCase()
  }
  showEntityName() {
    let ticket = this.props.ticket;
    if (ticket.movil)
      return ticket.movil.dominio

    if (ticket.persona)
      return ticket.persona.nombre + " " + ticket.persona.apellido

    if (ticket.llanta)
      return ticket.llanta.serial
  }

  handleAprobar() {
    this.ajaxHandler
      .fetch("/tickets/aprobar/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  async handleAprobarAuditor() {
    let error = null;
    const { ticket, adjuntos = [], intl } = this.props;    

    // Validar adjuntos obligatorios
    let categoriaId = parseInt(this.configAdjuntosObligatorios.APROBAR_AUDITOR.categoria) || 8;

    if (
      this.configAdjuntosObligatorios &&
      this.configAdjuntosObligatorios.APROBAR_AUDITOR &&
      this.configAdjuntosObligatorios.APROBAR_AUDITOR.obligatorio === "true"
    ) {

      // Filtra adjuntos en la categoría especificada
      const adjuntosEnCategoria = adjuntos.filter(
        (c) => c.id === categoriaId && c.adjuntos && c.adjuntos.length > 0
      );

      if (adjuntosEnCategoria.length === 0) {
        error = intl.formatMessage({
          id: "ticketHeader.aprobar_auditor.error_categoria_adjuntos_faltantes",
          defaultMessage: "Para aceptar el presupuesto debe ingresar un adjunto para la categoría Informe Auditor.",
        });
      }
    } else {
      error = intl.formatMessage({
        id: "ticketHeader.aprobar_auditor.error_obtener_categoria_adjuntos",
        defaultMessage: "No se pudo obtener la categoría. Contacte al administrador",
      });
    }

    // Validación de coincidencia entre adjuntos y presupuestos pendientes
    if (!error) {
      try {
        const response = await this.ajaxHandler.fetch(
          `/validar-coincidencia-presupuestos-adjuntos/${ticket.id}?categoriaId=${categoriaId}`,
          { method: 'GET' }
        );

        if (response.ok) {
          const { coincidencia, mensaje } = await response.json();

          // Verificar coincidencia de adjuntos y presupuestos pendientes
          if (!coincidencia) {
            error = mensaje;
          }
        } else {
          error = intl.formatMessage({
            id: "ticketHeader.aprobar_auditor.error_obtener_presupuestos",
            defaultMessage: "No se pudo obtener la cantidad de presupuestos pendientes. Contacte al administrador.",
          });
        }
      } catch (fetchError) {
        console.error(fetchError);
        error = intl.formatMessage({
          id: "ticketHeader.aprobar_auditor.error_obtener_presupuestos",
          defaultMessage: "No se pudo obtener la cantidad de presupuestos pendientes. Contacte al administrador.",
        });
      }
    }

    // Mostrar ventana de alerta si hay un error
    if (error) {
      Dialog.alert(intl, {
        title: intl.formatMessage({
          id: "ticketHeader.aprobar_auditor.error_adjuntos_faltantes",
          defaultMessage: "Adjuntos faltantes",
        }),
        text: error,
      });
    } else {
      // Proceder a aprobar el auditor si no hay errores
      this.ajaxHandler
        .fetch("/tickets/aprobar-auditor/" + ticket.id, { method: "POST" })
        .then((response) => {
          if (response.status === 204) {
            this.props.callbackDataUpdate();
          } else {
            response.json().then((data) => {
              this.setState({ errors: data.detalle });
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.ajaxHandler.handleError(error);
        });
    }
}

  handleAprobarAbono() {
    this.ajaxHandler
      .fetch("/tickets/aprobar-abono/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleRechazarAbono() {
    this.ajaxHandler
      .fetch("/tickets/rechazar-abono/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleCancelar() {
    $("#motivo_cancelacion_modal").modal("toggle");
  }

  handleSubmitCancelar(event){
    event.preventDefault();
    $("#motivo_cancelacion_modal").modal("hide");
    this.ajaxHandler
      .fetch("/tickets/cancelar/" + this.props.ticket.id, {
        method: "POST",
        body: JSON.stringify({
          comentario: this.state.observaciones_cancelacion,
          motivo_cancelacion_id: this.state.motivo_cancelacion_id
        }),
      })
      .then((response) => {
        if (response.status === 204) {
          this.setState({
            motivo_cancelacion_id: null,
            observaciones_cancelacion: ""
          });
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });

  }

  handleCerrar() {
    $("#modal_cierre").modal("hide");

    if (this.props.ticket.llanta && this.props.ticket.id == this.props.ticket.llanta.ticket_id) {
      this.props.redirectLlanta(this.props.ticket);
    } else {
      let error = null;
      if (this.props.ticket.servicio.adjuntoObligatorio) {
        let categoriaId = this.props.ticket.servicio.categoria.id;
        if (
          this.props.adjuntos &&
          !this.props.adjuntos.filter(
            (c) => c.id === categoriaId && c.adjuntos.length
          ).length
        ) {
          error =
            this.props.intl.formatMessage({
              id:
                "ticketHeader.close_tickets.error_ingresar_al_menos_un_adjunto",
              defaultMessage:
                "Parar cerrar el ticket debe ingresar al menos un adjunto para la categoría ",
            }) +
            this.props.ticket.servicio.categoria.nombre +
            ".";
        }
      }

      if (error) {
        Dialog.alert(this.props.intl, {
          title: this.props.intl.formatMessage({
            id: "ticketHeader.close_tickets.error_adjuntos_faltantes",
            defaultMessage: "Adjuntos faltantes",
          }),
          text: error,
        });
      } else {
          let fechaHoraSalidaTaller = this.state.fechaHoraSalidaTaller;
          if (fechaHoraSalidaTaller)
            fechaHoraSalidaTaller = Timezone.getDateForClient(fechaHoraSalidaTaller, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');

          let [fechaAlta, fechaVencimiento, numero, renovacion] = [null,null,null,null]
          
          if(this.state.formDataProximoVencimiento !== null){
            ({fechaAlta, fechaVencimiento, numero, renovacion} = {...this.state.formDataProximoVencimiento})
            fechaAlta = Timezone.getDateForClient(fechaAlta, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
            fechaVencimiento = Timezone.getDateForClient(fechaVencimiento, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
          }
          this.ajaxHandler
            .fetch("/tickets/cerrar/" + this.props.ticket.id, {
              method: "POST",
              body: JSON.stringify({
                encuestaNivelSatisfaccion:this.state.encuestaNivelSatisfaccion,
                encuestaComentariosSatisfaccion:this.state.encuestaComentariosSatisfaccion,
                fechaHoraSalidaTaller: fechaHoraSalidaTaller,
                fechaAlta: fechaAlta,
                fechaVencimiento: fechaVencimiento,
                numero: numero,
                renovacion: renovacion
              }),
            })
            .then((response) => {
              if (response.status === 204) {
                this.props.callbackDataUpdate();
              } else {
                response.json().then((data) => {
                  this.setState({
                    errors: data.detalle,
                  });
                });
              }
              window.scrollTo(0, 0);
            })
            .catch((error) => {
              this.ajaxHandler.handleError(error);
            });
      }
    }
  
  }

  handleCerrarGestoria(e) {
    e.preventDefault();
    let error = null;
    if (this.props.ticket.servicio.adjuntoObligatorio) {
      let categoriaId = this.props.ticket.servicio.categoria.id;
      if (
        this.props.adjuntos &&
        !this.props.adjuntos.filter(
          (c) => c.id === categoriaId && c.adjuntos.length
        ).length
      ) {
        error =
          this.props.intl.formatMessage({
            id:
              "ticketHeader.cerrarGestoria.error_ingresar_al_menos_un_adjunto",
            defaultMessage:
              "Parar cerrar el ticket debe ingresar al menos un adjunto para la categoría ",
          }) +
          this.props.ticket.servicio.categoria.nombre +
          ".";
      }
    }

    if (error) {
      Dialog.alert(this.props.intl, {
        title: this.props.intl.formatMessage({
          id: "ticketHeader.cerrarGestoria.error_adjuntos_faltantes",
          defaultMessage: "Adjuntos faltantes",
        }),
        text: error,
      });
    } else {
      this.ajaxHandler
        .fetch("/tickets/cerrar-gestoria/" + this.props.ticket.id, {
          method: "POST",
        })
        .then((response) => {
          if (response.status === 204) {
            this.props.callbackDataUpdate();
          } else {
            response.json().then((data) => {
              this.setState({
                errors: data.detalle,
              });
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.ajaxHandler.handleError(error);
        });
    }
  }

  handleRecotizarAuditor() {
    let error = null;

    //HACK
    let categoriaId = 8;

    if (
      this.props.adjuntos &&
      !this.props.adjuntos.filter(
        (c) => c.id === categoriaId && c.adjuntos.length
      ).length
    ) {
      error = this.props.intl.formatMessage({
        id: "ticketHeader.recotizarAuditor.error_ingresar_un_adjunto",
        defaultMessage:
          "Parar enviar a Recotizar el presupuesto debe ingresar un adjunto para la categoría Informe Auditor.",
      });
    }

    if (error) {
      Dialog.alert(this.props.intl, {
        title: this.props.intl.formatMessage({
          id: "ticketHeader.recotizarAuditor.error_adjuntos_faltantes",
          defaultMessage: "Adjuntos faltantes",
        }),
        text: error,
      });
    } else {
      this.ajaxHandler
        .fetch(
          "/tickets/solicitar-recotizacion-auditor/" + this.props.ticket.id,
          {
            method: "POST",
          }
        )
        .then((response) => {
          if (response.status === 204) {
            this.props.callbackDataUpdate();
          } else {
            response.json().then((data) => {
              this.setState({
                errors: data.detalle,
              });
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.ajaxHandler.handleError(error);
        });
    }
  }

  handleRecotizar() {
    this.props.intl.formatMessage({
      id: "ticketHeader.recotizarAuditor.error_ingresar_un_adjunto",
      defaultMessage:
        "Parar enviar a Recotizar el presupuesto debe ingresar un adjunto para la categoría Informe Auditor.",
    });
    this.ajaxHandler
      .fetch("/tickets/solicitar-recotizacion/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleListoParaRetirar(e) {
    e.preventDefault();
    if (ConfigBusiness.get("tickets.tareasCierre.habilitado") === "true") {
      let modalTareasCierreKey = this.state.modalTareasCierreKey + 1;
      this.setState(
        {
          modalTareasCierreKey: modalTareasCierreKey,
        },
        () => {
          $("#tareas_cierre_modal").modal({ backdrop: "static" });
        }
      );
    } else {
      let modalFechaRealizadoKey = this.state.modalFechaRealizadoKey + 1;
      this.setState(
        {
          modalFechaRealizadoKey: modalFechaRealizadoKey,
        },
        () => {
          $('#fecha_realizado_listo_para_retirar_modal').modal({backdrop: 'static'});
        }
      );
    }
  }

  handleEnviarAReparar() {
    this.ajaxHandler
      .fetch("/tickets/enviar-a-reparar/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleConfirmarReparacion() {
    let error = null;

    if (this.props.ticket.servicio.nombre === "GRABADO DE AUTOPARTES") {
      //HACK
      let categoriaId = 5;

      if (
        this.props.adjuntos &&
        !this.props.adjuntos.filter(
          (c) => c.id === categoriaId && c.adjuntos.length
        ).length
      ) {
        error = this.props.intl.formatMessage({
          id: "ticketHeader.confirmarReparacion.error_ingresar_un_adjunto",
          defaultMessage:
            "Parar enviar a Listo Para Retirar debe ingresar un adjunto para la categoría Grabado de Autopartes.",
        });
      }
    }

    if (error) {
      Dialog.alert(this.props.intl, {
        title: this.props.intl.formatMessage({
          id: "ticketHeader.confirmarReparacion.error_adjuntos_faltantes",
          defaultMessage: "Adjuntos faltantes",
        }),
        text: error,
      });
    } else {
      this.ajaxHandler
        .fetch("/tickets/confirmar-reparacion/" + this.props.ticket.id, {
          method: "POST",
        })
        .then((response) => {
          if (response.status === 204) {
            this.props.callbackDataUpdate();
          } else {
            response.json().then((data) => {
              this.setState({
                errors: data.detalle,
              });
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.ajaxHandler.handleError(error);
        });
    }
  }

  handleAdicional() {
    this.ajaxHandler
      .fetch("/tickets/solicitar-adicional/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleDatosCierreVencimientoSave = (formDataProximoVencimiento) => {
    this.setState({
      formDataProximoVencimiento: formDataProximoVencimiento
    },
      () => {
        $("#datos_vencimiento_modal").modal("toggle");
        
        if (this.state.encuestaVencimiento  || this.state.informaFechaSalidaTaller) {
          $("#modal_cierre").modal("toggle");
        } else {
          this.handleCerrar();
        }
      });
  }

  handleDatosCierreVencimientoClose() {
    $("#datos_vencimiento_modal").modal("toggle");
  }

  handleFechaRealizadoClose() {
    $('#fecha_realizado_listo_para_retirar_modal').modal('toggle');
  }

  handleTareasCierreSave(obj) {
    $("#tareas_cierre_modal").modal("toggle");

    let modalFechaRealizadoKey = this.state.modalFechaRealizadoKey + 1;
    let tareasCierreInfo = obj;

    this.setState(
      {
        modalFechaRealizadoKey: modalFechaRealizadoKey,
        tareasCierreInfo : tareasCierreInfo
      },
      () => {
        $('#fecha_realizado_listo_para_retirar_modal').modal({backdrop: 'static'});
      }
    );

  }

  handleTareasCierreClose() {
    $("#fecha_realizado_listo_para_retirar_modal").modal("toggle");
  }

    handleFechaRealizadoSave(obj) {
    $('#fecha_realizado_listo_para_retirar_modal').modal('toggle');

    this.ajaxHandler.fetch('/tickets/update-realizado/' + this.props.ticket.id, {
      method: 'POST',
      body: JSON.stringify({
        ...obj
      }),
    }).then(response => {
      if(response.status === 204) {
        this.props.callbackDataUpdate();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      this.ajaxHandler.handleError(error);
    });

    if (this.state.tareasCierreInfo !== null) {

      this.ajaxHandler.fetch("/tickets/listo-para-retirar/" + this.props.ticket.id, {
        method: "POST",
        body: JSON.stringify({
          ...this.state.tareasCierreInfo,
        }),
      }).then(response => {
        if(response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json()
          .then(data => {
            this.setState({
              errors: data.detalle
            });
          });
        }
        window.scrollTo(0,0);
      }).catch((error) => {
        this.ajaxHandler.handleError(error);
      }); 

    } else {

        this.ajaxHandler
          .fetch("/tickets/listo-para-retirar/" + this.props.ticket.id, {
            method: "POST",
          })
          .then((response) => {
            if (response.status === 204) {
              this.props.callbackDataUpdate();
            } else {
              response.json().then((data) => {
                this.setState({
                  errors: data.detalle,
                });
              });
            }
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            this.ajaxHandler.handleError(error);
          });
      } 
  }

  canClose() {
    let ticket = this.props.ticket;
    let canClose = false;
    if (ticket.estado === "PROVISORIO" && ticket.ot.servicio.estadoProvisorio) {
      canClose = true;
    } else if (
      ticket.estado === "EN_GESTION" &&
      ticket.ot.servicio.necesitaGestoria &&
      !ticket.ot.servicio.estadoProvisorio
    ) {
      canClose = true;
    } else if (
      ticket.estado === "EN_ESCRIBANIA" &&
      ticket.ot.servicio.certificaEscribania &&
      !ticket.ot.servicio.necesitaGestoria &&
      !ticket.ot.servicio.estadoProvisorio
    ) {
      canClose = true;
    } else if (
      ticket.estado === "EN_FORMULARIO" &&
      ticket.ot.servicio.necesitaFormularios &&
      !ticket.ot.servicio.certificaEscribania &&
      !ticket.ot.servicio.necesitaGestoria &&
      !ticket.ot.servicio.estadoProvisorio
    ) {
      canClose = true;
    }
    return canClose;
  }


  handleCheckCierre(){
    let ticketTipo = this.props.ticket.ticketTipo
    let showForCorrectivo = ticketTipo === "CORRECTIVO" && this.state.encuestaCorrectivo
    let showForPreventivo = ticketTipo === "PREVENTIVO" && this.state.encuestaPreventivo

    if(ticketTipo == "VENCIMIENTO"){
      $("#datos_vencimiento_modal").modal({ backdrop: "static" });
    } else if (showForCorrectivo || showForPreventivo || this.state.informaFechaSalidaTaller) {
      $("#modal_cierre").modal("toggle");
    } else {
     this.handleCerrar()
    }
  }

  onChangeValueNivelEncuesta(event){
    this.setState({
      encuestaNivelSatisfaccion:event.target.value,
    })
  }

  onChangeValueComentarioEncuesta(event){
    this.setState({
      encuestaComentariosSatisfaccion:event.target.value,
    })
  }

  handleCancelModalCierre(){
    this.setState({
      encuestaNivelSatisfaccion:null,
      encuestaComentariosSatisfaccion:'',
      fechaHoraSalidaTaller: null,
      formDataProximoVencimiento: null
    })
    $("#modal_cierre").modal("toggle");
  }

  handleDatePickerFormChange(name, event) {
		if(name == 'fechaHoraSalidaTaller')
		  this.setState({ fechaHoraSalidaTaller: event });
  }
	
  handleDatePickerFormRawChange(name, date) {
    if(name == 'fechaHoraSalidaTaller')
    this.setState({ fechaHoraSalidaTaller: date });
  }

  validateModalCierre(){
    let ticketTipo = this.props.ticket.ticketTipo
    let rta = false;
    
    if (ticketTipo === "CORRECTIVO" && this.state.encuestaCorrectivo && !this.state.encuestaNivelSatisfaccion)
      rta = true;
    
    if (ticketTipo === "PREVENTIVO" && this.state.encuestaPreventivo && !this.state.encuestaNivelSatisfaccion)
      rta = true;

    if (ticketTipo === "VENCIMIENTO" && this.state.encuestaVencimiento && !this.state.encuestaNivelSatisfaccion)
      rta = true;
    
    if (this.state.informaFechaSalidaTaller && !this.state.fechaHoraSalidaTaller)
      rta = true;

    return rta
  }

  handleInputMotivoChange(motivo){
		this.setState({
			motivosLoading: true
		});
		Utils.getData('/motivos/simple-search?nombre=' + motivo + '&tipo_motivo=' + ID_CANCELACION).then((res) => {
      res.forEach(element => {
        if (element.multilenguaje_id) {
          element.nombre = this.props.intl.formatMessage({...(backendStrings[element.multilenguaje_id] ? backendStrings[element.multilenguaje_id] : backendStrings['errors.default']) })
        }
      });
			this.setState({
				motivos: res,
				motivosLoading: false
			});
		});
	};

  handleCancelModalCancelacion(event) {
    event.preventDefault();
    $('#motivo_cancelacion_modal').modal('toggle');
		this.setState({
			motivo_cancelacion_id: null,
			observaciones_cancelacion: ""
		});
	}

  render() {
    let canCancelTicket = false
    let canCloseTicket = false
    let ticket = this.props.ticket;
    let classAvatar = "dt-user-avatar mr-1 ";
    switch (ticket.ticketTipo) {
      case "PREVENTIVO":
        classAvatar += "paleta-preventivo";
        canCancelTicket = Security.hasPermission("TICKETS_CANCELAR_PREVENTIVOS")
        canCloseTicket = Security.hasPermission("TICKETS_CERRAR_PREVENTIVOS")
        break;
      case "VENCIMIENTO":
        classAvatar += "paleta-vencimiento";
        canCancelTicket = Security.hasPermission("TICKETS_CANCELAR_VENCIMIENTOS")
        canCloseTicket = Security.hasPermission("TICKETS_CERRAR_VENCIMIENTOS")
        break;
      case "GESTORIA":
        classAvatar += "paleta-gestoria";
        canCancelTicket =Security.hasPermission("TICKETS_CANCELAR_GESTORIA")
        canCloseTicket = Security.hasPermission("TICKETS_CERRAR_GESTORIA")
        break;
      case "CORRECTIVO":
        classAvatar += "paleta-correctivo";
        canCancelTicket = Security.hasPermission("TICKETS_CANCELAR_CORRECTIVOS")
        canCloseTicket = Security.hasPermission("TICKETS_CERRAR_CORRECTIVOS")
        break;
      default:
        break;
    }
    let classTP = "";
    switch (ticket.semaforo) {
      case "VERDE":
        classTP = "bg-success";
        break;
      case "AMARILLO":
        classTP = "bg-yellow";
        break;
      case "ROJO":
        classTP = "bg-danger";
        break;
      default:
        classTP = "bg-blue-grey";
        break;
    }
    let enTallerMark = EnTallerGrisImage;
    switch (ticket.enTaller) {
      case "1":
        enTallerMark = EnTallerGrisImage;
        break;
      case "2":
        enTallerMark = EnTallerVerdeImage;
        break;
      case "3":
        enTallerMark = EnTallerRojoImage;
        break;
    }
    let classNivelEncuesta = ""
    if(ticket.encuestaNivelSatisfaccion < 7){
      classNivelEncuesta = "bg-danger"
    }
    if(ticket.encuestaNivelSatisfaccion === 7 || ticket.encuestaNivelSatisfaccion === 8){
      classNivelEncuesta = "bg-yellow"
    }
    if(ticket.encuestaNivelSatisfaccion === 9 || ticket.encuestaNivelSatisfaccion === 10){
      classNivelEncuesta = "bg-success"
    }
    return (
      <div className="card" {...(ticket.ticketGarantia || ticket.ticketPendiente || (ticket.alertaChecklist && this.state.alertaChecklistHabilitado)) && {style:{'backgroundColor':'yellow'}}}>
        <div className="card-content">
          <div className="card-body">
            <div className="row">
              <div className="col-md-10">
                <h5 className="header-card">
                  <div className="item" style={{ display: "flex" }}>
                    <div id="avatar" className={classAvatar}>
                      {this.state.ticketTipo[ticket.ticketTipo].substr(0, 1).toUpperCase()}
                    </div>
                    {"TKT " +
                      ticket.id +
                      (ticket.idProveedor == ""
                        ? ""
                        : " (" + ticket.idProveedor + ")")}
                      {(ticket.alertaChecklist && this.state.alertaChecklistHabilitado) &&
                        <div
                          className="item"
                          title={this.props.intl.formatMessage({
                          id:
                            "ticketHeader.render.ticket_alerta_checlist",
                          defaultMessage: "Vehículo con inspecciones pendientes de resolución, ver inspecciones",
                          })}
                        >
                           <a href={"/formulario?entidad="+ticket.movil.dominio} target="_blank">
                            <img
                              style={{ width: "34px", transform: "scale(1.4, 1.4)", paddingLeft: "5px"}}
                              src={TicketAlertaChecklist}
                            />
                           </a>
                        </div>
                      }
                      {ticket.ticketGarantia &&
                        <div
                          className="item"
                          title={this.props.intl.formatMessage({
                          id:
                            "ticketHeader.render.ticket_garantia",
                          defaultMessage: "Correctivo en garantía, ver ticket:  ",
                          })  +  ticket.ticketGarantia } 
                        >
                          <a href={"/tickets/"+ticket.ticketGarantia} target="_blank">
                            <img
                              style={{ width: "34px", transform: "scale(1.4, 1.4)", paddingLeft: "5px"}}
                              src={TicketGarantiaImage}
                            />
                          </a>
                        </div>
                      }
                       {ticket.ticketPendiente &&
                        <div
                          className="item"
                          title={this.props.intl.formatMessage({
                          id:
                            "ticketHeader.render.ticket_pendiente",
                          defaultMessage: "Este vehículo cuenta con otro ticket en ejecución, ver ticket: ",
                          })  + ticket.ticketPendiente + " - " + ticket.ticketPendienteServicio.nombre } 
                        >
                          <a href={"/tickets/"+ticket.ticketPendiente} target="_blank">
                            <img
                              style={{ width: "30px", transform: "scale(1.4, 1.4)", paddingLeft: "10px"}}
                              src={TicketPendienteImage}
                            />
                          </a>
                        </div>
                      }
                  </div>
                  <div className="divider"></div>
                  <div
                    className="item text-uppercase"
                    style={{ display: "flex" }}
                  >
                    {this.state.fotoMarcaUrl ? (
                      <div
                        id="avatar"
                        className="dt-user-avatar-image mr-1"
                        style={{
                          backgroundImage:
                            "url(" + this.state.fotoMarcaUrl + ")",
                        }}
                      ></div>
                    ) : (
                      <div id="avatar" className="dt-user-avatar mr-1">
                        {this.showEntityAvatar()}
                      </div>
                    )}
                    {" "}
                    {this.showEntityName()}
                  </div>
                  <div className="divider"></div>
                  <div className="item text-uppercase">
                    {ticket.servicio.nombre}
                  </div>
                  <div className="divider"></div>
                  <div className="item text-uppercase">{getTicketEstadosComponent(ticket.estado)}</div>
                  <div className="divider"></div>
                  <div className="item text-uppercase">
                    { Timezone.getDateForClient(ticket.fechaHoraAlta, null, 'L') }
                  </div>
                  {ticket.enTaller !== 0 ? <div className="divider"></div> : ""}
                  {ticket.enTaller !== 0 ? (
                    <div className="item">
                      <img
                        style={{ width: "36px", transform: "scale(1.6, 1.6)" }}
                        src={enTallerMark}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="divider"></div>
                  <div className="item text-uppercase">
                    {"TT "}
                    <h3 className="display-inline">
                      <div className="badge badge-pill bg-blue-grey">
                        {ticket.mttTotal}
                      </div>
                    </h3>
                  </div>
                  {ticket.estado !== "CERRADO" &&
                  ticket.estado !== "CANCELADO" ? (
                    <div className="divider"></div>
                  ) : (
                    ""
                  )}
                  {ticket.estado !== "CERRADO" &&
                  ticket.estado !== "CANCELADO" ? (
                    <div className="item text-uppercase">
                      {"TP "}
                      <h3 className="display-inline">
                        <div className={"badge badge-pill " + classTP}>
                          {ticket.mttParcial}
                        </div>
                      </h3>
                    </div>
                  ) : (
                    ""
                  )}
                  {(ticket.estado === "CERRADO" && ticket.encuestaNivelSatisfaccion) ? (
                    <>
                    <div className="divider"></div>
                    <div className="item text-uppercase">
                    {"ST "}
                    <h3 className="display-inline">
                      <div
                      className={"badge badge-pill " + classNivelEncuesta}
                      data-placement="top"
                      data-tooltip="tooltip"
                      title={ticket.encuestaComentarios}
                      >
                        {ticket.encuestaNivelSatisfaccion}
                      </div>
                    </h3>
                    </div>
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              </div>
              <div className="col-md-2 p-0">
                <div>
                  <div
                    className="row dt-icons"
                    style={{
                      display: this.props.action === "VIEW" ? "none" : "",
                    }}
                  >
                    <div className="col-12 text-right pr-2">
                      {this.canDo("A_RECOTIZAR") &&
                      Security.hasPermission("TICKETS_RECOTIZAR") ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id: "ticketHeader.render.button_title_recotizar",
                            defaultMessage: "Recotizar",
                          })}
                          onClick={this.handleRecotizar.bind(this)}
                        >
                          <i className="ft-refresh-ccw"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("A_RECOTIZAR_AUDITOR") &&
                      Security.hasPermission("TICKETS_RECOTIZAR_AUDITOR") ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_recotizar_auditor",
                            defaultMessage: "Recotizar Auditor",
                          })}
                          onClick={this.handleRecotizarAuditor.bind(this)}
                        >
                          <i className="ft-refresh-ccw"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("APROBAR_AUDITOR") &&
                      Security.hasPermission("TICKETS_APROBAR_AUDITOR") ? (
                        <div
                          className="btn btn-success box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_valida_auditor",
                            defaultMessage: "Valida Auditor",
                          })}
                          onClick={this.handleAprobarAuditor.bind(this)}
                        >
                          <i className="ft-thumbs-up"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {!this.canDo("APROBAR_AUDITOR") && this.canDo("APROBAR") &&
                      Security.hasPermission("TICKETS_APROBAR")  &&
                      this.checkAprobarPresupuesto() ? (
                            <div
                              className="btn btn-success box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                              data-tooltip="tooltip"
                              data-placement="top"
                              title={this.props.intl.formatMessage({
                                id:
                                  "ticketHeader.render.button_title_aprobar_prespupuesto",
                                defaultMessage: "Aprobar Prespupuesto",
                              })}
                              onClick={this.handleAprobar.bind(this)}
                            >
                              <i className="ft-thumbs-up"></i>
                            </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("APROBAR_ABONO") &&
                      Security.hasPermission("TICKETS_APROBAR_ABONO") &&
                      ticket.abono ? (
                        <div
                          className="btn btn-success box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_aprobar_abono",
                            defaultMessage: "Aprobar Abono",
                          })}
                          onClick={this.handleAprobarAbono.bind(this)}
                        >
                          <i className="ft-check"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("RECHAZAR_ABONO") &&
                      Security.hasPermission("TICKETS_RECHAZAR_ABONO") &&
                      ticket.abono ? (
                        <div
                          className="btn btn-danger box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_rechazar_abono",
                            defaultMessage: "Rechazar Abono",
                          })}
                          onClick={this.handleRechazarAbono.bind(this)}
                        >
                          <i className="ft-x"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("LISTO_PARA_RETIRAR") &&
                      Security.hasPermission(
                        "TICKETS_PASAR_LISTO_PARA_RETIRAR"
                      ) ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_listo_para_retirar",
                            defaultMessage: "Listo para Retirar",
                          })}
                          onClick={this.handleListoParaRetirar.bind(this)}
                        >
                          <i className="ft-flag"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {(this.canDo("SOLICITAR_ADICIONAL") &&
                      Security.hasPermission("TICKETS_COTIZAR_ADICIONAL")) ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_recotizar_adicional",
                            defaultMessage: "Recotizar Adicional",
                          })}
                          onClick={this.handleAdicional.bind(this)}
                        >
                          <i className="ft-refresh-ccw"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("A_CONFIRMAR") &&
                      Security.hasPermission("TICKETS_CONFIRMAR_REPARACION") ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_confirmar_reparacion",
                            defaultMessage: "Confirmar Reparación",
                          })}
                          onClick={this.handleConfirmarReparacion.bind(this)}
                        >
                          <i className="ft-check-square"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("A_REPARAR") &&
                      Security.hasPermission("TICKETS_ENVIAR_A_REPARAR") ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_enviar_a_reparar",
                            defaultMessage: "Enviar a reparar",
                          })}
                          onClick={this.handleEnviarAReparar.bind(this)}
                        >
                          <i className="ft-chevrons-right"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {ticket.estado === "ABIERTO" ? (
                        ticket.ticketTipo === "VENCIMIENTO" && !ticket.autogestion ? (
                          ""
                        ) : this.canDo("CERRAR") && canCloseTicket &&
                          ticket.fechaHoraRealizado !== null &&
                          ticket.autogestion ? (
                          <div
                            className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                            data-tooltip="tooltip"
                            data-placement="top"
                            title={this.props.intl.formatMessage({
                              id:
                                "ticketHeader.render.ticket_autogestion.button_title_cerrar",
                              defaultMessage: "Cerrar",
                            })}
                            onClick={this.handleCheckCierre.bind(this)}
                          >
                            <i className="ft-lock"></i>
                          </div>
                        ) : (
                          ""
                        )
                      ) : this.canDo("CERRAR") && canCloseTicket &&
                        ticket.fechaHoraRealizado !== null ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id: "ticketHeader.render.button_title_cerrar",
                            defaultMessage: "Cerrar",
                          })}
                          onClick={this.handleCheckCierre.bind(this)}
                        >
                          <i className="ft-lock"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {ticket.ticketTipo === "GESTORIA" &&
                      this.canDo("CERRAR_GESTORIA") && canCloseTicket &&
                      this.canClose() ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.cerrar_gestoria.button_title_cerrar",
                            defaultMessage: "Cerrar",
                          })}
                          onClick={this.handleCerrarGestoria.bind(this)}
                        >
                          <i className="ft-lock"></i>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.canDo("CANCELAR") && canCancelTicket ? (
                        <div
                          className="btn btn-danger box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.ticket_cancelar.button_title_cancelar_ticket",
                            defaultMessage: "Cancelar Ticket",
                          })}
                          onClick={this.handleCancelar.bind(this)}
                        >
                          <i className="ft-thumbs-down"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          tabIndex="-1"
          id="datos_vencimiento_modal"
          role="dialog"
          aria-labelledby="myModalLabel133"
        >
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalLabel133">
                  <i className="la la-history align-middle icon-modal-title"></i>
                  {this.props.intl.formatMessage({
                    id: "ticketHeader.datos_vencimiento_modal.header_ticket",
                    defaultMessage: " Ticket: ",
                  }) + ticket.id}
                </h4>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                <TicketDatosCierreVencimiento
                  key={this.state.modalDatosCierreVencimientoKey}
                  ticket={ticket}
                  callbackClose={this.handleDatosCierreVencimientoClose.bind(this)}
                  callbackSave={(data) => {this.handleDatosCierreVencimientoSave(data)}}
                ></TicketDatosCierreVencimiento>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="tareas_cierre_modal"
          role="dialog"
          aria-labelledby="myModalTareasCierre"
        >
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalTareasCierre">
                  <i className="la la-history align-middle icon-modal-title"></i>
                  {this.props.intl.formatMessage({
                    id: "ticketHeader.tareas_cierre_modal.header_ticket",
                    defaultMessage: " Ticket: ",
                  }) + ticket.id}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                {ConfigBusiness.get("tickets.presupuesto.tipo") ==="detallado" ?
                    <TicketTareasItemsCierre
                      key={this.state.modalTareasCierreKey}
                      ticket={ticket}
                      callbackClose={this.handleTareasCierreClose.bind(this)}
                      callbackSave={this.handleTareasCierreSave.bind(this)}
                    ></TicketTareasItemsCierre>
                  : 
                    <TicketTareasCierre
                      key={this.state.modalTareasCierreKey}
                      ticket={ticket}
                      callbackClose={this.handleTareasCierreClose.bind(this)}
                      callbackSave={this.handleTareasCierreSave.bind(this)}
                    ></TicketTareasCierre>
                }
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          tabIndex="-1"
          id="motivo_cancelacion_modal"
          role="dialog"
          aria-labelledby="myModalMotivoCancelacion"
        >
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalTareasCierre">
                  <i className="la la-history align-middle icon-modal-title"></i>
                  {this.props.intl.formatMessage({
                    id: "ticketHeader.tareas_cierre_modal.header_ticket",
                    defaultMessage: " Ticket: ",
                  }) + ticket.id}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                <div className="row">
                  <div className="col-md-12">
                    <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmitCancelar}>
                      <div className="form-body">
                        <div className="card pull-up">
                          <div className="card-content">
                            <div className="card-body">
                              <h4 className="form-section">
                                <i className="la la-file-text"></i> <FormattedMessage id="Motivo_de_cancelación" defaultMessage="Motivo de cancelación"/>
                                <div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="campos requeridos" defaultMessage="campos_requeridos"/></div>
                              </h4>


                              <div className="row mb-3">
                                <div className="col-md-1">
                                </div>
                                <div className="col-md-10">
                                  <div className="form-row p-1" style={{background:"#e3e4e5"}}>
                                    <div className="col-md-2">
                                        <div className="row-12 d-flex justify-content-center mt-3">
                                          <i className="fa fa-exclamation-triangle fa-3x" style={{color:"#e97c3a"}}></i>
                                        </div>
                                        <div className="row-12 d-flex justify-content-center mb-1 mt-1">
                                          <FormattedMessage 
                                            id="Debe_consignarse_el_motivo_de_cancelación" 
                                            defaultMessage="Debe consignarse el motivo de cancelación"
                                          />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label htmlFor="motivo" className="">
                                          <FormattedMessage id="Motivo_de_cancelación" defaultMessage="Motivo de cancelación"/>* :
                                        </label>
                                        <Select
                                          name="motivo_cancelacion_id"
                                          id="motivo_cancelacion_id"
                                          className="select-personal-llantas z-15"
                                          maxMenuHeight={10}
                                          placeholder={this.props.intl.formatMessage({
                                            id: 'Motivo_de_cancelación',
                                            defaultMessage: 'Motivo de cancelación',
                                          })}
                                          options={this.state.motivos}
                                          labelKey="nombre"
                                          valueKey="id"
                                          value={this.state.motivo_cancelacion_id}
                                          onChange={e => this.setState({motivo_cancelacion_id: e ? e.id : null})}
                                          onInputChange={this.handleInputMotivoChange}
                                          isLoading={this.state.motivosLoading}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="" htmlFor="comentario">
                                          <FormattedMessage id="Observaciones" defaultMessage="Observaciones"/>* :
                                        </label>
                                        <textarea 
                                          className="form-control"
                                          id="observaciones_cancelacion"
                                          name="observaciones_cancelacion"
                                          rows="7"
                                          placeholder={this.props.intl.formatMessage({ id: 'Observaciones', defaultMessage: 'Observaciones' })}
                                          value={this.state.observaciones_cancelacion}
                                          onChange={e => this.setState({observaciones_cancelacion: e.target.value})} >
                                        </textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card pull-up">
                        <div className="card-content">
                          <div className="card-body">
                            <div className="text-cd text-right">
                              <button 
                                type="submit"
                                className="btn btn-primary mr-1" 
                                disabled={this.state.motivo_cancelacion_id && this.state.observaciones_cancelacion ? false : true}
                              >
                              <i className="fa fa-check-circle"></i> <FormattedMessage id="Guardar" defaultMessage="Guardar"/>
                              </button>
                              <button type="button" className="btn btn-danger" onClick={this.handleCancelModalCancelacion}>
                              <i className="fa fa-times-circle"></i> <FormattedMessage id="Cancelar" defaultMessage="Cancelar"/>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          tabIndex="-1"
          id="modal_cierre"
          role="dialog"
          aria-labelledby="myModalEncuesta"
        >
          <div className="modal-lg modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
              <h4 className="modal-title text-white" id="myModalLabel133">
                  {this.props.intl.formatMessage({
                    id: "ticketHeader.encuesta_satisfaccion_modal.header_ticket",
                    defaultMessage: " Ticket: ",
                  }) + ticket.id}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                { this.state.informaFechaSalidaTaller ? (
                  <div className="card">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section">
                          <i className="las la-comments"></i>
                          <FormattedMessage 
                          id="ticketHeader.modal_cierre.informa_fecha_salida_taller"
                          defaultMessage=" Por favor Indicar la fecha y hora de salida de taller"/>
                        </h4>
                        <div className="row justify-content-center">
                          <div className="col-md-6">
                            <DatePicker
                              id="fechaHoraSalidaTaller"
                              name="fechaHoraSalidaTaller"
                              className="form-control date-picker-placeholder"																	
                              placeholderText={this.props.intl.formatMessage({ id: 'correctivosAbm.placeholder_fecha_de_alta', defaultMessage: 'DD/MM/AAAA' })}																	
                              selected={this.state.fechaHoraSalidaTaller === null || this.state.fechaHoraSalidaTaller === '0000-00-00' ? null : moment(this.state.fechaHoraSalidaTaller)}																	
                              onChange={(event) => this.handleDatePickerFormChange("fechaHoraSalidaTaller", event)}
                              onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaHoraSalidaTaller", event.target.value)}
                              dateFormat="DD/MM/yyyy HH:mm"
                              showTimeSelect
                              timeIntervals = { 10 }
                              maxDate={moment()}
                              minDate={this.props.ticket ? moment(this.props.ticket.fechaHoraAlta) : null}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                { (ticket.ticketTipo === "CORRECTIVO" && this.state.encuestaCorrectivo) || (ticket.ticketTipo === "PREVENTIVO" && this.state.encuestaPreventivo) || (ticket.ticketTipo === "VENCIMIENTO" && this.state.encuestaVencimiento) ? (
                <div className="card">
                    <div className="card-content">
                      <div className="card-body">
                          <h4 className="form-section">
                            <i className="las la-comments"></i><FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.nivel_satisfaccion" defaultMessage=" Por favor Indicar el nivel de satisfacción por el servicio recibido"/>
                          </h4>
                        <div className="row justify-content-center">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="btn-group btn-group-toggle radioButtons" data-toggle="buttons" onChange={this.onChangeValueNivelEncuesta.bind(this)}>
                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={1} name="options" autoComplete="off"/> 1
                                  </label>

                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={2} name="options" autoComplete="off"/>2
                                  </label>

                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={3} name="options" autoComplete="off"/>3
                                  </label>

                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={4} name="options" autoComplete="off"/>4
                                  </label>

                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={5} name="options" autoComplete="off"/>5
                                  </label>

                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={6} name="options" autoComplete="off"/>6
                                  </label>

                                  <label className="btn radio-yellow active form-check-label">
                                    <input className="form-check-input" type="radio" value={7} name="options" autoComplete="off"/>7
                                  </label>

                                  <label className="btn radio-yellow active form-check-label">
                                    <input className="form-check-input" type="radio" value={8} name="options" autoComplete="off"/>8
                                  </label>

                                  <label className="btn radio-green active form-check-label">
                                    <input className="form-check-input" type="radio" value={9} name="options" autoComplete="off"/>9
                                  </label>

                                  <label className="btn radio-green active form-check-label">
                                    <input className="form-check-input" type="radio" value={10} name="options" autoComplete="off"/>10
                                  </label>
                              </div>
                            </div>
                            <div className='row mt-1'>
                                <div className='col'>
                                  <div className='row justify-content-start'>
                                    <FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.pesimo_trabajo" defaultMessage="Pésimo Trabajo"/>
                                  </div>
                                </div>
                                <div className='col'>
                                  <div className='row justify-content-center mr-1'>
                                    <span>{(this.state.encuestaNivelSatisfaccion) && this.state.encuestaNivelSatisfaccion}</span>
                                  </div>
                                </div>
                                <div className='col'>
                                  <div className='row justify-content-end'>
                                    <FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.excelente_trabajo" defaultMessage="Excelente Trabajo"/>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className="mt-2">
                              <label htmlFor="comentariosEncuesta">
                                <FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.comentarios" defaultMessage="Comentarios (Opcional)"/>
                              </label>
                              <textarea onChange={this.onChangeValueComentarioEncuesta.bind(this)} value={this.state.encuestaComentariosSatisfaccion} className="form-control" id="comentariosEncuesta" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  ) : null}
                  <div className="card">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="text-cd text-right">
                          <button 
                            type="submit"
                            className="btn btn-primary mr-1"
                            disabled={this.validateModalCierre()}
                            onClick={this.handleCerrar.bind(this)}
                            >
                            <i className="fa fa-check-circle"></i><FormattedMessage id="ticketHeader.encuesta_button.cerrarTicket" defaultMessage=" Cerrar Ticket"/>
                          </button>
                          <button type="button" className="btn btn-danger" onClick={this.handleCancelModalCierre.bind(this)}>
                            <i className="fa fa-times-circle"></i><FormattedMessage id="ticketHeader.encuesta_button.cancelar" defaultMessage=" Cancelar"/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" tabIndex="-1" id="fecha_realizado_listo_para_retirar_modal" role="dialog" aria-labelledby="myModalFechaRealiado">
            <div className="modal-lg modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalFechaRealiado"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.fecha_realizado_modal.header_ticket', defaultMessage: ' Ticket: ' }) + ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <TicketInformarFechaRealizado key={this.state.modalFechaRealizadoKey} ticket={ticket} callbackClose={this.handleFechaRealizadoClose.bind(this)} callbackSave={this.handleFechaRealizadoSave.bind(this)} ></TicketInformarFechaRealizado>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default injectIntl(TicketHeader);
