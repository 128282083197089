import 'dropzone/dist/min/dropzone.min.css'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropzone-component/styles/filepicker.css'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Redirect } from 'react-router-dom'
import 'react-select/dist/react-select.css'
import '../../assets/css/vec-dropzone.css'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import Loading from '../ui/Loading.js'

class DireccionAutocompletar extends Component {
	constructor(props) {
		super(props);

    this.ajaxHandler = new AjaxHandler();

 	moment.locale('es');

	this.state = {
		redirectTo: null,
		props: this.props,
		formData: {
			calle: '',
			numero: '',
			codigo_postal: '',
			localidad: null,
			provincia: null,
			pais: null,
			longitud: '',
			latitud: ''
		},

		provincias: [],
		latitudDefault: -34.6035814,
		longitudDefault: -58.3815711,
		ubicacion: '',
		loading: false,
		cargaInicial: true,
		mostrarMapa: false,

		};

    this.componentForm = {
			street_number: 'short_name',
			route: 'long_name',
			locality: 'long_name',
			administrative_area_level_1: 'short_name',
			sublocality_level_1: 'long_name',
			country: 'long_name',
			postal_code: 'short_name'
    };

	this.fillInAddress = this.fillInAddress.bind(this);
	this.geolocate = this.geolocate.bind(this);
	this.handleProvinciaChange = this.handleProvinciaChange.bind(this);
	this.handleInputFormChange = this.handleInputFormChange.bind(this);
	this.handleInputFormChangeAutocomplete = this.handleInputFormChangeAutocomplete.bind(this);
	this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
	this.handlePaisChange = this.handlePaisChange.bind(this);
	this.handlePaisChangeAutocomplete = this.handlePaisChangeAutocomplete.bind(this);
	this.handleDesplegarMapa = this.handleDesplegarMapa.bind(this);
	this.cambiarMarcador = this.cambiarMarcador.bind(this);
	this.placeMarkerAndPanTo = this.placeMarkerAndPanTo.bind(this);
	this.geocodeLocation = this.geocodeLocation.bind(this);
	this.actualizarUbicacionInput = this.actualizarUbicacionInput.bind(this);

	this.map = null;
	this.marker = null;
	//se inicializa el validador que luego se cargará dinámicamente según los campos obligatorios que requiere el módulo
	this.formValidation = new FormValidation({
		component: this,
		validators: {}
	});
  }

  componentDidMount() {
		$('[data-tooltip="tooltip"]').tooltip({ trigger: "hover" });

		let component = this;
		this.ajaxHandler.subscribe(this);
		
		if(this.props.camposObligatorios.length==0){
			this.props.cambioDireccion("direccionValid", true, true);
		}
		else{
			let validators = {};
			this.props.camposObligatorios.forEach(element => validators['formData.'+element] = (value) =>  Validator.notEmpty(value))
			this.formValidation = new FormValidation({
				component: this,
				validators: validators
			});
		}

		Promise.all([
			ConfigBusiness.get('direccion.latitud'),
			ConfigBusiness.get('direccion.longitud'),
		]).then((data) => {
			let latitudDefault = this.props.latitud ? this.props.latitud : data[0] ? parseFloat(data[0]) : this.state.latitudDefault;
			let longitudDefault = this.props.longitud ? this.props.longitud : data[1] ? parseFloat(data[1]) : this.state.longitudDefault;
			component.setState({
				latitudDefault: latitudDefault,
				longitudDefault: longitudDefault
			}, () => {
				this.initAutocomplete()
				this.initMap()
			})
		}).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
		});
	}

	componentWillReceiveProps(next_props) {
		//solo utilizo la función en el edit o view para pasarle la dirección del padre a este componente
		if(this.state.cargaInicial){
			let prop = next_props.formulario;
			let form = this.state.formData;
			if(prop && ( prop.localidad !== form.localidad || prop.calle !== form.calle ||
				prop.numero !== form.numero ||
				(prop.provincia && form.provincia && prop.provincia.id !== form.provincia.id) ||
				(prop.pais && form.pais && prop.pais.id !== form.pais.id))){
					let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
					formDataCopy.calle = prop.calle ? prop.calle : '';
					formDataCopy.numero = prop.numero ? prop.numero : '';
					formDataCopy.codigo_postal = prop.codigo_postal ? prop.codigo_postal :'';
					formDataCopy.latitud = prop.latitud ? prop.latitud : '';
					formDataCopy.longitud = prop.longitud ? prop.longitud : '';

					this.setState({
						formData: formDataCopy,
						cargaInicial: false
					}, () => {
						if (next_props.priorizarCoordenadas && formDataCopy.latitud && formDataCopy.longitud) {
							this.geocodeLatLng(formDataCopy.latitud, formDataCopy.longitud)
							this.actualizarMarcador(formDataCopy.latitud, formDataCopy.longitud, true);
						}
						else {
							let localidad = ''
							if (next_props.localidadString)
								localidad = prop.localidad;
							else
								localidad = prop.localidad ? prop.localidad.nombre : '';
							this.handlePaisChangeAutocomplete(
								prop.pais ? prop.pais.nombre : '',
								prop.provincia ? prop.provincia.nombre : '',
								localidad
							).then(() => {
								this.actualizarUbicacionInput()
								if (formDataCopy.latitud && formDataCopy.longitud)
									this.actualizarMarcador(formDataCopy.latitud, formDataCopy.longitud, true);
							})
						}
					});
				}
			}

  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }



	initMap() {
			return  new Promise((resolve, reject) => {
					let interval = setInterval(() => {
							if (window.google) {
									var centro = {
										lat: this.state.formData.latitud ? parseFloat(this.state.formData.latitud) : this.state.latitudDefault,
										lng: this.state.formData.longitud ? parseFloat(this.state.formData.longitud) : this.state.longitudDefault
									};
									this.map = new window.google.maps.Map(document.getElementById('map'), {
											zoom: 15,
											center: centro,
											clickable: true,
									});
									// Place a draggable marker on the map
                                    this.marker = new window.google.maps.Marker({
                                        position: centro,
                                        map: this.map,
                                        draggable: this.props.action === 'EDIT' || this.props.action === 'ADD',
                                        clickable: this.props.action === 'EDIT' || this.props.action === 'ADD',
                                        title: this.props.intl.formatMessage({ id: 'direccionAutocompletar.select_coordinates.cursor.title', defaultMessage: 'Seleccionar coordenadas' })
                                    });
                                    if (this.props.action === 'EDIT' || this.props.action === 'ADD') {
                                        window.google.maps.event.addListener(this.marker, 'dragend', this.cambiarMarcador);
                                        this.map.addListener('click', this.placeMarkerAndPanTo);
                                    }
									clearInterval(interval)
									resolve()
							}
					}, 100);
			});
	}

	placeMarkerAndPanTo(event) {
		this.geocodeLatLng(event.latLng.lat(), event.latLng.lng());
	}

	cambiarMarcador(e){
		this.geocodeLatLng(this.marker.getPosition().lat(), this.marker.getPosition().lng());
	}

	geocodeLatLng(lat, lng) {
		try {
			var geocoder = new window.google.maps.Geocoder;
			var latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
			geocoder.geocode({'location': latlng}, this.geocodeLocation);
		} catch (error) {
			console.log("Error con el servidor de google");
			console.log(error);
		}
	}

	geocodeLocation(results, status) {
		if (status === 'OK') {
				if (results[0]) {
						this.fillInAddress(results[0], false).then(()=>{this.actualizarUbicacionInput();});
				}
		}
		else{
			console.log("Error con el servidor de google");
		}
	}

	geocodeAddress(address) {
			//console.error("Address ", address);
			var geocoder = new window.google.maps.Geocoder;
			geocoder.geocode({'address': address}, function(results, status) {
					if (status === 'OK') {
							if (results[0]) {
									//console.error(results[0]);
									this.fillInAddress(results[0]);
							}
					}
			});
	}

	actualizarUbicacionInput()
	{
		let state = this.state.formData;
		//actualiza el input de ubicación
		let calle = state.calle ? state.calle + ' ' + this.state.formData.numero+', ' : '';
		let pais = state.pais ? state.pais.nombre : '';

		let provincia = state.provincia ? state.provincia.nombre + ', ' : '';
		let localidad = '';
		if (this.props.localidadString)
			localidad = state.localidad ? state.localidad + ', ' : '';
		else
			localidad = state.localidad ? state.localidad.nombre + ', ' : '';

		let direccion = calle + localidad  + provincia + pais;
		this.props.cambioDireccion("direccion", direccion, this.formValidation.state.form.valid);
		$("#autocomplete").val(direccion);
		//this.setState({ubicacion: calle + localidad  + provincia + pais});
	}

	handleInputFormChangeAutocomplete(name, value) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
		this.props.cambioDireccion(name, value, this.formValidation.state.form.valid);
  }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;

		this.setState({
			formData: formDataCopy
		});

		this.props.cambioDireccion(name, value, this.formValidation.state.form.valid);

	}

	handleSelectFormChange(name, object) {
		const value = object === null ? null : object.id;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
		this.props.cambioDireccion(name, value, this.formValidation.state.form.valid);
	}

	handlePaisChange(pais) {
    return  new Promise((resolve, reject) => {
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.provincia = null;
			formDataCopy.localidad = null;
			formDataCopy.pais = pais
			this.setState({
				formData: formDataCopy,
			}, () => {resolve()});
    }).then(()=>{
			this.props.cambioDireccion("pais", this.state.formData.pais, this.formValidation.state.form.valid);
			this.props.cambioDireccion("provincia", this.state.formData.provincia, this.formValidation.state.form.valid);

		});
  }

	handleProvinciaChange(provincia) {
    return new Promise((resolve, reject) => {

			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.localidad = null;
			formDataCopy.provincia = provincia;
			this.setState({
				formData: formDataCopy,
			},()=> {resolve()});
    }).then(()=>{
			this.props.cambioDireccion("provincia", this.state.formData.provincia, this.formValidation.state.form.valid);
			this.props.cambioDireccion("localidad", this.state.formData.localidad, this.formValidation.state.form.valid);
		});
	}


	handleLocalidadChange(localidad) {
		return  new Promise((resolve, reject) => {
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.localidad = localidad
			this.setState({
				formData: formDataCopy,
			}, () => resolve());
		}).then(()=>{
			this.props.cambioDireccion("localidad", localidad, this.formValidation.state.form.valid);
		});
	}


	handleLocalidadChangeAutocomplete(provincia, localidad){
		return new Promise((resolve,reject)=>{
				if(provincia.nombre == "Mendoza" && localidad == "")
					localidad = "Mendoza";

				if (this.props.localidadString) {
					resolve()
					this.handleLocalidadChange(localidad)
				}
				else {
						let endpoint = 'match'
						if (this.props.crearLocalidades)
							endpoint = 'get_or_create'
						this.ajaxHandler.fetch('/localidades/' + endpoint, {
							method: 'POST',
							body: JSON.stringify({
								nombre: localidad,
								provincia: provincia.id
							}),
						}).then(response => {
								response.json().then(localidad_backend => {
										resolve()
										this.handleLocalidadChange(localidad_backend)
								})
						})
			 }
		})
	}



	handleProvinciaChangeAutocomplete(pais, provincia, localidad){
		return new Promise((resolve,reject)=>{
				if(provincia == "CABA")
						provincia = "Capital Federal";

				let endpoint = 'match'
				if (this.props.crearProvincias)
					endpoint = 'get_or_create'
				this.ajaxHandler.fetch('/provincias/' + endpoint, {
					method: 'POST',
					body: JSON.stringify({
						nombre: provincia,
						pais: pais.id
					}),
				}).then(response => {
						response.json().then(provincia_backend => {
								this.handleProvinciaChange(provincia_backend).then(()=>{
										if(provincia_backend)
											this.handleLocalidadChangeAutocomplete(provincia_backend, localidad).then(() => resolve())
										else
											resolve()
								});
						})
				})
		})
	}


	handlePaisChangeAutocomplete(pais, provincia, localidad){
		return new Promise((resolve,reject)=>{
			this.ajaxHandler.fetch('/paises/match', {
				method: 'POST',
				body: JSON.stringify({
					nombre: pais,
				}),
			}).then(response => {
					response.json().then(pais_encontrado => {
							this.handlePaisChange(pais_encontrado).then(()=>{
								if(pais_encontrado)
									this.handleProvinciaChangeAutocomplete(pais_encontrado, provincia, localidad).then(() => resolve())
								else
									resolve()
							});
					})
			})
		});
	}


		initAutocomplete() {
				return  new Promise((resolve, reject) => {
						let interval = setInterval(() => {
								if (window.google) {
										// Create the autocomplete object, restricting the search to geographical
										// location types.
										this.autocomplete = new window.google.maps.places.Autocomplete(
												/** @type {!HTMLInputElement} */(document.getElementById('autocomplete')),
												{types: ['geocode']});

										// When the user selects an address from the dropdown, populate the address
										// fields in the form.
										if (!this.props.disabled) {
												this.autocomplete.addListener('place_changed', this.fillInAddress);
										}
										clearInterval(interval)
										resolve()
								}
						}, 100)
				});
		}

		//Al ingresar una nueva dirección en el mapa o en el input de ubicación, se recargan todos los campos de dirección
    fillInAddress(calle, esUbicacion=true) {

				return  new Promise((resolve, reject) => {
					// Get the place details from the autocomplete object.
					let place = null;
					if(!calle){
						place = this.autocomplete.getPlace();

						if(!place.address_components) return;
					}	else
						place = calle;
					var paisVal = '';
					var provinciaVal = '';
					var localidadVal = '';
					var longitudVal = '';
					var latitudVal = '';

					//se eliminan los valores de la dirección anterior
					let addCopy = JSON.parse(JSON.stringify(this.state.formData));
					addCopy['calle'] = "";
					addCopy['numero'] = "";
					addCopy['codigo_postal'] = "";
					addCopy['localidad'] = "";
					addCopy['provincia'] = null;
					addCopy['pais'] = null;
					addCopy['longitud'] = "";
					addCopy['latitud'] = "";
					this.setState({formData: addCopy});

	        // Get each component of the address from the place details
					// and fill the corresponding field on the form.
					for (var i = 0; i < place.address_components.length ; i++) {

						let types = place.address_components[i].types;

						if(types.includes('street_number')){//altura
							this.handleInputFormChangeAutocomplete("numero", place.address_components[i][this.componentForm['street_number']]);
						} else
							if(types.includes('route')){//dirección
								this.handleInputFormChangeAutocomplete("calle", place.address_components[i][this.componentForm['route']]);
							} else
								if(types.includes('locality') || types.includes('sublocality_level_1') ){//localidad o barrio en CABA
									localidadVal = place.address_components[i][this.componentForm['locality']];
									this.handleInputFormChangeAutocomplete("localidad", place.address_components[i][this.componentForm['locality']]);
								} else
									if(types.includes('administrative_area_level_1')){//provincia
										provinciaVal = place.address_components[i][this.componentForm['administrative_area_level_1']];
									} else if(types.includes('country')){//país
										paisVal = place.address_components[i][this.componentForm['country']];
									}

					}
					//actualiza latitud y longitud, y parcha el problema con Mendoza Capital
					if (place.geometry !== undefined) {
						let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
						formDataCopy.latitud = place.geometry.location.lat();
						formDataCopy.longitud = place.geometry.location.lng();

						this.setState({
							formData: formDataCopy
						});
						this.props.cambioDireccion("latitud", formDataCopy.latitud, this.formValidation.state.form.valid);
						this.props.cambioDireccion("longitud", formDataCopy.longitud, this.formValidation.state.form.valid);

						//actualiza en el mapa el marcador
						this.actualizarMarcador(formDataCopy.latitud, formDataCopy.longitud, esUbicacion);
					}
					this.handlePaisChangeAutocomplete(paisVal, provinciaVal, localidadVal).then(()=>{resolve();});
				});
			}

		actualizarMarcador(latitud, longitud, esUbicacion){
			return  new Promise((resolve, reject) => {
					if(!latitud || !longitud)
							resolve()

					let interval = setInterval(() => {
							if (window.google) {
									var latlng = new window.google.maps.LatLng(latitud, longitud);
									this.marker && this.marker.setPosition(latlng);
									if(esUbicacion && this.map){
										this.map.setCenter(new window.google.maps.LatLng(latitud, longitud));
										this.map.setZoom(15);
									}
									clearInterval(interval)
									resolve()
							}
					}, 100);
			});
		}

      // Bias the autocomplete object to the user's geographical location,
      // as supplied by the browser's 'navigator.geolocation' object.
    geolocate() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function(position) {
            var geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            var circle = new window.google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy
            });
            this.autocomplete.setBounds(circle.getBounds());
          });
        }
		}

		handleDesplegarMapa(){

				this.setState({mostrarMapa: !this.state.mostrarMapa});
		}

render() {
	this.formValidation.validate();
	
	let state = this.state;

	let formData = this.state.formData;

	let requiredSymbol = this.state.props.action !== 'VIEW' ? ' *' : '';

	return (

		<React.Fragment>
			{state.redirectTo && <Redirect push to={state.redirectTo} />}
			{state.loading && <Loading />}
			<div className="row">
			<div className="col-md-6">
						<div className="help-block " style={{padding:'15px'}} hidden={formData.latitud}>
							<h3 className="text-danger field-message text-center"><FormattedMessage id="direccionAutocompletar.label_temporary.complete_la_ubicacion_o_seleccione_punto_mapa" defaultMessage="Complete la ubicación o seleccione el punto exacto en el mapa"/></h3>
						</div>
						<div className="row col-md-12" hidden={this.props.action === 'VIEW'}>
								<div className="col-md-12">
									<div className="form-group row">
										<label className="col-md-3 label-control col-form-label" htmlFor="ubicacion">
											<FormattedMessage id="direccionAutocompletar.label_direccion" defaultMessage="Dirección "/>{this.props.camposObligatorios.length != 0 ? requiredSymbol : ''}:
										</label>
										<div className="col-md-9">
											<input
												onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
												id="autocomplete" className="form-control" placeholder={this.props.intl.formatMessage({ id: 'direccionAutocompletar.label_direccion.placeholder.ingrese_direccion', defaultMessage: 'Ingrese la dirección' })} type="text" name="ubicacion"></input>
										</div>
									</div>
								</div>
						</div>

						{/* Pais */}
						<div className="row col-md-12" hidden={this.props.camposHidden.includes("pais")}>
							<div className="col-md-12">
								<div className="form-group row">
										<label className="col-md-3 label-control col-form-label" htmlFor="pais">
											<FormattedMessage id="direccionAutocompletar.label_pais" defaultMessage="País "/>{this.props.camposObligatorios.includes("pais") ? requiredSymbol : ''}:
										</label>
										<div className="col-md-9">
											<input disabled readOnly type="text" className="form-control" value={formData.pais ? formData.pais.nombre : ''} />
										</div>
									</div>
							</div>
						</div>

						{/* PROVINCIA */}
						<div className="row col-md-12" hidden={!formData.pais || state.provinciasLoading || this.props.camposHidden.includes("provincia")}>
								<div className="col-md-12">
									<div className="form-group row">
										<label className="col-md-3 label-control col-form-label" htmlFor="provincia">
											<FormattedMessage id="direccionAutocompletar.label_provincia" defaultMessage="Provincia "/>{this.props.camposObligatorios.includes("provincia") ? requiredSymbol : ''}:
										</label>
										<div className="col-md-9">
											<input disabled readOnly type="text" className="form-control" value={formData.provincia ? formData.provincia.nombre : ''} />
										</div>
									</div>
								</div>
						</div>

						{/* LOCALIDAD */}
						<div className="row col-md-12" hidden={!formData.provincia || state.localidadesLoading || this.props.camposHidden.includes("localidad")}>
							<div className="col-md-12">
								<div className="form-group row">
									<label className="col-md-3 label-control col-form-label" htmlFor="localidad">
										<FormattedMessage id="direccionAutocompletar.label_localidad " defaultMessage="Localidad "/>{this.props.camposObligatorios.includes("localidad") ? requiredSymbol : ''}:
									</label>
									<div className="col-md-9">
										{ this.props.localidadString ?
											<input disabled readOnly type="text" className="form-control" value={formData.localidad ? formData.localidad : ''} />
											:
											<input disabled readOnly type="text" className="form-control" value={formData.localidad ? formData.localidad.nombre : ''} />
										}
									</div>
								</div>
							</div>
						</div>

						{/* CODIGO POSTAL */}
						<div className="row">
							<div className="col-md-6" hidden={this.props.camposHidden.includes("codigoPostal")}>
								<div className="form-group row">
									<label className="col-md-3 label-control col-form-label" htmlFor="codigo_postal">
										<FormattedMessage id="direccionAutocompletar.label_codigo_postal " defaultMessage="Código Postal "/>{this.props.camposObligatorios.includes("codigoPostal") ? requiredSymbol : ''}:
									</label>
									<div className="col-md-9">
										{this.props.action === 'VIEW' ? (
										<div className="form-control-static col-form-label">{formData.codigo_postal}</div>
										) : (
										<div>
											<input type="text" className="form-control" id="postal_code" name="codigo_postal" placeholder={this.props.intl.formatMessage({ id: 'direccionAutocompletar.label_codigo_postal.placeholder', defaultMessage: 'Código Postal' })} value={formData.codigo_postal} onChange={this.handleInputFormChange} />
										</div>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="row col-md-12">
							 <div className="col-md-12">
								 <div className="form-group row">
									 {/* CALLE */}
									 <label className="col-md-3 label-control col-form-label" hidden={this.props.camposHidden.includes("calle")} htmlFor="calle">
										 <FormattedMessage id="direccionAutocompletar.label_calle" defaultMessage="Calle "/>{this.props.camposObligatorios.includes("calle") ? requiredSymbol : ''}:
									 </label>
									 <div className="col-md-5" hidden={this.props.camposHidden.includes("calle")}>
											 <input disabled readOnly type="text" className="form-control" id="route" name="calle" placeholder={this.props.intl.formatMessage({ id: 'direccionAutocompletar.label_calle.placeholder', defaultMessage: 'Calle' })} value={formData.calle}/>
									 </div>
									 {/* NRO */}
									 <label className="col-md-2 label-control col-form-label" htmlFor="numero">
										 <FormattedMessage id="direccionAutocompletar.label_nro" defaultMessage="Nro. "/>{this.props.camposObligatorios.includes("numero") ? requiredSymbol : ''}:
									 </label>
									 <div className="col-md-2">
											 <input disabled readOnly type="text" className="form-control" id="street_number" name="numero" placeholder={this.props.intl.formatMessage({ id: 'direccionAutocompletar.label_nro.placeholder', defaultMessage: 'Nro.' })} value={formData.numero} />{/* onChange={this.handleInputFormChange} />*/}
									 </div>
								 </div>
							 </div>
						 </div>

							{/* latitud */}
							<div className="row">
							<div className="col-md-6" >
								<div className="form-group row">
									<div className="col-md-9">
										<div>
											<input type="hidden" className="form-control" id="latitude" name="latitud" value={formData.latitud} />
										</div>
									</div>
								</div>
							</div>
							{/* Longitud */}
							<div className="col-md-6" >
								<div className="form-group row">
									<div className="col-md-9">
										<div>
											<input type="hidden" className="form-control" id="longitude" name="longitud" value={formData.longitud} />
										</div>
									</div>
								</div>
							</div>
					</div>
				</div>
				<div className="col-md-6">
					<div id="map" className="col-12" style={{height:'350px', margin:'auto'}} >
					</div>
				</div>
		</div>

		</React.Fragment>
	);}
}

export default injectIntl(DireccionAutocompletar);
