import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import { TableRow } from '@material-ui/core'
import 'moment/min/locales'
import Swal from 'sweetalert2'
import * as Utils from '../../commons/utils/Utils'
import { StyledTableCellProps as StyledTableCell } from '../../commons/utils/TableStyles';

const InsumosMarcasGridRow = (props) => {
	const intl = props.intl
	const marca = props.marca;
	const baseUrl = props.baseUrl;

	const [redirectTo, setRedirectTo] = useState(null)

	const handleMarcaDelete = () => {
		Swal.fire({
			title: intl.formatMessage({ id: '¿Confirma la eliminación?', defaultMessage: '¿Confirma la eliminación?' }),
			html: intl.formatMessage({ id: 'insumoMarcaAbm.eliminar.se_eliminara_la_marca', defaultMessage: 'Se eliminará la Marca' }) + ': ' + "<b>" + marca.nombre + "</b>",
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: intl.formatMessage({ id: 'Si', defaultMessage: 'Si' }),
			cancelButtonText: intl.formatMessage({ id: 'No', defaultMessage: 'No' }),
		}).then((result) => {
			if (result.value) {
				Utils.deleteData('/panol/insumomarca/' + marca.id)
					.then(() => {
						props.fetchMarcas();
						Swal(intl.formatMessage({ id: 'Eliminado!', defaultMessage: 'Eliminado!' }), "", "success");
					})
					.catch((err) => {
						Swal(
							err.getMessage(),
							'',
							'error',
						);
					});
			}
		})
	};
	return (
		<>
			{redirectTo && <Redirect push to={redirectTo} />}
			<TableRow>
				<StyledTableCell align="left">
					{marca.id}
				</StyledTableCell>
				<StyledTableCell align="left">
					{marca.nombre}
				</StyledTableCell>
				{props.showCategoria ?
				<StyledTableCell align="left">
					{marca.categoria.map(categoria => (
							categoria.nombre
						)).join('; ')
					}
				</StyledTableCell>
					: ''
				}
				<StyledTableCell>
					<div className="d-inline-flex">
						{props.permissions.itemsMarcasVisualizar &&
							<button
								className="action view btn btn-sm btn-icon btn-dt-grid text-success"
								title="Ver"
								data-togle="tooltip"
								data-placement="top"
								onClick={() => setRedirectTo(baseUrl + '/' + marca.id)}
							>
								<i className="fa fa-search fa-xs" />
							</button>

						}
						{ props.permissions.itemsMarcasModificar &&
							<button
								className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
								title="Editar"
								data-togle="tooltip"
								data-placement="top"
								onClick={() => setRedirectTo(baseUrl + '/' + marca.id + '/edit')}
							>
								<i className="fa fa-pencil fa-xs" />
							</button>
						}
						{ (props.permissions.itemsMarcasEliminar && marca.activa === 1 ) ?
							<button
								className="action delete btn btn-sm btn-icon btn-dt-grid text-danger"
								title="Eliminar"
								data-togle="tooltip"
								data-placement="top"
								onClick={() => handleMarcaDelete()}
							>
								<i className="fa fa-trash fa-xs" />
							</button>
							: ''
						}
					</div>
				</StyledTableCell>
			</TableRow>
		</>
	)
}

export default InsumosMarcasGridRow;
