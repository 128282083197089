import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import Loading from "../ui/Loading.js";
import $ from "jquery";
import "xlsx/dist/xlsx.full.min.js";
import {Grid} from '@material-ui/core';
import { FormattedMessage, injectIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Utils from '../../commons/utils/Utils';
import PresupuestoDetalladoEditModal from "./PresupuestoDetalladoEditModal.js";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

const requiredSymbol = ' *';

function Row(props) {
	const intl = props.intl;
	const [presupuestoTarea, setPresupuestoTarea] = useState(props.presupuestoTarea);
	const [showModalEdit, setShowModalEdit] = useState(false);

	const useStyles = makeStyles((theme) => ({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "15px",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			borderRadius: "15px",
		},
	}));

	const classEdit = useStyles();

	const updateElement = (data) => {
		props.updatePresupuestoTarea(data, props.index);
		presupuestoTarea.precio = data.costo
		presupuestoTarea.cantidad = data.cantidad
		
		closeModalEdit();
	}

	const total = () => {
		let total = null;
		if(presupuestoTarea.cantidad !== null){
			if(presupuestoTarea.precio !== null){
				total = presupuestoTarea.cantidad * presupuestoTarea.precio;
			}
		}
		return total;
	};

	const closeModalEdit = (event) => {
		setShowModalEdit(false);
	};

	const renderTableTbody = (field, index) => {
		switch (field) {
			case 'index':
				return (
					<td key={index} className="all custom-lineHeight">
						{props.index + 1}
					</td>
				);
			case 'id':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoTarea.id_tarea}
					</td>
				);
			case 'external_code':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoTarea.external_code}
					</td>
				);
			case 'nombre':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoTarea.nombre}
					</td>
				);
			case 'cuenta_mayor':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoTarea.cuenta_mayor}
					</td>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoTarea.cuenta_mayor_descripcion}
					</td>
				);
			case 'cantidad':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoTarea.cantidad}
					</td>
				);
			case 'precio':
				return (
					<td key={index} className="all custom-lineHeight">
						{presupuestoTarea.precio}
					</td>
				);
			case 'total':
				return (
					<td key={index} className="all custom-lineHeight">
						{total()}
					</td>
				);
			case 'servicio':
				return (
					<td key={index} className="all custom-lineHeight">
						{
							presupuestoTarea.servicio ? 
								presupuestoTarea.servicio.nombre :
								(
									presupuestoTarea.servicio_nombre ? presupuestoTarea.servicio_nombre : ''
								)
						}
					</td>
				);
			default:
				return null;
		}
	}

	return (
		<React.Fragment>
			<tr key={presupuestoTarea.id}>
				<Modal
					open={showModalEdit}
					onClose={closeModalEdit}
					className={classEdit.modal}
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={showModalEdit}>
						<Box sx={style}>
							<PresupuestoDetalladoEditModal
								type="presupuestoTarea"
								intl={props.intl}
								element={presupuestoTarea}
								updateElement={updateElement}
								closeModalEdit={closeModalEdit}
								enabledCosto={props.showTareas.includes('precio')}
								enabledCantidad={props.showTareas.includes('cantidad')}
							/>
						</Box>
					</Fade> 
				</Modal>
				{props.showTareas.map((field, index) => renderTableTbody(field, index))}
				<td className="all custom-lineHeight">
					<button
						className="btn btn-sm btn-icon btn-dt-grid text-warning"
						title={intl.formatMessage({ id: 'Presupuesto.Editar', defaultMessage: 'Editar' })}
						data-togle="tooltip"
						data-placement="top"
						onClick={(event) => {setShowModalEdit(true); event.preventDefault();}}
					>
						<i className="fa fa-pencil fa-xs"/>
					</button>
					<button
						className="btn btn-sm btn-icon btn-dt-grid text-danger"
						title={intl.formatMessage({ id: 'Presupuesto.Eliminar', defaultMessage: 'Eliminar' })}
						data-togle="tooltip"
						data-placement="top"
						onClick={() => props.deleteTarea(props.index)}
					>
						<i className="fa fa-trash fa-xs"></i>
					</button>
				</td>
			</tr>
		</React.Fragment>
	);
}

const NEW_TAREA_INIT = {
	id: null,
	external_code: '',
	nombre: '',
	cuenta_mayor: '',
	cuenta_mayor_descripcion: '',
	precio: '',
	cantidad: '',
	servicio_id: null,
	servicio: ''
};

const PresupuestoDetalladoTareaGrid = (props) => {
	const [loading, setLoading] = useState(false);
	const [newPresupuestoTarea, setNewPresupuestoTarea] = useState({ ...NEW_TAREA_INIT })

	const setLoadingAndUpdate = (state) => {
		setLoading(state);
	};

	const renderTableThead = (field, index) => {
		switch (field) {
			case 'index':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Items" defaultMessage="Items" />
					</th>
				);
			case 'id':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Id" defaultMessage="Id" />
					</th>
				);
			case 'external_code':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Tarea" defaultMessage="Tarea" />
					</th>
				);
			case 'nombre':
				return (
					<th key={index} scope="col" style={{ maxWidth: "3px" }}>
						<FormattedMessage id="Presupuesto.Descripción" defaultMessage="Descripción" />
					</th>
				);
			case 'cuenta_mayor':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cuenta_Mayor" defaultMessage="Cuenta Mayor" />
					</th>
				);
			case 'cuenta_mayor_descripcion':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Descripción_Cuenta_Mayor" defaultMessage="Descripción Cuenta Mayor" />
					</th>
				);
			case 'cantidad':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Cantidad" defaultMessage="Cantidad" />
					</th>
				);
			case 'precio':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Precio" defaultMessage="Precio" />
					</th>
				);
			case 'total':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Total" defaultMessage="Total" />
					</th>
				);
			case 'servicio':
				return (
					<th key={index} scope="col">
						<FormattedMessage id="Presupuesto.Servicio" defaultMessage="Servicio" />
					</th>
				);
			default:
				return null;
		}
	}

	return (
		<React.Fragment>
			<table
				id="dataTable"
				className="dataTable table nowrap server-side table-hover dataTableMovil"
				width="100%"
			>
				<thead>
					<tr>
						{props.showTareas.map((field, index)=> renderTableThead(field, index))}
						<th scope="col">
						</th>
					</tr>
				</thead>
				<tbody>
					{props.presupuestoTareas ? (
						props.presupuestoTareas.map((presupuestoTarea, index) => (
							<Row
								index={index}
								intl={props.intl}
								presupuestoTarea={presupuestoTarea}
								loading={(state) => setLoadingAndUpdate(state)}
								key={presupuestoTarea.external_code + presupuestoTarea.nombre + index}
								deleteTarea={props.deleteTarea}
								updatePresupuestoTarea={props.updatePresupuestoTarea}
								showTareas={props.showTareas}
							/>
						))
					) : (
						<tr>
							<td align={"center"} colSpan={14}>
								{loading ? (
									<CircularProgress />
								) : (
									<FormattedMessage
										id="No_hay_datos_disponibles."
										defaultMessage="No hay datos disponibles."
									/>
								)}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</React.Fragment>
	);
};

export default injectIntl(PresupuestoDetalladoTareaGrid);
